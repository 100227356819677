import { Avatar, Box, Button, Checkbox, Flex, FormControl, FormLabel, Icon, Image, Input, Link, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdEdit, MdModeEdit, MdSettings, MdDone, MdAdd, MdDelete } from 'react-icons/md';
import tools from '../../../assets/images/tools.svg';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import { IoMdClose, IoMdEye } from 'react-icons/io';
import 'react-quill/dist/quill.snow.css';
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { authState, companyProfileUpdate, createAggrement, createBankAccount, createStripeAccount, deleteBankAccount, deleteStripeAccount, getAggrements, getBankAccounts, getStripeAccounts, setAuthError, updateAggrement, updateBankAccount, updatePassword, updateStripeAccount, updateUser, uploadCompanyImage, uploadUserImage, userDetailById } from '../../../redux/modules/auth'
import { useForm } from '../../../utils/hooks'
import { fillFormValues, googleMapAutocomplete, loadGoogleMapScript } from '../../../utils/common';
import { css } from '@emotion/react';
import Loader from '../../../components/common/loader';
import TextEditor from '../../../components/common/TextEditor';
import AgreementModal from '../../../components/common/AgreementModal';
import StripeIcon from '../../../assets/images/stripe-icon.png';
import BankAccount from '../../../assets/images/bank-account-icon.png';
import { ReportState, setReportAgreement } from '../../../redux/modules/report';
import { useLocation, useNavigate } from 'react-router-dom';

export function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const { isOpen: isOpenReset, onOpen: onOpenReset, onClose: onCloseReset } = useDisclosure();
  const { isOpen: isOpenCropper, onOpen: onOpenCropper, onClose: onCloseCropper } = useDisclosure();

  const MANAGE_PASSWORD_FORM = {
    default: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    },
    field: {
      required: true,
      inputs: ['oldPassword', 'password', "confirmPassword"]
    }
  };

  const USER_UPDATE_FORM = {
    default: {
      firstName: '',
      lastName: '',
      phone: '',
      address: '',
      state: '',
      email: '',
      user_Certificate: '',
      user_LicenceNumber: ''
    },
    field: {
      required: true,
      inputs: []
    }
  };

  const COMPANY_UPDATE_FORM = {
    default: {
      companyName: '',
      companyAddress: '',
      email: '',
      state: '',
      city: '',
      zipcode: '',
      website: ''
    },
    field: {
      required: true,
      inputs: []
    }
  };

  const STRIPE_FORM = {
    default: {
      publishableKey: "",
      secretKey: "",
      accountId: ""
    },
    field: {
      required: true,
      inputs: ["publishableKey", "secretKey", "accountId"]
    },
  };

  const BANK_ACCOUNT_FORM = {
    default: {
      accountHolderName: "",
      bankName: "",
      accountNo: "",
      confirmAccountNo: "",
      routingNumber: ""
    },
    field: {
      required: true,
      inputs: ["accountHolderName", "bankName", "accountNo", "confirmAccountNo", "routingNumber"]
    },
  };

  const { values: managePassValues, change: managePassChange, touched: managePassTouched, errors: managePassErrors, reset: managePassReset } = useForm(MANAGE_PASSWORD_FORM.default, MANAGE_PASSWORD_FORM.field);
  const { values: companyValues, change: companyChange, touched: companyTouched, errors: companyErrors, setValues: companySetValues, reset: companyReset } = useForm(COMPANY_UPDATE_FORM.default, COMPANY_UPDATE_FORM.field);
  const { values: userUpdateValues, change: userUpdateChange, touched: userUpdateTouched, errors: userUpdateErrors, setValues: userUpdateSetValues, reset: userUpdateReset } = useForm(USER_UPDATE_FORM.default, USER_UPDATE_FORM.field);
  const { values: stripeValues, change: stripeChange, errors: stripeErrors, setValues: setStripeValues, touched: stripeTouched } = useForm(STRIPE_FORM.default, STRIPE_FORM.field);
  const { values: bankAccountValues, change: bankAccountChange, errors: bankAccountErrors, setValues: setBankAccountValues, touched: bankAccountTouched } = useForm(BANK_ACCOUNT_FORM.default, BANK_ACCOUNT_FORM.field);
  const report: ReportState = useSelector((state: RootState) => state.report);
  const [agreementDesc, setAgreementDesc] = useState('');
  const [agreementTitle, setAgreementTitle] = useState('');
  const [showAgreementForm, setShowAgreementForm] = useState(false);
  const [isAgreementUpdate, setIsAgreementUpdate] = useState({ status: false, id: null });

  const [selectFor, setSelectFor] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState<any>();
  const [currentFileName, setCurrentFileName] = useState(null);

  // Set agreement modal states
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [openAgreementModel, setOpenAgreementModel] = useState(false);

  const [onlinePayment, setOnlinePayment] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [checkDefaultBankAccount, setCheckDefaultBankAccount] = useState(null);
  const [agreementUpdated, setAgreementUpdated] = useState(null);
  const [activeReportId, setActiveReportId] = useState(null);


  const { isOpen: stripe, onOpen: openStripe, onClose: closeStripe } = useDisclosure();
  const { isOpen: showPaymentModal, onOpen: openShowPaymentModal, onClose: closeShowPaymentModal,
  } = useDisclosure();
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  useEffect(() => {
    if (loadMap) {
      let autocomplete: google.maps.places.Autocomplete;
      const autocompleteInput = document.getElementById('autocomplete') as HTMLInputElement;
      autocomplete = new google.maps.places.Autocomplete(autocompleteInput);

      // When a place is selected from the autocomplete dropdown
      autocomplete.addListener('place_changed', () => {
        const companyAddressValues = googleMapAutocomplete(autocomplete);
        if (companyAddressValues) {
          companySetValues({
            companyName: companyValues.companyName,
            companyAddress: companyAddressValues.streetNumber,
            email: companyValues.email,
            state: companyAddressValues.addressState,
            city: companyAddressValues.addressCity,
            zipcode: companyAddressValues.addressZipcode || "",
            website: companyValues.website,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyValues.companyAddress]);


  // Initial value loder
  // ... render page resources
  useEffect(() => {
    if (report?.editReport?.report?.id) {
      setActiveReportId(report?.editReport?.report?.id);
    }
  }, [report?.editReport?.report?.id]);

  useEffect(() => {
    if (auth.user?.id) {
      dispatch(getAggrements({ payload: { userId: auth.user.id } }));
    }
  }, [auth.user?.id, dispatch]);

  const fileChange = (event: any) => {
    event.preventDefault();
    setSelectFor(event.target.name);

    let files: any;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
      onOpenCropper();
    } else if (event.target) {
      files = event.target.files;
      onOpenCropper();
    }

    const reader = new FileReader();
    reader.onload = () => {
      setCropImage(reader.result as any);
    };

    reader.readAsDataURL(files[0]);
    setCurrentFileName(files[0].name);
  };

  const getCropData = async () => {
    if (typeof croppedImage !== 'undefined') {
      const dataURLtoFile = (dataurl: string, filename: string) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n) {
          u8arr[n - 1] = bstr.charCodeAt(n - 1);
          n -= 1; // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime });
      };

      // put file into form data
      const data = new FormData();
      data.append(
        'image',
        dataURLtoFile(
          croppedImage.getCroppedCanvas().toDataURL(),
          currentFileName
        )
      );
      if (selectFor === 'company') {
        // Call uploadCompanyImage api
        new Promise(() => {
          const data2: any = dispatch(
            uploadCompanyImage({
              companyId: auth.company.id,
              payload: data
            })
          );
          data2.then(function (value) {
            if (value.type === 'auth/uploadCompanyImage/fulfilled') {
              toastr.success('', 'Image uploaded successfully');
              getUserDetails();
            }
          })
        });
      } else if (selectFor === 'profile') {
        // Call uploadUserImage api
        new Promise(() => {
          const data3: any = dispatch(
            uploadUserImage({
              userId: auth.user.id,
              payload: data
            })
          );
          data3.then(function (value) {
            if (value.type === 'auth/uploadUserImage/fulfilled') {
              toastr.success('', 'Image uploaded successfully');
              getUserDetails();
            }
          })
        });
      }
      onCloseCropper();
    }
  };

  // Call userDetailbyId api
  function getUserDetails() {
    dispatch(userDetailById({ userId: auth.user.id }));
  }

  useEffect(() => {
    if (auth.user && auth.company) {
      userUpdateSetValues({ ...userUpdateValues, ...fillFormValues(USER_UPDATE_FORM.default, auth.user, ['id']) });
      companySetValues({ ...companyValues, ...fillFormValues(COMPANY_UPDATE_FORM.default, auth.company, ['id']) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.company, auth.user]);

  const managePasswordSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(managePassTouched).length <= 0) {
      toastr.warning('', 'Please first fill the form');
      return;
    }

    if (Object.keys(managePassErrors).length > 0) {
      toastr.warning('', managePassErrors[Object.keys(managePassErrors)[0]]);
      return;
    }

    // Call updatePassword api
    new Promise(() => {
      const data4: any = dispatch(
        updatePassword({
          userId: auth.user.id,
          payload: {
            newpassword: managePassValues.password,
            currentpassword: managePassValues.oldPassword
          }
        })
      );
      data4.then(function (value) {
        if (value.type === 'auth/updatePassword/fulfilled') {
          onCloseReset();
          managePassReset();
          toastr.success('', 'Password updated successfully');
        }
      })
    });
  };

  const userUpdatedSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(userUpdateTouched).length <= 0) {
      toastr.warning('', 'Please first fill the form');
      return;
    }

    if (Object.keys(userUpdateErrors).length > 0) {
      toastr.warning('', userUpdateErrors[Object.keys(userUpdateErrors)[0]]);
      return;
    }

    // Call updateUser api
    new Promise(() => {
      const data5: any = dispatch(
        updateUser({
          userId: auth.user.id,
          payload: Object.fromEntries(
            Object.entries(userUpdateValues).filter(([_, v]) => v !== '')
          )
        })
      );
      data5.then(function (value) {
        if (value.type === 'auth/updateUser/fulfilled') {
          getUserDetails();
          userUpdateReset();
          toastr.success('', 'Profile updated successfully');
        }
      })
    });
  };

  const companyUpdatedSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (Object.keys(companyTouched).length <= 0) {
      toastr.warning('', 'Please first update the form');
      return;
    }

    if (Object.keys(companyErrors).length > 0) {
      toastr.warning('', companyErrors[Object.keys(companyErrors)[0]]);
      return;
    }
    if (!companyValues.companyAddress || !companyValues.city || !companyValues.state || !companyValues.zipcode) {
      toastr.warning('', 'Please first fill the Address');
      return;
    }
    const address = companyValues.companyAddress.toLowerCase() + ' ' + companyValues.city.toLowerCase() + ' ' + companyValues.state.toLowerCase() + ' ' + companyValues.zipcode;
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        // Call profileUpdate api
        new Promise(() => {
          const data6: any = dispatch(
            companyProfileUpdate({
              companyId: auth.user.companyDetailId,
              payload: Object.fromEntries(
                Object.entries(companyValues).filter(([_, v]) => v !== '')
              )
            })
          );
          data6.then(function (value) {
            if (value.type === 'auth/companyProfileUpdate/fulfilled') {
              companyReset();
              getUserDetails();
              toastr.success('', 'Company profile updated successfully');
            }
          })
        });
      } else {
        toastr.error('', 'Please enter a valid address');
        return;
      }
    });
  };

  const editorUpdated = (_content: any, _delta: any, _source: any, editor: any) => {
    const html = editor.getHTML();
    setAgreementDesc(html);
    setAgreementUpdated(true);
  }

  const submitAgreement = () => {
    if (isAgreementUpdate.status === true) {
      if (!agreementUpdated) {
        toastr.warning('', 'Change the values to Update the agreement');
        return;
      }
      // Call updateAggrement api
      new Promise(() => {
        const data7: any = dispatch(
          updateAggrement({
            aggrementId: isAgreementUpdate.id,
            payload: {
              title: agreementTitle,
              description: agreementDesc
            }
          })
        );
        data7.then(function (value) {
          if (value.type === "auth/updateAggrement/fulfilled") {
            setAgreementDesc('');
            setAgreementTitle('');
            setShowAgreementForm(!showAgreementForm);
            setIsAgreementUpdate({ status: false, id: null });
          }
        })
      });
    } else {
      if (!(agreementTitle !== '' && agreementDesc !== '')) {
        toastr.warning('', 'Add all the values to save the agreement');
        return;
      }
      // Call createAggrement api
      new Promise(() => {
        const data8: any = dispatch(
          createAggrement({
            payload: {
              userId: auth.user.id,
              title: agreementTitle,
              description: agreementDesc
            }
          })
        );
        data8.then(function (value) {
          if (value.type === "auth/createAggrement/fulfilled") {
            setAgreementDesc('');
            setAgreementTitle('');
            setShowAgreementForm(!showAgreementForm);
          }
        })
      });
    }
    setAgreementUpdated(false);
    if (report?.reportAgreement) {
      navigate(`/edit-report/${activeReportId}`)
      dispatch(setReportAgreement(0))

    }
  };



  const closeAgreementForm = () => {
    setAgreementDesc('');
    setAgreementTitle('');
    setShowAgreementForm(!showAgreementForm);
    setIsAgreementUpdate({ status: false, id: null });
  }

  const handleUpdateAggrement = (agreement: any) => {
    setAgreementTitle(agreement.title);
    setAgreementDesc(agreement.description);
    setShowAgreementForm(!showAgreementForm);
    setIsAgreementUpdate({ status: true, id: agreement.id });
  }

  const handleViewAggrement = (agreement: any) => {
    setSelectedAgreement(agreement);
    setOpenAgreementModel(true);
  }

  const saveStripeDetails = () => {
    if (Object.keys(stripeTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }

    if (Object.keys(stripeErrors).length > 0) {
      toastr.warning("", stripeErrors[Object.keys(stripeErrors)[0]]);
      return;
    }

    if (auth.stripeAccounts[0]?.id) {
      new Promise(() => {
        const data9: any = dispatch(
          updateStripeAccount({ accountId: auth.stripeAccounts[0].id, payload: { ...stripeValues } })
        );
        data9.then(function (value) {
          if (value.type === "auth/update/stripeAccount/fulfilled") {
            toastr.success("", "Stripe info updated successfully");
            closeStripe();
          }
        })
      });

      if (defaultPaymentMethod) {
        new Promise(() => {
          const data10: any = dispatch(updateUser({ userId: auth.user.id, payload: { AccountId: auth.stripeAccounts[0].id, SelectAccountType: defaultPaymentMethod } }));
          data10.then(function (value) {
            if (value.type === "auth/updateUser/fulfilled") {
              dispatch(userDetailById({ userId: auth.user?.id }));
            }
          })
        });
      }
    }
    else {
      new Promise(() => {
        const data11: any = dispatch(
          createStripeAccount({ ...stripeValues })
        );
        data11.then(function (value) {
          if (value.type === "auth/connectStripeAccount/fulfilled") {
            toastr.success("", "Stripe account added successfully");
            closeStripe();

            if (defaultPaymentMethod) {
              new Promise(() => {
                const data12: any = dispatch(updateUser({ userId: auth.user.id, payload: { AccountId: value.payload.id, SelectAccountType: defaultPaymentMethod } }))
                data12.then(function (value1) {
                  if (value1.type === "auth/updateUser/fulfilled") {
                    dispatch(userDetailById({ userId: auth.user?.id }));
                  }
                })
              });
            }
          }
        })
      });
    }
  }

  const [accountId, setAccountId] = useState(null);
  const [createBankMethod, setCreateBankMethod] = useState(null);

  const saveAccountInfo = () => {
    if (Object.keys(bankAccountTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }

    if (Object.keys(bankAccountErrors).length > 0) {
      toastr.warning("", bankAccountErrors[Object.keys(bankAccountErrors)[0]]);
      return;
    }

    if (bankAccountValues.confirmAccountNo !== bankAccountValues.accountNo) {
      toastr.warning("", "Account Number and Confirm Account Number should be same");
      return;
    }

    if (accountId) {
      new Promise(() => {
        const data13: any = dispatch(
          updateBankAccount({ accountId, payload: { ...bankAccountValues } })
        );
        data13.then(function (value) {
          if (value.type === "auth/update/bankAccount/fulfilled") {
            toastr.success("", "Bank info updated successfully");
            closeShowPaymentModal();
            setOnlinePayment(false);
          }
        })
      });

      if (createBankMethod || (accountId === checkDefaultBankAccount)) {
        new Promise(() => {
          const data14: any = dispatch(updateUser({ userId: auth.user.id, payload: { AccountId: accountId, SelectAccountType: 'Bank' } }))
          data14.then(function (value) {
            if (value.type === "auth/updateUser/fulfilled") {
              dispatch(userDetailById({ userId: auth.user?.id }));
            }
          })
        });
      }
    }
    else {
      new Promise(() => {
        const data15: any = dispatch(
          createBankAccount({ ...bankAccountValues })
        );
        data15.then(function (value) {
          if (value.type === "auth/connect/BankAccount/fulfilled") {
            toastr.success("", "Bank account added successfully");
            closeShowPaymentModal();
            setOnlinePayment(false);
            if (createBankMethod || (accountId === checkDefaultBankAccount)) {
              new Promise(() => {
                const data16: any = dispatch(updateUser({ userId: auth.user.id, payload: { AccountId: value.payload.id, SelectAccountType: 'Bank' } }))
                data16.then(function (response) {
                  if (response.type === "auth/updateUser/fulfilled") {
                    dispatch(userDetailById({ userId: auth.user?.id }));
                  }
                })
              });
            }
          }
        })
      });
    }
  }

  const saveSavedAccountInfo = () => {
    if (!checkDefaultBankAccount) {
      toastr.warning("", "Select a account first");
    }
    else {
      new Promise(() => {
        const profileData: any = dispatch(updateUser({ userId: auth.user.id, payload: { AccountId: checkDefaultBankAccount, SelectAccountType: 'Bank' } }))
        profileData.then(function (value) {
          if (value.type === "auth/updateUser/fulfilled") {
            toastr.success("", "Account set to default successfully");
            closeShowPaymentModal();
            getUserDetails();
            dispatch(userDetailById({ userId: auth.user?.id }));
          }
        })
      });
    }
  }

  const handleAccountEdit = (accountInfo: any) => {
    setBankAccountValues({ ...accountInfo.bankDetail });
    setOnlinePayment(true);
    setAccountId(accountInfo.id);
  }

  const handleAccountDelete = (accountInfo: any) => {
    new Promise(() => {
      const accountDataInfo: any = dispatch(deleteBankAccount({ accountId: accountInfo.id }));
      accountDataInfo.then(function (value) {
        if (value.type === "auth/delete/bankAccount/fulfilled") {
          toastr.success("", "Account deleted successfully");
        }
      })
    });
    if (checkDefaultBankAccount === accountInfo.id || auth.bankAccounts.length === 1) {
      closeShowPaymentModal();
      setCreateBankMethod(null);
      new Promise(() => {
        const profileData1: any = dispatch(updateUser({ userId: auth.user.id, payload: { AccountId: '', SelectAccountType: '' } }))
        profileData1.then(function (value) {
          if (value.type === "auth/updateUser/fulfilled") {
            dispatch(userDetailById({ userId: auth.user?.id }));
          }
        })
      });
    }
  }

  useEffect(() => {
    dispatch(getBankAccounts());
  }, [dispatch]);

  const handleDeleteStripeAccount = () => {
    new Promise(() => {
      const stripeData: any = dispatch(deleteStripeAccount({ accountId: auth.stripeAccounts[0].id }));
      stripeData.then(function (value) {
        if (value.type === "auth/delete/stripeAccount/fulfilled") {
          toastr.success("", "Stripe account deleted successfully");
          closeStripe();
          setStripeValues(fillFormValues(STRIPE_FORM.default, STRIPE_FORM.default, ['publishableKey', 'secretKey', 'accountId']));
          new Promise(() => {
            const profileData3: any = dispatch(updateUser({ userId: auth.user.id, payload: { AccountId: '', SelectAccountType: '' } }))
            profileData3.then(function (value1) {
              if (value1.type === "auth/updateUser/fulfilled") {
                dispatch(userDetailById({ userId: auth.user?.id }));
              }
            })
          });
          setDefaultPaymentMethod(null);
        }
      })
    });
  }

  useEffect(() => {
    dispatch(getStripeAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (auth.stripeAccounts[0]) {
      setStripeValues({ publishableKey: auth.stripeAccounts[0]?.publicKey, secretKey: auth.stripeAccounts[0]?.stripeKey, accountId: auth.stripeAccounts[0]?.accountId });
    }
  }, [auth.stripeAccounts, setStripeValues]);

  useEffect(() => {
    if (auth.user?.AccountId) {
      setCheckDefaultBankAccount(+auth.user?.AccountId)
    }
  }, [auth.user, auth.user?.AccountId]);

  useEffect(() => {
    if (auth?.error) {
      const { message } = auth.error;
      toastr.error('', message ? message : '');
    }
    return () => { dispatch(setAuthError(null)); }
  }, [auth.error, dispatch]);
  useEffect(() => {
    if (location.pathname !== '/settings') {
      dispatch(setReportAgreement(0))
    }
  })
  return (
    <>
      {auth.loginLoading.length !== 0 && <Loader />}
      <Box p='29px 30px 20px 20px' h='calc(100vh - 70px)' overflow='auto' w='100%'>
        <Text as='h1' mb={{ sm: '16px', xl: '26px' }} color='black.lighten' fontSize='24px' lineHeight='30px' fontWeight='600'>Settings</Text>
        <Box bg='white' borderRadius='4px' p={{ sm: '19px 0', xxl: '29px 0' }} minH='calc(100vh - 175px)'>
          <Tabs defaultIndex={report?.reportAgreement} className='tab-with-icons'>
            <TabList borderColor='gray.light3' borderBottomWidth='1px'>
              <Tab _selected={{ color: 'black.lighten' }} border='none' p='19px 17px'><Icon mr='14px' fontSize='24px' color='gray.primary' as={MdSettings}></Icon><Text fontSize={{ sm: '18px', xxl: '21px' }} lineHeight={{ sm: '24px', xxl: '26px' }} color='black.lighten'>Basics Settings</Text></Tab>
              <Tab _selected={{ color: 'black.lighten' }} border='none' p='19px 17px'><Image src={tools} alt='tools' mr='14px' /><Text fontSize={{ sm: '18px', xxl: '21px' }} lineHeight={{ sm: '24px', xxl: '26px' }} color='black.lighten'>Business Tools</Text></Tab>
            </TabList>

            <TabPanels>
              <TabPanel p='13px 21px'>
                <Box border='1px solid' borderColor='white.lighten2' h='calc(100% - 50px)'>
                  <Tabs className='tab-with-icons'>
                    <TabList borderColor='gray.light3' borderBottomWidth='1px'>
                      <Tab _selected={{ color: 'black.lighten' }} border='none' p='19px 17px'><Text fontSize={{ sm: '16px', xxl: 'xxl' }} lineHeight={{ sm: '20px', xxl: '22px' }} color='black.lighten'>Company Profile</Text></Tab>
                      <Tab _selected={{ color: 'black.lighten' }} border='none' p='19px 17px'><Text fontSize={{ sm: '16px', xxl: 'xxl' }} lineHeight={{ sm: '20px', xxl: '22px' }} color='black.lighten'>User Profile</Text></Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel p={{ sm: '20px', xxl: '30px' }}>
                        <Box w='100%' maxW='991px'>
                          <SimpleGrid spacing='20px 0'>
                            <SimpleGrid columns={2} spacing={{ sm: '0 30px', xxl: '0 60px' }}>
                              <Box d='inline-flex' alignItems='center' justifyContent='space-between' flexWrap='wrap' alignContent='flex-start'>
                                <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Company Logo</Text>
                                <Box maxW='300px' w='100%'>
                                  <Box position='relative' display='inline-flex'>
                                    <Box w='147px' h='147px' borderRadius='50%' overflow='hidden' display='inline-flex' alignItems='center' justifyContent='center' bg='black.lighten' border='3px solid' borderColor='blue.dark'>
                                      <Avatar name={companyValues.companyName} src={auth?.company?.accessProfileUrl} h='100%' w='100%' bg='transparent' css={css({
                                        '.chakra-avatar__initials': {
                                          fontSize: '38px',
                                          color: 'white'
                                        },
                                        '.chakra-avatar__img': {
                                          borderRadius: '0',
                                          width: 'auto',
                                          height: 'auto',
                                          maxHeight: '100%'
                                        }
                                      })} />
                                    </Box>
                                    <Box top='15px' right='4px' position='absolute' width='25px' height='25px' borderRadius='50%' display='inline-flex' alignItems='center' justifyContent='center' bg='blue.dark' cursor='pointer'>
                                      <label>
                                        <Icon cursor='pointer' fontSize='15px' color='white' as={MdModeEdit}></Icon>
                                        <Input type="file" accept=".jpeg, .jpg, .png" onChange={fileChange} name='company' w='0' h='0' border='none' outline='none' p='0' _focus={{ outline: 'none' }} />
                                      </label>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap' mb='20px'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Name</Text>
                                  <Input isDisabled={auth.user?.user_Type !== "super_admin"} variant='Bordered' type='text' maxW={{ sm: 'unset', xxl: '300px' }} placeholder='Inspection Company Name' name='companyName' value={companyValues.companyName} onChange={companyChange} />
                                </Box>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap' mb='20px'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten' data-disabled>Email</Text>
                                  <Input isDisabled={auth.user?.user_Type !== "super_admin" && (auth.user?.role && auth.company?.email)} maxW={{ sm: 'unset', xxl: '300px' }} variant='Bordered' type='text' placeholder='inspectaurora@gmail.com' name='email' value={companyValues.email} onChange={companyChange} />
                                </Box>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Website</Text>
                                  <Input isDisabled={auth.user?.user_Type !== "super_admin" && (auth.user?.role && auth.company?.website)} variant='Bordered' type='text' maxW={{ sm: 'unset', xxl: '300px' }} placeholder='www.inspectwithaurora.com' name='website' value={companyValues.website} onChange={companyChange} />
                                </Box>
                              </Box>
                            </SimpleGrid>
                            <SimpleGrid columns={2} spacing={{ sm: '0 30px', xxl: '0 60px' }}>
                              <FormControl
                                isRequired
                                display="inline-flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                              >
                                <FormLabel as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Address</FormLabel>
                                <Input id='autocomplete' autoComplete='off' isDisabled={auth.user?.AdminID && (auth.user?.role && auth.company?.companyAddress)} variant='Bordered' maxW={{ sm: 'unset', xxl: '300px' }} placeholder='1016 White Dove Cir Stockbridge, Georgia(GA), 30281' name='companyAddress' value={companyValues.companyAddress} onChange={companyChange} />
                              </FormControl>
                              <FormControl
                                isRequired
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                              >
                                <FormLabel as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>State</FormLabel>
                                <Input isDisabled={auth.user?.AdminID && (auth.user?.role && auth.company?.state)} variant='Bordered' type='text' maxW={{ sm: 'unset', xxl: '300px' }} placeholder='Type State Name' name='state' value={companyValues.state} onChange={companyChange} />
                              </FormControl>
                            </SimpleGrid>
                            <SimpleGrid columns={2} spacing={{ sm: '0 30px', xxl: '0 60px' }}>
                              <FormControl
                                isRequired
                                display="inline-flex"
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                              >
                                <FormLabel as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>City</FormLabel>
                                <Input isDisabled={auth.user?.AdminID && (auth.user?.role && auth.company?.city)} variant='Bordered' type='text' maxW={{ sm: 'unset', xxl: '300px' }} placeholder='Type City Name' name='city' value={companyValues.city} onChange={companyChange} />
                              </FormControl>
                              <FormControl
                                isRequired
                                display="flex"
                                alignContent="center"
                                justifyContent="space-between"
                                flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                              >
                                <FormLabel as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Zip</FormLabel>
                                <Input isDisabled={auth.user?.AdminID && (auth.user?.role && auth.company?.zipcode)} variant='Bordered' type='text' maxW={{ sm: 'unset', xxl: '300px' }} placeholder='Type Zip Code' name='zipcode' value={companyValues.zipcode} onChange={companyChange} />
                              </FormControl>
                            </SimpleGrid>
                          </SimpleGrid>
                          <Box mt='40px'>
                            <Text fontSize='17px'>
                              For any change/edit/update in the information filled above,please send us an email at <Text as='span' fontWeight='600' d='inline-block'>info@myauroraportal.com.</Text>
                            </Text>
                          </Box>
                          <Box d='flex' justifyContent='flex-end' mt='38px'>
                            {!!Object.keys(companyTouched).length && <Button variant='primary2' onClick={companyUpdatedSubmit}>Save Profile</Button>}
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel p={{ sm: '20px', xxl: '30px' }}>
                        <Box w='100%' maxW='991px'>
                          <SimpleGrid spacing='20px 0'>
                            <SimpleGrid columns={2} spacing={{ sm: '0 30px', xxl: '0 60px' }}>
                              <Box justifyContent='space-between' d='flex' flexWrap='wrap' alignItems='center'>
                                <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>User Image</Text>
                                <Box w='300px'>
                                  <Box position='relative' d='inline-flex'>
                                    <Box w='147px' h='147px' borderRadius='50%' overflow='hidden' d='inline-flex' alignItems='center' justifyContent='center' bg='black.lighten' border='3px solid' borderColor='blue.dark'>
                                      <Avatar name={`${auth?.user?.firstName} ${auth?.user?.lastName}`} src={auth?.user?.AccessProfileUrl} h='100%' w='100%' bg='transparent' css={css({
                                        '.chakra-avatar__initials': {
                                          fontSize: '38px',
                                          color: 'white'
                                        },
                                        '.chakra-avatar__img': {
                                          borderRadius: '0',
                                          width: 'auto',
                                          height: 'auto',
                                          maxHeight: '100%'
                                        }
                                      })} />
                                    </Box>
                                    <Box top='15px' right='4px' position='absolute' width='25px' height='25px' borderRadius='50%' display='inline-flex' alignItems='center' justifyContent='center' bg='blue.dark' cursor='pointer'>
                                      <label>
                                        <Icon cursor='pointer' fontSize='15px' color='white' as={MdModeEdit}></Icon>
                                        <Input type="file" accept=".jpeg, .jpg, .png*" onChange={fileChange} name='profile' w='0' h='0' border='none' outline='none' p='0' _focus={{ outline: 'none' }} />
                                      </label>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </SimpleGrid>
                            <SimpleGrid spacing='20px 0'>
                              <SimpleGrid columns={2} spacing={{ sm: '0 30px', xxl: '0 60px' }}>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten' data-disabled>First Name</Text>
                                  <Input maxW={{ sm: 'unset', xxl: '300px' }} isDisabled variant='Bordered' type='text' placeholder='Denwik' name='firstName' value={userUpdateValues.firstName} onChange={userUpdateChange} />
                                </Box>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten' data-disabled>Last Name</Text>
                                  <Input maxW={{ sm: 'unset', xxl: '300px' }} isDisabled variant='Bordered' type='text' placeholder='M' name='lastName' value={userUpdateValues.lastName} onChange={userUpdateChange} />
                                </Box>
                              </SimpleGrid>
                              <SimpleGrid columns={2} spacing={{ sm: '0 30px', xxl: '0 60px' }}>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten' data-disabled>Phone</Text>
                                  <Input maxW={{ sm: 'unset', xxl: '300px' }} isDisabled variant='Bordered' type='text' placeholder='+91 25875 62456' name='phone' value={userUpdateValues.phone} onChange={userUpdateChange} />
                                </Box>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten' data-disabled>Email</Text>
                                  <Input maxW={{ sm: 'unset', xxl: '300px' }} isDisabled variant='Bordered' type='text' placeholder='inspectaurora@gmail.com' name='email' value={userUpdateValues.email} onChange={userUpdateChange} />
                                </Box>
                              </SimpleGrid>
                              <SimpleGrid columns={2} spacing={{ sm: '0 30px', xxl: '0 60px' }}>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Address</Text>
                                  <Textarea maxW={{ sm: 'unset', xxl: '300px' }} variant='Bordered' placeholder='1016 White Dove Cir Stockbridge, Georgia(GA), 30281' name='address' value={userUpdateValues.address} onChange={userUpdateChange}></Textarea>
                                </Box>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap' alignContent='flex-start'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>State</Text>
                                  <Input maxW={{ sm: 'unset', xxl: '300px' }} variant='Bordered' type='text' placeholder='Type State Name' name='state' value={userUpdateValues.state} onChange={userUpdateChange} />
                                </Box>
                              </SimpleGrid>
                              <SimpleGrid columns={2} spacing={{ sm: '0 30px', xxl: '0 60px' }}>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap' alignContent='flex-start'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Certifications</Text>
                                  <Input maxW={{ sm: 'unset', xxl: '300px' }} variant='Bordered' type='text' placeholder='Type Certificates Name' name='user_Certificate' value={userUpdateValues.user_Certificate} onChange={userUpdateChange} />
                                </Box>
                                <Box justifyContent='space-between' d='flex' flexWrap='wrap' alignContent='flex-start'>
                                  <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Licenses</Text>
                                  <Input maxW={{ sm: 'unset', xxl: '300px' }} variant='Bordered' type='text' placeholder='Type License Name' name='user_LicenceNumber' value={userUpdateValues.user_LicenceNumber} onChange={userUpdateChange} />
                                </Box>
                              </SimpleGrid>
                            </SimpleGrid>
                            <Box d='flex' justifyContent='space-between' mt='38px' alignItems='center'>
                              <Text fontSize={{ sm: '16px', xxl: 'xxl' }} lineHeight='22px' cursor='pointer' textDecoration='underline' color='blue.light4' as='span' onClick={onOpenReset}>Change Password</Text>
                              {!!Object.keys(userUpdateTouched).length && <Button variant='primary2' onClick={userUpdatedSubmit}>Save Profile</Button>}
                            </Box>
                          </SimpleGrid>
                        </Box>
                        <Modal isOpen={isOpenReset} onClose={onCloseReset} size={'lg'} scrollBehavior='inside'>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader p='25px 28px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Change Password</ModalHeader>
                            <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={onCloseReset} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
                            <ModalBody p='0 28px'>
                              <Box justifyContent='space-between' d='flex' flexWrap='wrap' mb='15px'>
                                <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Old password</Text>
                                <Input variant='Bordered' type='password' placeholder='********' name='oldPassword' value={managePassValues.oldPassword} onChange={managePassChange} />
                              </Box>
                              <Box justifyContent='space-between' d='flex' flexWrap='wrap' mb='15px'>
                                <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>New password</Text>
                                <Input variant='Bordered' type='password' placeholder='********' name='password' value={managePassValues.password} onChange={managePassChange} />
                              </Box>
                              <Box justifyContent='space-between' d='flex' flexWrap='wrap'>
                                <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' w={{ sm: '100%', xxl: 'auto' }} color='black.lighten'>Confirm password</Text>
                                <Input variant='Bordered' type='password' placeholder='********' name='confirmPassword' value={managePassValues.confirmPassword} onChange={managePassChange} />
                              </Box>
                            </ModalBody>
                            <ModalFooter p='25px 28px'>
                              <Button variant='primary2' w='117px' onClick={managePasswordSubmit}>
                                Submit
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </TabPanel>
              <TabPanel p='13px 21px'>
                <Box border='1px solid' borderColor='white.lighten2' h='calc(100% - 50px)'>
                  <Tabs className='tab-with-icons'>
                    <TabList borderColor='gray.light3' borderBottomWidth='1px'>
                      <Tab _selected={{ color: 'black.lighten' }} border='none' p='19px 17px'><Text fontSize={{ sm: '16px', xxl: 'xxl' }} lineHeight={{ sm: '20px', xxl: '22px' }} color='black.lighten'>Agreements</Text></Tab>
                      <Tab _selected={{ color: 'black.lighten' }} border='none' p='19px 17px'><Text fontSize={{ sm: '16px', xxl: 'xxl' }} lineHeight={{ sm: '20px', xxl: '22px' }} color='black.lighten'>Payment</Text></Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel p={{ sm: '20px', xxl: '30px' }}>
                        {showAgreementForm ? (<Box maxW='1162px'>
                          <Box d='inline-flex'>
                            <Text as='h2' mb={{ sm: '22px', lg: '32px' }} color='black.lighten' fontSize={{ sm: '22px', xxl: '24px' }} lineHeight='30px' fontWeight='600'>Add Agreements</Text>
                          </Box>
                          <Box>
                            <Box d='flex' flexDirection='row' flexWrap='wrap' mb='29px'>
                              <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' color='black.lighten' w='100%'>Title</Text>
                              <Input variant='Bordered' type='text' placeholder='Add title' name='title' value={agreementTitle} onChange={(e) => { setAgreementTitle(e.target.value); setAgreementUpdated(true); }} />
                            </Box>
                            <Box d='flex' flexDirection='row' flexWrap='wrap'>
                              <Text as='label' fontSize={{ sm: '16px', xxl: 'xxl' }} pr='10px' mb={{ sm: '4px', xxl: '0' }} lineHeight='22px' color='black.lighten' w='100%'>Agreement</Text>
                              <TextEditor
                                value={agreementDesc}
                                onChange={editorUpdated}
                              />
                            </Box>
                          </Box>
                          <Box mt={{ sm: '37px', xxl: '47px' }}>
                            <Button w='90px' mr='15px' variant='primary2' onClick={submitAgreement}>{isAgreementUpdate.status ? "Update" : "Add"}</Button>
                            <Button w='90px' variant='outlined' onClick={closeAgreementForm}>Back</Button>
                          </Box>
                        </Box>) :
                          (<>
                            <Flex alignItems='center' mb='30px' justifyContent='space-between'>
                              <Text as='h2' color='black.lighten' fontSize='24px' lineHeight='30px' fontWeight='600'>Agreements</Text>
                              <Button variant='primary2' ml='20px' onClick={() => setShowAgreementForm(!showAgreementForm)}>
                                Create Agreement
                              </Button>
                            </Flex>
                            <Box display='flex' flexDirection='column'>
                              {auth?.agreements?.map((x: any) => {
                                return <Box key={x.id} display='flex' mb='20px' alignItems='center'>
                                  <Box mr='14px'>
                                    <Text fontSize='xxl' lineHeight='22px' color='black.lighten' mr='20px' w='100%' whiteSpace='pre-wrap'>{x.title}</Text>
                                  </Box>
                                  <Box ml='auto' display='inline-flex'>
                                    <Icon cursor='pointer' fontSize='22px' mr='15px' color='gray.primary' as={IoMdEye} onClick={() => handleViewAggrement(x)}></Icon>
                                    <Icon cursor='pointer' color='gray.primary' fontSize='18px' as={MdModeEdit} onClick={() => handleUpdateAggrement(x)}></Icon>
                                  </Box>
                                </Box>
                              })}
                            </Box></>)}
                      </TabPanel>
                      <TabPanel p={{ sm: '20px', xxl: '30px' }}>
                        <Flex w='100%' maxW='991px'>
                          <Flex position='relative' cursor='pointer' alignItems='center' justifyContent='center' maxW='288px' h='200px' borderRadius='8px' border='1px solid' borderColor={auth.user?.SelectAccountType === 'Stripe' ? 'blue.light3' : 'gray.light4'} w='100%' bg={auth.user?.SelectAccountType === 'Stripe' ? 'white.lighten8' : 'white'} onClick={() => { openStripe(); }}>
                            <Flex flexDirection='column' alignItems='center'>
                              <Box w='52px'>
                                <Image src={StripeIcon}></Image>
                              </Box>
                              <Text fontSize='xxl' fontWeight='600' mt='10px'>Stripe</Text>
                            </Flex>
                            {auth.user?.SelectAccountType === 'Stripe' && <Flex position='absolute' right='16px' top='16px' bg='blue.light3' borderRadius='50%' w='26px' h='26px' alignItems='center' justifyContent='center'>
                              <Icon color='white.primary' fontSize='18px' as={MdDone}></Icon>
                            </Flex>}
                            {auth.user?.SelectAccountType !== 'Stripe' && <Flex position='absolute' right='16px' top='16px' bg='gray.light3' borderRadius='50%' w='26px' h='26px' alignItems='center' justifyContent='center'>
                              <Icon color='white.primary' fontSize='18px' as={MdDone}></Icon>
                            </Flex>}
                          </Flex>
                          <Flex position='relative' cursor='pointer' alignItems='center' justifyContent='center' maxW='288px' h='200px' borderRadius='8px' border='1px solid' borderColor={auth.user?.SelectAccountType === 'Bank' ? 'blue.light3' : 'gray.light4'} w='100%' bg={auth.user?.SelectAccountType === 'Stripe' ? 'white.lighten8' : 'white'} ml='32px' onClick={() => { openShowPaymentModal(); }}>
                            <Flex flexDirection='column' alignItems='center'>
                              <Box w='52px'>
                                <Image src={BankAccount}></Image>
                              </Box>
                              <Text fontSize='xxl' fontWeight='600' mt='10px'>Bank Account</Text>
                            </Flex>
                            {auth.user?.SelectAccountType === 'Bank' && <Flex position='absolute' right='16px' top='16px' bg='blue.light3' borderRadius='50%' w='26px' h='26px' alignItems='center' justifyContent='center'>
                              <Icon color='white.primary' fontSize='18px' as={MdDone}></Icon>
                            </Flex>}
                            {auth.user?.SelectAccountType !== 'Bank' && <Flex position='absolute' right='16px' top='16px' bg='gray.light3' borderRadius='50%' w='26px' h='26px' alignItems='center' justifyContent='center'>
                              <Icon color='white.primary' fontSize='18px' as={MdDone}></Icon>
                            </Flex>}
                          </Flex>
                        </Flex>
                        <Modal isOpen={stripe} onClose={closeStripe} size='lg' scrollBehavior='inside'>
                          <ModalOverlay />
                          <ModalContent p='25px 0px'>
                            <ModalHeader
                              borderBottom="1px solid #DBDBDB52"
                              p="0px 25px 25px"
                            >
                              <Text fontSize="21px" lineHeight="26px" color="black.lighten" fontWeight="600">Manage Stripe</Text>
                            </ModalHeader>
                            <Icon
                              color="black.primary"
                              fontSize="24px"
                              as={IoMdClose}
                              onClick={closeStripe}
                              cursor="pointer"
                              position="absolute"
                              top="25px"
                              right="29px"
                            ></Icon>
                            <ModalBody p='22px 22px 0 22px'>
                              <Flex>
                                <Text>You can change the account API key, Token Number and Email-id</Text>
                                <Text ml='auto' pl='10px' color='blue.light3' whiteSpace='nowrap' fontSize='14px' mt='3px' _hover={{ textDecoration: 'underline' }}>
                                  <a href='https://home-inspection-test.s3.amazonaws.com/stripekey/get-stripe-details.pdf' target='_blank' rel="noreferrer">
                                    How to get Stripe details
                                  </a>
                                </Text>
                              </Flex>
                              <Box mt='22px'>
                                <Text fontWeight='600'>Publishable key</Text>
                                <Input
                                  type="text"
                                  maxW="unset"
                                  variant="Underlined"
                                  height="fitContent"
                                  fontSize="xl"
                                  name='publishableKey' value={stripeValues.publishableKey} onChange={stripeChange}
                                />
                              </Box>
                              <Box mt='22px'>
                                <Text fontWeight='600'>Secret key</Text>
                                <Input
                                  type="password"
                                  maxW="unset"
                                  variant="Underlined"
                                  height="fitContent"
                                  fontSize="xl"
                                  name='secretKey' value={stripeValues.secretKey} onChange={stripeChange}
                                  disabled={auth.stripeAccounts[0]?.id}
                                />
                              </Box>
                              {!auth.stripeAccounts[0]?.accountId && <Box mt='22px'>
                                <Text fontWeight='600'>Account id</Text>
                                <Input
                                  type="text"
                                  maxW="unset"
                                  variant="Underlined"
                                  height="fitContent"
                                  fontSize="xl"
                                  name='accountId' value={stripeValues.accountId} onChange={stripeChange}
                                />
                              </Box>}
                              <Flex mt='22px'>
                                <Checkbox variant='transparentSmall' colorScheme='transtarent' isChecked={defaultPaymentMethod === "Stripe" || (auth.user?.SelectAccountType === "Stripe" && auth.stripeAccounts[0]?.id)} onChange={() => setDefaultPaymentMethod("Stripe")} />
                                <Text ml='12px'>Do you want to use this payment method?</Text>
                              </Flex>
                              <Flex justify="space-between" mt="25px">
                                <Box>
                                  <Button variant="primary2" w="81px" onClick={saveStripeDetails}>
                                    Save
                                  </Button>
                                  <Button variant="grey" w="81px" ml='10px' onClick={closeStripe}>
                                    Cancel
                                  </Button>
                                </Box>
                                <Box>
                                  {auth.stripeAccounts[0]?.id &&
                                    <Button variant="redBtn" w="81px" ml='10px' onClick={handleDeleteStripeAccount}>
                                      Delete
                                    </Button>
                                  }
                                </Box>
                              </Flex>
                            </ModalBody>
                            <ModalFooter justifyContent='space-between' p='0'>

                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                        <Modal
                          isOpen={showPaymentModal}
                          onClose={closeShowPaymentModal}
                          size="xl"
                          scrollBehavior="inside"
                        >
                          <ModalOverlay />
                          <ModalContent borderRadius="10px" p='25px 0px'>
                            <ModalHeader
                              borderBottom="1px solid #DBDBDB52"
                              fontSize="21px"
                              lineHeight="26px"
                              color="black.lighten"
                              fontWeight="600"
                              p="0px 25px 25px"
                            >
                              Payment Information
                            </ModalHeader>
                            <Icon
                              color="black.primary"
                              fontSize="24px"
                              as={IoMdClose}
                              onClick={closeShowPaymentModal}
                              cursor="pointer"
                              position="absolute"
                              top="25px"
                              right="29px"
                            ></Icon>
                            <ModalBody pt='15px'>
                              <Box>
                                {!onlinePayment && (
                                  <>
                                    <Box>
                                      <>
                                        <Flex justifyContent='space-between' alignItems='center' mb='15px'>
                                          <Text color="black.lighten" fontSize='xxl' fontWeight="600" lineHeight='1'>Saved Accounts</Text>
                                          <Flex alignItems='center'>
                                            <Icon as={MdAdd}></Icon>
                                            <Link
                                              color='black.lighten'
                                              fontWeight='600'
                                              textDecoration='none !important'
                                              onClick={() => {
                                                setBankAccountValues(fillFormValues(BANK_ACCOUNT_FORM.default, BANK_ACCOUNT_FORM.default, ['id']));
                                                setAccountId('');
                                                setCreateBankMethod(null);
                                                setOnlinePayment(true)
                                              }}
                                            >
                                              Add New
                                            </Link>
                                          </Flex>
                                        </Flex>
                                        {auth.bankAccounts.map((currBankItem: any, id: number) => {
                                          return <Flex
                                            key={id}
                                            bg="white.lighten7"
                                            borderRadius="10px"
                                            p="15px 15px 15px 15px"
                                            w="100%"
                                            mb='10px'
                                          >
                                            <Flex w='100%'>
                                              <Checkbox
                                                variant="transparentSmall"
                                                colorScheme="transparent"
                                                alignSelf="flex-start"
                                                mt="3px"
                                                mr="20px"
                                                isChecked={currBankItem.id === checkDefaultBankAccount}
                                                onChange={() => setCheckDefaultBankAccount(currBankItem.id)}
                                              />
                                              <Flex justifyContent="space-between" w="100%">
                                                <Box>
                                                  <Box>
                                                    <Text fontWeight="600">Account Holder Name</Text>
                                                    <Text>{currBankItem.bankDetail?.accountHolderName}</Text>
                                                  </Box>
                                                  <Box mt="15px">
                                                    <Text fontWeight="600">Account Number</Text>
                                                    <Text>{currBankItem.bankDetail?.accountNo}</Text>
                                                  </Box>
                                                </Box>
                                                <Box mr='45px'>
                                                  <Box>
                                                    <Text fontWeight="600">Routing Number</Text>
                                                    <Text>{currBankItem.bankDetail?.routingNumber}</Text>
                                                  </Box>
                                                  <Box mt="15px">
                                                    <Text fontWeight="600">Bank Name</Text>
                                                    <Text>{currBankItem.bankDetail?.bankName}</Text>
                                                  </Box>
                                                </Box>
                                              </Flex>
                                            </Flex>
                                            <Flex onClick={() => { handleAccountEdit(currBankItem) }} cursor='pointer' bg='blue.primary' minW='26px' w='26px' h='26px' alignItems='center' justifyContent='center' borderRadius='50%'>
                                              <Icon as={MdEdit} color='white.primary'></Icon>
                                            </Flex>
                                            <Flex onClick={() => { handleAccountDelete(currBankItem) }} cursor='pointer' fontSize='16px' bg='red.dark' ml='10px' minW='26px' w='26px' h='26px' alignItems='center' justifyContent='center' borderRadius='50%'>
                                              <Icon as={MdDelete} color='white.primary'></Icon>
                                            </Flex>
                                          </Flex>
                                        })}
                                        {!auth.bankAccounts.length && <Text>No accounts added</Text>}
                                      </>
                                    </Box>
                                    <Button
                                      variant="primary2"
                                      w="117px"
                                      mt="15px"
                                      onClick={saveSavedAccountInfo}
                                    >
                                      Save
                                    </Button>
                                  </>
                                )}
                              </Box>
                              <Box>
                                {onlinePayment && (
                                  <>
                                    <SimpleGrid columns={2} spacing={3}>
                                      <Box>
                                        <Text>Account Holder Name</Text>
                                        <Input
                                          type="text"
                                          maxW="unset"
                                          variant="Bordered"
                                          height="40px"
                                          fontSize="xl"
                                          name='accountHolderName'
                                          value={bankAccountValues.accountHolderName}
                                          onChange={bankAccountChange}
                                        />
                                      </Box>
                                      <Box>
                                        <Text>Bank Name</Text>
                                        <Input
                                          type="text"
                                          maxW="unset"
                                          variant="Bordered"
                                          height="40px"
                                          fontSize="xl"
                                          name='bankName'
                                          value={bankAccountValues.bankName}
                                          onChange={bankAccountChange}
                                        />
                                      </Box>
                                      <Box>
                                        <Text>Account Number</Text>
                                        <Input
                                          type="number"
                                          maxW="unset"
                                          variant="Bordered"
                                          height="40px"
                                          fontSize="xl"
                                          name='accountNo'
                                          value={bankAccountValues.accountNo}
                                          onChange={bankAccountChange}
                                        />
                                      </Box>
                                      <Box>
                                        <Text>Confirm Account Number</Text>
                                        <Input
                                          type="number"
                                          maxW="unset"
                                          variant="Bordered"
                                          height="40px"
                                          fontSize="xl"
                                          name='confirmAccountNo'
                                          value={bankAccountValues.confirmAccountNo}
                                          onChange={bankAccountChange}
                                        />
                                      </Box>
                                      <Box>
                                        <Text>Routing Number</Text>
                                        <Input
                                          type="number"
                                          maxW="unset"
                                          variant="Bordered"
                                          height="40px"
                                          fontSize="xl"
                                          name='routingNumber'
                                          value={bankAccountValues.routingNumber}
                                          onChange={bankAccountChange}
                                        />
                                      </Box>
                                    </SimpleGrid>
                                    <Flex mt='22px'>
                                      <Checkbox variant='transparentSmall' colorScheme='transtarent' isChecked={createBankMethod === "Bank" || (accountId === checkDefaultBankAccount)} onChange={() => setCreateBankMethod("Bank")} />
                                      <Text ml='12px'>Save these details and use this as your payment method.</Text>
                                    </Flex>
                                    <Flex justify="space-between" mt="15px">
                                      <Button variant="primary2" w="117px" onClick={saveAccountInfo}>
                                        Save
                                      </Button>
                                      <Button variant="grey" w="117px" onClick={() => setOnlinePayment(false)}>
                                        Cancel
                                      </Button>
                                    </Flex>
                                  </>
                                )}
                              </Box>
                            </ModalBody>
                          </ModalContent>
                        </Modal>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <Modal isOpen={isOpenCropper} onClose={onCloseCropper} size={'5xl'} scrollBehavior='inside' closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='25px 28px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Add Image</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={onCloseCropper} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 28px 25px'>
            <Box>
              <Box style={{ width: "100%" }}>
                <Cropper
                  className='img-cropper'
                  zoomTo={0}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={cropImage}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCroppedImage(instance);
                  }}
                  guides={true}
                />
              </Box>
              <Box m='20px 0' overflow='hidden'>
                <Box w='auto'>
                  <Box
                    className="img-preview"
                    bg='gray.light3'
                    borderRadius='4px'
                  />
                </Box>
              </Box>
              <Button variant='primary2' onClick={getCropData}>
                Save
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AgreementModal setOpen={openAgreementModel} setClose={setOpenAgreementModel} title={selectedAgreement?.title} description={selectedAgreement?.description} />
    </>
  )
}

export default Settings