import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Heading,
  Input,
  Button,
  Select,
  Checkbox,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  Icon,
} from "@chakra-ui/react";
import logo from "../../../assets/images/logo.png";
import signupIllustration from "../../../assets/images/signup-banner.svg";
import { Flex } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { FiUser } from "react-icons/fi";
import { IoLocationSharp } from "react-icons/io5";
import { authState, setAuthError, signup } from "../../../redux/modules/auth";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useForm } from "../../../utils/hooks";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import visibilityOff from "../../../assets/images/visibility_off.svg";
import visibilityOn from "../../../assets/images/visibility_on.svg";
import Loader from "../../../components/common/loader";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { IoMdClose } from "react-icons/io";
import {
  getCompanyPolicy,
  superAdminState,
} from "../../../redux/modules/superAdmin/superAdmin";
import {
  googleMapAutocomplete,
  loadGoogleMapScript,
} from "../../../utils/common";
import { FaQuoteLeft } from "react-icons/fa";
import whitePattern from "../../../assets/images/white-pattern.jpg";

export default function Signup() {
  const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const auth: authState = useSelector((states: RootState) => states.auth);
  const superAdmin: superAdminState = useSelector(
    (stateSuper: RootState) => stateSuper.superAdmin
  );

  const USER_FORM = {
    default: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    field: {
      required: true,
      inputs: [
        "firstName",
        "lastName",
        "email",
        "phone",
        "password",
        "confirmPassword",
      ],
    },
  };

  const COMPANY_FORM = {
    default: {
      companyName: "",
      companyAddress: "",
      city: "",
      zipcode: "",
      referralSource: "",
    },
    field: {
      required: true,
      inputs: ["companyName", "companyAddress", "city", "zipcode"],
    },
  };

  const [state, selectState] = useState("");
  const [country, selectCountry] = useState("");
  const [isTermsChecked, setTermsChecked] = useState(true);
  const [isCheckedDisable, setisCheckedDisable] = useState(false);
  const [loaderActive, setLoaderActive] = useState(false);
  const {
    values: userValues,
    change: userChange,
    touched: userTouched,
    errors: userErrors,
  } = useForm(USER_FORM.default, USER_FORM.field);
  const {
    values: companyValues,
    change: companyChange,
    touched: companyTouched,
    setValues: companySetValues,
    errors: companyErrors,
  } = useForm(COMPANY_FORM.default, COMPANY_FORM.field);

  //company policy state
  const [companyPolicy, setCompanyPolicy] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { nextStep, activeStep, setStep } = useSteps({ initialStep: 0 });
  const {
    isOpen: openVerificationModal,
    onOpen: openEmailVerification,
    onClose: closeVerificationModal,
  } = useDisclosure();
  const {
    isOpen: showTerms,
    onOpen: openShowTerms,
    onClose: closeShowTerms,
  } = useDisclosure();
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  useEffect(() => {
    if (loadMap) {
      let autocomplete: google.maps.places.Autocomplete;
      const autocompleteInput = window.document.getElementById(
        "autocomplete"
      ) as HTMLInputElement;
      autocomplete = new google.maps.places.Autocomplete(autocompleteInput);

      // When a place is selected from the autocomplete dropdown
      autocomplete.addListener("place_changed", () => {
        const companyAddressValues = googleMapAutocomplete(autocomplete);
        if (companyAddressValues) {
          companySetValues({
            companyName: companyValues.companyName,
            companyAddress: companyAddressValues.streetNumber,
            city: companyAddressValues.addressCity,
            zipcode: companyAddressValues.addressZipcode || "",
            referralSource: companyValues.referralSource,
          });
          selectCountry(companyAddressValues.addressCountry);
          selectState(companyAddressValues.addressState);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyValues.companyAddress]);

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (activeStep === 0) {
      if (Object.keys(userTouched).length <= 0) {
        toastr.warning("", "Please first fill the form");
        return;
      }

      if (Object.keys(userErrors).length > 0) {
        toastr.warning("", userErrors[Object.keys(userErrors)[0]]);
        return;
      }

      nextStep(); // Move to next step
    } else if (activeStep === 1) {
      if (Object.keys(companyTouched).length <= 0) {
        toastr.warning("", "Please first fill the form");
        return;
      }
      if (
        !companyValues.companyAddress ||
        companyValues.companyAddress === ""
      ) {
        toastr.warning("", "Company Address is required");
        return;
      }
      if (!companyValues.city || companyValues.city === "") {
        toastr.warning("", "City is required");
        return;
      }
      if (!state || state === "") {
        toastr.warning("", "State is required");
        return;
      }
      if (!country || country === "") {
        toastr.warning("", "Country is required");
        return;
      }
      if (!companyValues.zipcode || companyValues.zipcode === "") {
        toastr.warning("", "Zipcode is required");
        return;
      }

      // if (Object.keys(companyErrors).length > 0) {
      //   toastr.warning('', companyErrors[Object.keys(companyErrors)[0]]);
      //   return;
      // }

      if (!isTermsChecked) {
        toastr.warning("", "You should agree with Terms & Conditions");
        return;
      }
      // loader loading
      setLoaderActive(true);
      const address =
        companyValues.companyAddress.toLowerCase() +
        " " +
        country.toLowerCase() +
        " " +
        state.toLowerCase() +
        " " +
        companyValues.city.toLowerCase() +
        " " +
        companyValues.zipcode;
      var geocoder = new google.maps.Geocoder();
      // geocoder.geocode({ address: address }, (results, status) => {
        // console.log(status, "status")
        // if (status === "OK") {
          // Call signup user api
          new Promise(() => {
            setLoaderActive(false);
            const data1: any = dispatch(
              signup({
                payload: {
                  firstName: userValues.firstName,
                  lastName: userValues.lastName,
                  email: userValues.email,
                  phone: userValues.phone,
                  password: userValues.password,
                  ...companyValues,
                  country,
                  state,
                },
              })
            );
            data1.then(function (value) {
              if (value.type === "auth/signup/fulfilled") {
                // loader loading
                openEmailVerification();
                toastr.success("", "Account created successfully");
              }
            });
          });
        // } else {
        //   // loader loading
        //   setLoaderActive(false);
        //   toastr.error("", "Please enter a valid address");
        //   return;
        // }
      // });
    }
  };

  //Company Terms and Conditions
  useEffect(() => {
    dispatch(getCompanyPolicy());
  }, [dispatch]);

  useEffect(() => {
    setCompanyPolicy(superAdmin?.companyPolicy);
  }, [superAdmin?.companyPolicy]);

  useEffect(() => {
    if (auth?.error) {
      const { message } = auth.error;
      toastr.error("", message ? message : "");
    }
    return () => {
      dispatch(setAuthError(null));
    };
  }, [auth.error, dispatch]);
  const handleCloseTermsModel = () => {
    setTermsChecked(true);
    setisCheckedDisable(true);
    closeShowTerms();
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // Access individual query parameters
    const firstName = queryParams.get("firstName");
    const lastName = queryParams.get("lastName");
    const email = queryParams.get("email");
    const phone = queryParams.get("phone");
    userValues.email = email;
    userValues.firstName = firstName;
    userValues.lastName = lastName;
    userValues.phone = phone;
  }, []);
  return (
    <>
      {(auth.loginLoading.length !== 0 || loaderActive === true) && <Loader />}
      <Box display="flex" minH="100vh">
        <Box
          minW={{ sm: "50%", lg: "45%", xxl: "40%" }}
          w={{ sm: "50%", lg: "45%", xxl: "40%" }}
          py="20px"
          px="20px"
          display="inline-flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          pos="relative"
          _before={{
            content: '""',
            bgImg: whitePattern,
            pos: "absolute",
            w: "100%",
            h: "100%",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            bgSize: "cover",
            opacity: "0.2",
          }}
        >
          <Carousel
            autoPlay
            interval={4500}
            infiniteLoop={true}
            stopOnHover={false}
            showArrows={false}
            showStatus={false}
          >
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pos="relative"
            >
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                top="205px"
                fontSize="70px"
                left="15px"
                opacity="0.09"
                pos="absolute"
              ></Icon>
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                bottom="110px"
                fontSize="55px"
                right="15px"
                opacity="0.09"
                pos="absolute"
                transform="rotate(180deg)"
              ></Icon>
              <Image
                maxH={{ sm: "82px", xxl: "102px" }}
                src={logo}
                alt="Sign Up Logo"
                mb={{ sm: "18px", xxl: "34px" }}
              />
              <Flex
                minHeight="220px"
                alignItems="center"
                flexDir="column"
                justifyContent="flex-start"
                mb="40px"
              >
                <Text fontSize={{ sm: "24px", xxl: "26px" }} textAlign="center">
                  "This inspection software is a BIG improvement from the one I
                  had before! It takes me much less time to complete my
                  inspection reports! Thank you."
                </Text>
                <Text
                  as="h3"
                  mt="10px"
                  fontSize={{ sm: "24px", xxl: "28px" }}
                  fontWeight="600"
                  textAlign="center"
                >
                  F.Menesis
                </Text>
              </Flex>
            </Box>
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pos="relative"
            >
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                top="205px"
                fontSize="70px"
                left="15px"
                opacity="0.09"
                pos="absolute"
              ></Icon>
              <Icon
                color="#456db5"
                as={FaQuoteLeft}
                bottom="110px"
                fontSize="55px"
                right="15px"
                opacity="0.09"
                pos="absolute"
                transform="rotate(180deg)"
              ></Icon>
              <Image
                maxH={{ sm: "82px", xxl: "102px" }}
                src={logo}
                alt="Sign Up Logo"
                mb={{ sm: "18px", xxl: "34px" }}
              />
              <Flex
                minHeight="220px"
                alignItems="center"
                flexDir="column"
                justifyContent="flex-start"
                mb="40px"
              >
                <Text fontSize={{ sm: "24px", xxl: "26px" }} textAlign="center">
                  “I really like the fact that I can go to the Property without
                  my clipboard and complete the whole inspection with Aurora.”
                </Text>
                <Text
                  as="h3"
                  mt="10px"
                  fontSize={{ sm: "24px", xxl: "28px" }}
                  fontWeight="600"
                  textAlign="center"
                >
                  R. Dunn
                </Text>
              </Flex>
            </Box>
          </Carousel>
        </Box>

        <Box
          minW={{ sm: "50%", lg: "55%", xxl: "60%" }}
          w={{ sm: "50%", lg: "55%", xxl: "60%" }}
          pos="relative"
          bg="#0A1F3E"
          overflow="hidden"
          _before={{
            content: '""',
            bg: "transparent",
            pos: "absolute",
            w: "215px",
            h: "215px",
            border: "40px solid",
            borderColor: "rgba(255, 255, 255, 0.04)",
            borderRadius: "50%",
            top: "-60px",
            left: "-60px",
          }}
          _after={{
            content: '""',
            bg: "transparent",
            pos: "absolute",
            w: "215px",
            h: "215px",
            border: "40px solid",
            borderColor: "rgba(255, 255, 255, 0.04)",
            borderRadius: "50%",
            bottom: "-60px",
            right: "-60px",
          }}
        >
          <Flex
            py="40px"
            overflow="auto"
            flexDir="row"
            flexWrap="wrap"
            width="100%"
            height="100%"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            pt="70px"
          >
            <Box w="100%" maxW="740px" p={{ sm: "0 25px" }} minH="702px">
              <Steps
                className="signup-stepper"
                variant="custom"
                onClickStep={() => activeStep === 1 && setStep(0)}
                activeStep={activeStep}
                padding="0"
                display="flex"
                justifyContent="center"
                pb="70px"
                flex="unset"
              >
                <Step label="Basic information" key="login" icon={FiUser}>
                  <Box display="flex">
                    <Box w="100%">
                      <Box
                        mb={{ sm: "20px", xxl: "30px" }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        w="100%"
                      >
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                            >
                              First Name
                            </FormLabel>
                            <Input
                              isInvalid={
                                userTouched["firstName"] &&
                                userErrors["firstName"]
                              }
                              variant="Simple"
                              placeholder="First Name"
                              name="firstName"
                              value={userValues.firstName}
                              onChange={userChange}
                            />
                          </FormControl>
                        </Box>
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                            >
                              Last Name
                            </FormLabel>
                            <Input
                              isInvalid={
                                userTouched["lastName"] &&
                                userErrors["lastName"]
                              }
                              variant="Simple"
                              placeholder="Last Name"
                              name="lastName"
                              value={userValues.lastName}
                              onChange={userChange}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        mb={{ sm: "20px", xxl: "30px" }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        w="100%"
                      >
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                            >
                              Email ID
                            </FormLabel>
                            <Input
                              isInvalid={
                                userTouched["email"] && userErrors["email"]
                              }
                              variant="Simple"
                              type="emal"
                              placeholder="username@gmail.com"
                              name="email"
                              value={userValues.email}
                              onChange={userChange}
                            />
                          </FormControl>
                        </Box>
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                            >
                              Phone
                            </FormLabel>
                            <Input
                              isInvalid={
                                userTouched["phone"] && userErrors["phone"]
                              }
                              variant="Simple"
                              type="tel"
                              placeholder="Enter here.."
                              name="phone"
                              value={userValues.phone}
                              onChange={userChange}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                            >
                              Password
                            </FormLabel>
                            <InputGroup>
                              <Input
                                isInvalid={
                                  userTouched["password"] &&
                                  userErrors["password"]
                                }
                                type={showPassword ? "text" : "password"}
                                variant="Simple"
                                placeholder="password"
                                name="password"
                                value={userValues.password}
                                onChange={userChange}
                              />
                              <InputRightElement top="8px" right="12px">
                                <Button
                                  p="0"
                                  minWidth="unset"
                                  bg="transparent"
                                  _hover={{ bg: "transparent" }}
                                  _focus={{ outline: "none" }}
                                  _active={{ bg: "transparent" }}
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <Image
                                      w="18px"
                                      src={visibilityOff}
                                      alt="visibility off"
                                    />
                                  ) : (
                                    <Image
                                      w="18px"
                                      src={visibilityOn}
                                      alt="visibility on"
                                    />
                                  )}
                                </Button>
                              </InputRightElement>
                            </InputGroup>
                            <Heading
                              as="h4"
                              fontSize="15px"
                              fontWeight="normal"
                              color="#fff"
                              mt="10px"
                              w="284px"
                            >
                              Use 8 or more characters with a mix of letters,
                              numbers & symbols
                            </Heading>
                          </FormControl>
                        </Box>
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                              display="flex"
                              alignItems="flex-start"
                            >
                              Confirm Password
                            </FormLabel>
                            <Input
                              isInvalid={
                                userTouched["confirmPassword"] &&
                                userErrors["confirmPassword"]
                              }
                              type="password"
                              variant="Simple"
                              placeholder="Enter here.."
                              name="confirmPassword"
                              value={userValues.confirmPassword}
                              onChange={userChange}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        w="100%"
                        display="flex"
                        justifyContent="flex-end"
                        mt="42px"
                      >
                        <Button
                          variant="primary"
                          w={{ sm: "250px", lg: "340px" }}
                          onClick={submitHandler}
                        >
                          Next Step
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Step>

                <Step label="Address" key="Verification" icon={IoLocationSharp}>
                  <Box display="flex">
                    <Box>
                      <Box
                        mb={{ sm: "20px", xxl: "30px" }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        w="100%"
                      >
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                            >
                              Company Name
                            </FormLabel>
                            <Input
                              isInvalid={
                                companyTouched["companyName"] &&
                                companyErrors["companyName"]
                              }
                              variant="Simple"
                              name="companyName"
                              placeholder="Type Company Name"
                              value={companyValues.companyName}
                              onChange={companyChange}
                            />
                          </FormControl>
                        </Box>
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                            >
                              Company Address
                            </FormLabel>
                            <Input
                              id="autocomplete"
                              autoComplete="off"
                              isInvalid={
                                companyTouched["companyAddress"] &&
                                companyErrors["companyAddress"]
                              }
                              variant="Simple"
                              placeholder="Company Address"
                              name="companyAddress"
                              value={companyValues.companyAddress}
                              onChange={companyChange}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        mb={{ sm: "20px", xxl: "30px" }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        w="100%"
                      >
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <Heading
                            as="h4"
                            fontSize="xxl"
                            fontWeight="normal"
                            color="#fff"
                            mb="10px"
                          >
                            Country
                          </Heading>
                          <Box className="country-dropdown">
                            <CountryDropdown
                              value={country}
                              onChange={selectCountry}
                            />
                          </Box>
                        </Box>
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <Heading
                            as="h4"
                            fontSize="xxl"
                            fontWeight="normal"
                            color="#fff"
                            mb="10px"
                          >
                            State
                          </Heading>
                          <Box className="country-dropdown">
                            <RegionDropdown
                              country={country}
                              value={state}
                              onChange={selectState}
                            />
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        mb={{ sm: "20px", xxl: "30px" }}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        w="100%"
                      >
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                            >
                              City
                            </FormLabel>
                            <Input
                              isInvalid={
                                companyTouched["city"] && companyErrors["city"]
                              }
                              variant="Simple"
                              placeholder="Type City Name"
                              name="city"
                              value={companyValues.city}
                              onChange={companyChange}
                            />
                          </FormControl>
                        </Box>
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <FormControl isRequired>
                            <FormLabel
                              as="h4"
                              fontSize="xxl"
                              fontWeight="normal"
                              color="#fff"
                              mb="10px"
                              display="flex"
                              alignItems="flex-start"
                            >
                              Zip Code
                            </FormLabel>
                            <Input
                              isInvalid={
                                companyTouched["zipcode"] &&
                                companyErrors["zipcode"]
                              }
                              variant="Simple"
                              placeholder="Enter Zip Code Number"
                              name="zipcode"
                              value={companyValues.zipcode}
                              onChange={companyChange}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="space-between"
                        w="100%"
                      >
                        <Box
                          w={{ sm: "calc(50% - 5px)", md: "calc(50% - 10px)" }}
                        >
                          <Heading
                            as="h4"
                            fontSize="xxl"
                            fontWeight="normal"
                            color="#fff"
                            mb="10px"
                            display="flex"
                            alignItems="flex-start"
                            mt="20px"
                          >
                            Referral Source
                          </Heading>
                          <Select
                            variant="Simple"
                            w="100%"
                            placeholder="Referral Source"
                            name="referralSource"
                            value={companyValues.referralSource}
                            onChange={companyChange}
                            bg="white.primary"
                          >
                            <option value="Word of mouth">Word of mouth</option>
                            <option value="Website">Website</option>
                            <option value="Youtube">Youtube</option>
                            <option value="Instagram">Instagram</option>
                          </Select>
                        </Box>
                      </Box>

                      <Box w="100%" display="flex" flexWrap="wrap" mt="42px">
                        <Checkbox
                          pr="10px"
                          variant="simple"
                          mb="20px"
                          isChecked={isTermsChecked}
                          disabled={isCheckedDisable}
                          defaultChecked
                          onChange={(e) => setTermsChecked(e.target.checked)}
                        ></Checkbox>
                        <Text color="#fff">
                          I Agree to all the{" "}
                          <Text
                            as="span"
                            cursor="pointer"
                            onClick={() => openShowTerms()}
                            color="blue.primary"
                          >
                            Terms & Conditions
                          </Text>{" "}
                          of Inspect Source{" "}
                        </Text>
                        <Button
                          ml="auto"
                          minW={{ sm: "250px", lg: "340px" }}
                          variant="primary"
                          onClick={submitHandler}
                        >
                          Sign Up
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Step>
              </Steps>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Modal
        isOpen={openVerificationModal}
        onClose={closeVerificationModal}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          textAlign="center"
        >
          <ModalHeader p={{ sm: "35px 50px 20px", xxl: "45px 50px 30px" }}>
            <Text
              as="h2"
              fontSize="24px"
              lineHeight="26px"
              color="black.lighten"
              fontWeight="600"
              mb="10px"
            >
              Verify your email
            </Text>
            <Text
              fontSize="18px"
              color="gray.darken"
              fontWeight="normal"
              as="p"
            >
              You will need to verify your email to complete signup
            </Text>
          </ModalHeader>
          <ModalBody
            p="8px 50px"
            textAlign="center"
            d="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Image
              maxW={{ sm: "260px", xxl: "300px" }}
              src={signupIllustration}
              alt="verification-illustration"
            ></Image>
            <Text
              maxW="450px"
              as="p"
              fontSize="17px"
              lineHeight="24px"
              color="gray.primary"
              mt={{ sm: "5px", xxl: "25px" }}
            >
              An email has been sent at{" "}
              <Text as="span" color="black.lighten">
                {userValues.email}
              </Text>{" "}
              If you have not recieved the email please check the spam folder{" "}
            </Text>
          </ModalBody>
          <ModalFooter
            justifyContent="center"
            p={{ sm: "30px 50px 25px", xxl: "40px 50px 35px" }}
          >
            <Button
              variant="primary"
              fontSize="16px"
              color="white.primary"
              fontWeight="normal"
              mr="20px"
              height="45px"
              minW="120px"
              onClick={() => (window.location.href = "/login")}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        variant="centerModal"
        isOpen={showTerms}
        onClose={closeShowTerms}
        size="full"
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="30px 30px 10px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Terms and Conditions
            <Icon
              color="black.primary"
              fontSize="24px"
              as={IoMdClose}
              onClick={closeShowTerms}
              cursor="pointer"
              position="absolute"
              top="29px"
              right="29px"
            ></Icon>
          </ModalHeader>
          <ModalBody p="0 30px 30px">
            {companyPolicy?.map((policy: any) => {
              return (
                <Flex
                  justifyContent="space-between"
                  cursor="pointer"
                  color="gray.primary"
                  alignItems="center"
                  key={policy.id}
                >
                  <Box>
                    <Text fontSize="21px" color="black.primary">
                      {policy.title}
                    </Text>
                    <Text fontSize="18px" color="gray.primary" mb="10px">
                      {policy.description}
                    </Text>
                  </Box>
                </Flex>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary2"
              ml="auto"
              minW="120px"
              onClick={handleCloseTermsModel}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
