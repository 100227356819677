import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Tooltip,
  FormControl,
  Textarea,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { ImSearch } from "react-icons/im";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { useForm } from "../../utils/hooks";
import {
  createInspector,
  getInspectors,
  inspectorState,
  updateInspector,
  deleteInspector,
  blockInspector,
  setInspectorError,
} from "../../redux/modules/admin/inspector";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { fillFormValues } from "../../utils/common";
import { RootState } from "../../redux/store";
import { authState } from "../../redux/modules/auth";
import Loader from "../common/loader";
import {
  createSuperAdminHomeInspector,
  deleteStaff,
  getStaffSuperAdmin,
  superAdminState,
  setSuperAdminError,
} from "../../redux/modules/superAdmin/superAdmin";

export default function StaffManagement() {
  const dispatch = useDispatch();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const inspector: inspectorState = useSelector(
    (state: RootState) => state.inspector
  );
  const superAdmin: superAdminState = useSelector(
    (state: RootState) => state.superAdmin
  );
  const {
    isOpen: addStaff,
    onOpen: openAddStaff,
    onClose: closeAddStaff,
  } = useDisclosure();
  const {
    isOpen: deleteConfirmation,
    onOpen: openDeleteConfirmation,
    onClose: closeDeleteConfirmation,
  } = useDisclosure();
  const {
    isOpen: showCreateHomeInspector,
    onOpen: openCreateHomeInspector,
    onClose: closeCreateHomeInspector,
  } = useDisclosure();
  const {
    isOpen: noteAdd,
    onOpen: openNote,
    onClose: closeNote,
  } = useDisclosure();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [editStaff, setEditStaff] = useState(null);
  const [staff, setStaff] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [staffId, setStaffId] = useState(null);
  const [staffValue, setStaffValue] = useState<any>({});
  const [noteValue, setNoteValue] = useState("");
  const [inspectorSubPlan, setInspectorSubPlan] = useState("monthly");

  const ADD_STAFF_FORM = {
    default: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      designation: "",
    },
    field: {
      required: true,
      inputs: [
        "firstName",
        "lastName",
        "email",
        "phone",
        auth.user?.user_Type === "super_admin" && "designation",
      ],
    },
  };
  const {
    values: addStaffValues,
    change: addStaffChange,
    touched: addStaffTouched,
    errors: addStaffErrors,
    setValues: setAddStaffValues,
  } = useForm(ADD_STAFF_FORM.default, ADD_STAFF_FORM.field);

  const handleAddStaff = () => {
    setEditStaff(false);
    setAddStaffValues(
      fillFormValues(ADD_STAFF_FORM.default, ADD_STAFF_FORM.default, ["id"])
    );
    openAddStaff();
  };

  const formSubmit = () => {
    if (Object.keys(addStaffTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }
    if (Object.keys(addStaffErrors).length > 0) {
      toastr.warning("", addStaffErrors[Object.keys(addStaffErrors)[0]]);
      return;
    }
    if (auth?.user?.user_Type === "company_admin") {
      closeAddStaff();
      openCreateHomeInspector();
    } else {
      new Promise(() => {
        const data: any = dispatch(
          createSuperAdminHomeInspector({
            payload: {
              ...addStaffValues,
              companyDetailId: auth.user.companyDetailId,
            },
          })
        );
        data.then(function (value) {
          if (value.type === "superadmin/create/staffManagement/fulfilled") {
            closeAddStaff();
            dispatch(getStaffSuperAdmin());
            toastr.success("", "Staff Added successfully");
          }
        });
      });
    }
  };

  const handleCreateHomeInspector = () => {
    new Promise(() => {
      const data1: any = dispatch(
        createInspector({
          payload: {
            ...addStaffValues,
            companyDetailId: auth.user.companyDetailId,
            userId: auth.user.id,
            planType: inspectorSubPlan,
          },
        })
      );
      data1.then(function (value) {
        if (value.type === "inspector/createInspector/fulfilled") {
          window.open(value?.payload?.url, "_self"); // Redirect for checkout
          closeCreateHomeInspector();
        }
      });
    });
  };

  const updateForm = () => {
    if (Object.keys(addStaffTouched).length <= 0) {
      toastr.warning("", "Please first update the form");
      return;
    }
    if (Object.keys(addStaffErrors).length > 0) {
      toastr.warning("", addStaffErrors[Object.keys(addStaffErrors)[0]]);
      return;
    }

    let updateEmail = {};
    if (addStaffValues.email !== userEmail) {
      updateEmail = { email: addStaffValues.email };
    }
    new Promise(() => {
      const data2: any = dispatch(
        updateInspector({
          staffId: staffId,
          payload: {
            firstName: addStaffValues.firstName,
            password: addStaffValues.password,
            lastName: addStaffValues.lastName,
            ...updateEmail,
            designation: addStaffValues.designation,
            phone: addStaffValues.phone,
          },
        })
      );
      data2.then(function (value) {
        if (value.type === "inspector/updateInspector/fulfilled") {
          closeAddStaff();
          setEditStaff(false);
          if (auth.user?.user_Type === "company_admin") {
            dispatch(getInspectors());
            toastr.success("", "Inspector details updated successfully");
          } else {
            dispatch(getStaffSuperAdmin());
            toastr.success("", "Staff details updated successfully");
          }
        }
      });
    });
  };

  const handleBlockStaff = (e: boolean, BlockStaffId: string) => {
    if (e === true) {
      openNote();
      setStaffValue({ e, BlockStaffId });
    } else {
      new Promise(() => {
        const data3: any = dispatch(
          blockInspector({ staffId: BlockStaffId, payload: { blockValue: e } })
        );
        data3.then(function (value) {
          if (value.type === "inspector/blockInspector/fulfilled") {
            toastr.success("", "Staff unblocked successfully");
            if (auth.user?.user_Type === "company_admin") {
              dispatch(getInspectors());
            } else {
              dispatch(getStaffSuperAdmin());
            }
          }
        });
      });
    }
  };

  const blockStaff = (e) => {
    e.preventDefault();
    if (!noteValue || noteValue === "") {
      toastr.warning("", "Note is required for Block");
      return;
    }
    new Promise(() => {
      const data4: any = dispatch(
        blockInspector({
          staffId: staffValue.BlockStaffId,
          payload: { blockValue: staffValue.e, block_description: noteValue },
        })
      );
      data4.then(function (value) {
        if (
          value.type === "inspector/blockInspector/fulfilled" &&
          staffValue.e === true
        ) {
          setNoteValue("");
          closeNote();
          toastr.success("", "Staff blocked successfully");
          if (auth.user?.user_Type === "company_admin") {
            dispatch(getInspectors());
          } else {
            dispatch(getStaffSuperAdmin());
          }
        }
      });
    });
  };

  const handleEditStaff = (selectedStaff: any, inspectorId: string) => {
    setStaffId(inspectorId);
    setEditStaff(true);
    setUserEmail(selectedStaff.email);
    setAddStaffValues({
      firstName: selectedStaff.firstName,
      lastName: selectedStaff.lastName,
      email: selectedStaff.email,
      phone: selectedStaff.phone,
      designation:
        auth.user?.user_Type === "super_admin" && selectedStaff.designation,
    });
    openAddStaff();
  };

  const handleDeleteStaffInspector = () => {
    if (auth.user?.user_Type === "company_admin") {
      new Promise(() => {
        const data5: any = dispatch(deleteInspector({ paramId: staffId }));
        data5.then(function (value) {
          if (value.type === "inspector/deleteInspector/fulfilled") {
            closeDeleteConfirmation(); // Close Confirmation Modal
            dispatch(getInspectors());
            toastr.success("", "Inspector deleted successfully");
          }
        });
      });
    } else {
      new Promise(() => {
        const data5: any = dispatch(deleteStaff({ paramId: staffId }));
        data5.then(function (value) {
          if (value.type === "superadmin/deleteStaff/fulfilled") {
            closeDeleteConfirmation(); // Close Confirmation Modal
            dispatch(getStaffSuperAdmin());
            toastr.success("", "Staff deleted successfully");
          }
        });
      });
    }
  };
  const handleDeleteInspectorModal = (inspectorId: string) => {
    setStaffId(inspectorId);
    openDeleteConfirmation(); // Open Confirmation Modal
  };

  const capitalizeFirstLetter = (str: string): string => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const tableColumns = [
    {
      name: "Name",
      selector: (row: any) => row.user_name,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
      minWidth: "330px",
      allowOverflow: true,
    },
    {
      name: "Contact No",
      selector: (row: any) => row.contact_no,
      minWidth: "150px",
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Plan",
      selector: (row: any) => row.plan,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Block Inspector",
      selector: (row: any) => row.block_staff,
      minWidth: "150px",
    },
    {
      name: "Manage",
      selector: (row: any) => row.manage,
      minWidth: "80px",
    },
  ];

  const superAdminTableColumns = [
    {
      name: "Name",
      selector: (row: any) => row.user_name,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
      minWidth: "330px",
      allowOverflow: true,
    },
    {
      name: "Contact No",
      selector: (row: any) => row.contact_no,
      minWidth: "150px",
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Block Staff",
      selector: (row: any) => row.block_staff,
      minWidth: "150px",
    },
    {
      name: "Designation",
      selector: (row: any) => row.designation,
      minWidth: "250px",
    },
    {
      name: "Manage",
      selector: (row: any) => row.manage,
      minWidth: "80px",
    },
  ];

  const tableDataItems = staff.map((selectedStaff: any) => {
    return {
      user_name: (
        <>
          <Box display="inline-flex" alignItems="center">
            <Text className="inspector-name">
              {selectedStaff.firstName} {selectedStaff.lastName}
            </Text>
          </Box>
        </>
      ),
      email: (
        <>
          <Box>
            <Text className="inspector-email">{selectedStaff.email}</Text>
          </Box>
        </>
      ),
      contact_no: (
        <>
          <Text>{selectedStaff.phone}</Text>
        </>
      ),
      status: (
        <>
          <Box
            bg={
              selectedStaff.homeInspector_block
                ? "red.lighten"
                : "green.lighten2"
            }
            p="6px 24px"
            borderRadius="20px"
          >
            <Text
              color={
                selectedStaff.homeInspector_block ? "red.dark" : "green.lighten"
              }
            >
              {selectedStaff.homeInspector_block ? "Inactive" : "Active"}
            </Text>
          </Box>
        </>
      ),
      block_staff: (
        <Checkbox
          className="switch"
          mr="15px"
          variant="transparent"
          colorScheme="transparent"
          isChecked={selectedStaff.homeInspector_block}
          onChange={(e) => {
            handleBlockStaff(e.target.checked, selectedStaff.id);
          }}
        ></Checkbox>
      ),
      designation: (
        <>
          <Text
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            maxW="200px"
            display="inline-block"
          >
            {auth.user?.user_Type === "super_admin" &&
              selectedStaff.designation}
          </Text>
        </>
      ),
      plan: (
        <>
          {selectedStaff.planType && (
            <Box
              w="82px"
              justifyContent="center"
              bg={
                selectedStaff.planType === "year"
                  ? "red.ligthen4"
                  : "purple.light"
              }
              color={
                selectedStaff.planType === "year"
                  ? "red.darken2"
                  : "purple.dark"
              }
              d="inline-flex"
              alignItems="center"
              h="26px"
              p="0 8px"
              borderRadius="30px"
            >
              {capitalizeFirstLetter(selectedStaff?.planType)}
            </Box>
          )}
        </>
      ),
      manage: (
        <>
          <Tooltip
            hasArrow
            label={
              auth.user?.user_Type === "company_admin"
                ? "Edit Inspector"
                : "Edit Staff"
            }
            fontSize="14px"
            bg="gray.light12"
            color="white.primary"
            placement="top"
          >
            <Button
              variant="iconBtn"
              minW="unset"
              p="0"
              color="gray.primary"
              mr="15px"
              onClick={() => handleEditStaff(selectedStaff, selectedStaff.id)}
            >
              <Icon fontSize="18px" as={MdEdit}></Icon>
            </Button>
          </Tooltip>
          <Tooltip
            hasArrow
            label={
              auth.user?.user_Type === "company_admin"
                ? "Delete Inspector"
                : "Delete Staff"
            }
            fontSize="14px"
            bg="gray.light12"
            color="white.primary"
            placement="top"
          >
            <Button
              fontSize="18px"
              variant="iconBtn"
              minW="unset"
              p="0"
              color="gray.primary"
              mr="15px"
              onClick={() => handleDeleteInspectorModal(selectedStaff.id)}
            >
              <Icon as={MdDelete}></Icon>
            </Button>
          </Tooltip>
        </>
      ),
    };
  });

  useEffect(() => {
    if (auth.user?.user_Type === "company_admin") {
      dispatch(getInspectors());
    }
    if (auth.user?.user_Type === "super_admin") {
      dispatch(getStaffSuperAdmin());
    }
  }, [auth.user?.user_Type, dispatch]);

  useEffect(() => {
    if (auth.user?.user_Type) {
      if (auth.user?.user_Type === "company_admin") {
        setStaff(inspector?.inspectors);
      } else {
        setStaff(superAdmin?.superAdminStaff);
      }
    }
  }, [auth.user?.user_Type, inspector.inspectors, superAdmin?.superAdminStaff]);

  useEffect(() => {
    if (searchKeyword.match(/^[a-zA-Z0-9!@. ]+$/g)) {
      if (auth.user?.user_Type === "company_admin") {
        let data: any[] = inspector.inspectors.filter((inspectorDetails) =>
          (inspectorDetails?.phone).match(searchKeyword)
        );
        data.push(
          ...inspector.inspectors.filter(
            (inspectorDetails) =>
              inspectorDetails?.email &&
              `${inspectorDetails?.email}`.match(searchKeyword)
          )
        );
        data.push(
          ...inspector.inspectors.filter(
            (inspectorDetails) =>
              inspectorDetails?.firstName &&
              `${inspectorDetails?.firstName} ${inspectorDetails?.lastName}`.match(
                searchKeyword
              )
          )
        );

        data = data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );
        setStaff(data);
      } else if (auth.user?.user_Type === "super_admin") {
        let data: any[] = superAdmin?.superAdminStaff.filter((staffDetails) =>
          (staffDetails?.phone).match(searchKeyword)
        );
        data.push(
          ...superAdmin?.superAdminStaff.filter(
            (staffDetails) =>
              staffDetails?.email &&
              `${staffDetails?.email}`.match(searchKeyword)
          )
        );
        data.push(
          ...superAdmin?.superAdminStaff.filter(
            (staffDetails) =>
              staffDetails?.firstName &&
              `${staffDetails?.firstName} ${staffDetails?.lastName}`.match(
                searchKeyword
              )
          )
        );
        data.push(
          ...superAdmin?.superAdminStaff.filter(
            (staffDetails) =>
              staffDetails?.designation &&
              `${staffDetails?.designation}`.match(searchKeyword)
          )
        );
        data = data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );
        setStaff(data);
      }
    } else {
      setStaff(
        auth.user?.user_Type === "company_admin"
          ? inspector.inspectors
          : superAdmin.superAdminStaff
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  useEffect(() => {
    if (inspector?.error) {
      const { message } = inspector.error;
      toastr.error("", message ? message : "");
    }
    return () => {
      dispatch(setInspectorError(null));
    };
  }, [inspector.error, dispatch]);

  useEffect(() => {
    if (superAdmin?.error) {
      const { message } = superAdmin.error;
      toastr.error("", message ? message : "");
    }
    return () => {
      dispatch(setSuperAdminError(null));
    };
  }, [superAdmin.error, dispatch]);

  return (
    <>
      {inspector.loading.length !== 0 && <Loader />}
      <Box p="29px 30px 20px 20px" minH="calc(100vh - 70px)">
        <Box
          d="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="28px"
        >
          <Box d="inline-flex" alignItems="center">
            <Text
              as="h1"
              mr="20px"
              color="black.lighten"
              fontSize="24px"
              fontWeight="600"
            >
              {auth.user?.user_Type === "company_admin" ? "Inspector" : "Staff"}
            </Text>
            <Box bg="orange.primary" p="0 8px" h="18px" borderRadius="5px">
              <Text fontSize="12px" color="white.primary">
                {inspector.inspectors.length ||
                  superAdmin.superAdminStaff.length}
              </Text>
            </Box>
          </Box>
          <Box display="inline-flex">
            <InputGroup w="282px" mr="20px">
              <InputLeftElement
                left="12px"
                top="0"
                _before={{
                  content: '""',
                  position: "absolute",
                  right: "-8px",
                  height: "23px",
                  width: "1px",
                  background: "blue.light2",
                }}
                children={
                  <Icon fontSize="17px" color="blue.dark3" as={ImSearch} />
                }
              />
              <Input
                variant="Search"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder={
                  auth.user?.user_Type === "company_admin"
                    ? "Search Inspector"
                    : "Search Staff"
                }
                h="40px"
              />
            </InputGroup>
            <Button variant="primary3" onClick={handleAddStaff}>
              {auth.user?.user_Type === "company_admin"
                ? "Add Inspector"
                : "Add Staff"}
            </Button>
          </Box>
        </Box>
        <Box
          borderRadius="12px"
          overflow="hidden"
          boxShadow="0px 0px 16px #f1f1f1c7"
        >
          <DataTable
            className="hi-datatable-component"
            data={tableDataItems}
            columns={
              auth.user?.user_Type === "company_admin"
                ? tableColumns
                : superAdminTableColumns
            }
            pagination
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="calc(100vh - 252px)"
          />
        </Box>
      </Box>

      <Modal
        isOpen={addStaff}
        onClose={closeAddStaff}
        size="xl"
        scrollBehavior="inside"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="30px 38px 15px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            {editStaff
              ? `${
                  auth.user?.user_Type === "company_admin"
                    ? "Edit Inspector"
                    : "Edit Staff"
                }`
              : `${
                  auth.user?.user_Type === "company_admin"
                    ? "Add Inspector"
                    : "Add Staff"
                }`}
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={closeAddStaff}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="8px 38px">
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text
                  w="100%"
                  as="label"
                  fontSize={{ sm: "16px", xxl: "xxl" }}
                  mb="4px"
                  color="black.lighten"
                  textAlign="left"
                >
                  First Name
                </Text>
                <Input
                  w="100%"
                  maxW="unset"
                  type="text"
                  variant="Bordered"
                  placeholder="First Name"
                  name="firstName"
                  value={addStaffValues.firstName}
                  onChange={addStaffChange}
                  isInvalid={
                    addStaffTouched["firstName"] && addStaffErrors["firstName"]
                  }
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text
                  w="100%"
                  as="label"
                  fontSize={{ sm: "16px", xxl: "xxl" }}
                  mb="4px"
                  color="black.lighten"
                  textAlign="left"
                  mt="24px"
                >
                  Last Name
                </Text>
                <Input
                  w="100%"
                  maxW="unset"
                  type="text"
                  variant="Bordered"
                  placeholder="Last Name"
                  name="lastName"
                  value={addStaffValues.lastName}
                  onChange={addStaffChange}
                  isInvalid={
                    addStaffTouched["lastName"] && addStaffErrors["lastName"]
                  }
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text
                  w="100%"
                  as="label"
                  fontSize={{ sm: "16px", xxl: "xxl" }}
                  mb="4px"
                  color="black.lighten"
                  textAlign="left"
                  mt="24px"
                >
                  Email Id
                </Text>
                <Input
                  w="100%"
                  maxW="unset"
                  type="email"
                  variant="Bordered"
                  placeholder="Email"
                  name="email"
                  value={addStaffValues.email}
                  onChange={addStaffChange}
                  isInvalid={
                    addStaffTouched["email"] && addStaffErrors["email"]
                  }
                  disabled={
                    Object.keys(inspector.inspectors).length && editStaff
                  }
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text
                  w="100%"
                  as="label"
                  fontSize={{ sm: "16px", xxl: "xxl" }}
                  mb="4px"
                  color="black.lighten"
                  textAlign="left"
                  mt="24px"
                >
                  Password
                </Text>
                <Input
                  w="100%"
                  maxW="unset"
                  type="password"
                  variant="Bordered"
                  placeholder="Passsword"
                  name="password"
                  value={addStaffValues.password}
                  onChange={addStaffChange}
                  isInvalid={
                    addStaffTouched["password"] && addStaffErrors["password"]
                  }
                  disabled={
                    Object.keys(inspector.inspectors).length && editStaff
                  }
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text
                  w="100%"
                  as="label"
                  fontSize={{ sm: "16px", xxl: "xxl" }}
                  mb="4px"
                  color="black.lighten"
                  textAlign="left"
                  mt="24px"
                >
                  Contact No.
                </Text>
                <Input
                  w="100%"
                  maxW="unset"
                  type="number"
                  variant="Bordered"
                  placeholder="Contact No."
                  name="phone"
                  value={addStaffValues.phone}
                  onChange={addStaffChange}
                  isInvalid={
                    addStaffTouched["phone"] && addStaffErrors["phone"]
                  }
                />
              </Box>
              {auth.user?.user_Type === "super_admin" && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <Text
                    w="100%"
                    as="label"
                    fontSize={{ sm: "16px", xxl: "xxl" }}
                    mb="4px"
                    color="black.lighten"
                    textAlign="left"
                    mt="24px"
                  >
                    Designation
                  </Text>
                  <Input
                    w="100%"
                    maxW="unset"
                    type="text"
                    variant="Bordered"
                    placeholder="Designation"
                    name="designation"
                    value={addStaffValues.designation}
                    onChange={addStaffChange}
                    isInvalid={
                      addStaffTouched["designation"] &&
                      addStaffErrors["designation"]
                    }
                  />
                </Box>
              )}
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="flex-end" p="30px 38px">
            <Button
              variant="grey"
              fontWeight="normal"
              height="40px"
              mr="10px"
              fontSize="16px"
              onClick={closeAddStaff}
            >
              Cancel
            </Button>
            {editStaff ? (
              <Button
                variant="primary"
                fontWeight="normal"
                w="117px"
                height="40px"
                fontSize="16px"
                onClick={updateForm}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="primary"
                fontWeight="normal"
                w="117px"
                height="40px"
                fontSize="16px"
                onClick={() => formSubmit()}
              >
                Submit
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={deleteConfirmation}
        onClose={closeDeleteConfirmation}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="30px 30px 35px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Delete Staff
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={closeDeleteConfirmation}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="0 30px 25px">
            <Text fontSize="18px" color="black.lighten">
              Are you sure you want to delete?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary2"
              minW="120px"
              onClick={handleDeleteStaffInspector}
            >
              Yes
            </Button>
            <Button
              variant="grey"
              ml="15px"
              minW="120px"
              onClick={closeDeleteConfirmation}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={noteAdd}
        onClose={closeNote}
        size="lg"
        closeOnOverlayClick={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="25px 30px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Note
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={closeNote}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="0px 30px 30px">
            <FormControl mb="10px">
              <Textarea
                w="100%"
                variant="Bordered"
                name="note"
                value={noteValue}
                onChange={(e) => setNoteValue(e.target.value)}
              ></Textarea>
            </FormControl>
            <Button
              variant="primary2"
              mt="15px"
              float="right"
              onClick={blockStaff}
            >
              Block
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        variant="centerModal"
        isOpen={showCreateHomeInspector}
        onClose={closeCreateHomeInspector}
        size="lg"
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="25px 30px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Pay
          </ModalHeader>
          <ModalBody p="0 30px 30px">
            <Box bg="white.lighten2" mb="15px" p="20px" borderRadius="4px">
              <Grid templateColumns="repeat(2, 1fr)" rowGap={3} gap={4}>
                <Text fontWeight="600">Name:</Text>
                <Text whiteSpace="pre-wrap" wordBreak="break-word">
                  {addStaffValues.firstName + addStaffValues.lastName}
                </Text>
                <Text fontWeight="600">Email:</Text>
                <Text whiteSpace="pre-wrap" wordBreak="break-word">
                  {addStaffValues.email}
                </Text>
                <Text fontWeight="600">Phone:</Text>
                <Text whiteSpace="pre-wrap" wordBreak="break-word">
                  {addStaffValues.phone}
                </Text>
              </Grid>
            </Box>
            <RadioGroup
              defaultValue="1"
              margin="10px 0 5px"
              onChange={setInspectorSubPlan}
              value={inspectorSubPlan}
            >
              <Stack direction="row">
                <Radio variant="primarySmall" value="monthly" p="0 10px">
                  <Text display="block" fontSize="16px" color="black.lighten">
                    <Text
                      display="inline-block"
                      fontSize="48px"
                      fontWeight="600"
                      lineHeight="88px"
                    >
                      $79
                    </Text>
                    / per month
                  </Text>
                </Radio>
                <Radio variant="primarySmall" value="yearly" p="0 10px">
                  <Text display="block" fontSize="16px" color="black.lighten">
                    <Text
                      display="inline-block"
                      fontSize="48px"
                      fontWeight="600"
                      lineHeight="88px"
                    >
                      $799
                    </Text>
                    / per year
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="grey"
              minW="120px"
              onClick={closeCreateHomeInspector}
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              ml="auto"
              minW="120px"
              onClick={handleCreateHomeInspector}
            >
              Pay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
