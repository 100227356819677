import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

export interface superAdminState {
    error: any;
    loading: boolean[];
    superAdminInspectionDetails: any;
    companyAdminUsers: any;
    homeInspectorUsers: any;
    superAdminStaff: any[];
    companyDatailSuperAdmin: any;
    superAdminReports: any;
    referralDetails: any;
    superAdminRevenueDetails: any;
    companyPolicy: any;
    growthPlusUsers:any;
    proPlusUsers:any;
}

const initialState: superAdminState = {
    error: null,
    loading: [],
    superAdminInspectionDetails: null,
    companyAdminUsers: null,
    homeInspectorUsers: null,
    superAdminStaff: [],
    companyDatailSuperAdmin: null,
    superAdminReports: null,
    referralDetails: null,
    superAdminRevenueDetails: [],
    companyPolicy: null,
    growthPlusUsers:null,
    proPlusUsers: null
};

export const getAdminAccountDetail = createAsyncThunk(
    'superadmin/get/companyAdmin',
    async () => {
        const response = await api.get(`superadmin/get/companyAdmin`);
        return response.data;
    }
)
//delete admin
export const deleteAdminDetail = createAsyncThunk(
    'superadmin/delete/admin',
    async (data: any, { dispatch }) => {
        const { paramId } = data;
        const response = await api.post(`superadmin/delete/admin/${paramId}`);
        dispatch(getAdminAccountDetail());
        return response.data;
    }
);
export const deleteStaff = createAsyncThunk(
    'superadmin/deleteStaff',
    async (data: any, { dispatch }) => {
        const { paramId } = data;
        const response = await api.post(`admin/delete/homeinspector/${paramId}`);
        return response.data;
    }
);
export const getHomeInspectorAccountsDetail = createAsyncThunk(
    'superadmin/get/homeinspector',
    async () => {
        const response = await api.get(`superadmin/get/homeinspector`);
        return response.data;
    }
)
export const getProSubscriber = createAsyncThunk(
    'superadmin/get/companyAdmin/growthPlus',
    async () => {
        const response = await api.get(`superadmin/get/companyAdmin/growthPlus`);
        return response.data;
    }
)


export const getProPlusSubscriber = createAsyncThunk(
    'superadmin/get/companyAdmin/proPlus',
    async () => {
        const response = await api.get(`superadmin/get/companyAdmin/proPlus`);
        return response.data;
    }
)


export const getStaffSuperAdmin = createAsyncThunk(
    'superadmin/get/superadmin/homeinspector',
    async () => {
        const response = await api.get('superadmin/get/superadmin/homeinspector');
        return response.data;
    }
);

export const blockCompanyAdmin = createAsyncThunk(
    'superadmin/block/company/admin',
    async (data: { staffId: string, payload: object }) => {
        const { staffId, payload } = data;
        const response = await api.put(`superadmin/block/company/admin/${staffId}`, { data: payload });
        return response.data;
    }
)

//Company admin details for super admin
export const getCompanyDetailsSuperAdmin = createAsyncThunk(
    'superadmin/get/companyDetails',
    async () => {
        const response = await api.get(`superadmin/get/companyDetails`);
        return response.data;
    }
);

//Report List for super admin
export const getReportsBySuperAdmin = createAsyncThunk(
    'superadmin/get/reports',
    async (data: { paramId: string }) => {
        const { paramId } = data;
        const response = await api.get(`superadmin/get/reports/${paramId}`);
        return response.data;
    }
)

//Dashboard revenue details
export const superAdminRevenueDetails = createAsyncThunk(
    'superadmin/get/revenueDetails',
    async (data: { type: string }) => {
        const { type: detailsType } = data;
        const response = await api.post('superadmin/get/revenueDetails', { data: { type: detailsType } });
        return response.data;
    }
);
//Dashboard inspection details
export const superAdmininspectionDetailForDashboard = createAsyncThunk(
    'dashboard/superAdmininspectionDetailForDashboard',
    async (data: { type: string }) => {
        const { type: detailsType } = data;
        const response = await api.post('superadmin/get/inspectionReport', { data: { type: detailsType } });
        return response.data;
    }
);
// Dashboard referral graph
export const referralDetails = createAsyncThunk(
    'dashboard/referralDetails',
    async (data: { type: string }) => {
        const { type: detailsType } = data;
        const response = await api.post('superadmin/get/referral', { data: { type: detailsType } });
        return response.data;
    }
);
// Settings Company policy APIs
export const getCompanyPolicy = createAsyncThunk(
    'users/get/termsCondition',
    async () => {
        const response = await api.get('users/get/termsCondition');
        return response.data;
    }
);

export const createCompanyPolicy = createAsyncThunk(
    'superadmin/create/termsCondition',
    async (data: { payload: object }, { dispatch }) => {
        const { payload } = data;
        const response = await api.post('superadmin/create/termsCondition', { data: payload });
        dispatch(getCompanyPolicy());
        return response.data;
    }
);
export const updateCompanyPolicy = createAsyncThunk(
    'superadmin/update/termsCondition',
    async (data: { policyId: number, payload: object }, { dispatch }) => {
        const { payload, policyId } = data;
        const response = await api.put(`superadmin/update/termsCondition/${policyId}`, { data: payload });

        dispatch(getCompanyPolicy());
        return response.data;
    }
);
export const deletePolicy = createAsyncThunk(
    'superadmin/delete/termsCondition',
    async (data: any, { dispatch }) => {
        const { paramId } = data;
        const response = await api.post(`superadmin/delete/termsCondition/${paramId}`);
        dispatch(getCompanyPolicy());
        return response.data;
    }
);
export const createSuperAdminHomeInspector = createAsyncThunk(
    'superadmin/create/staffManagement',
    async (data: { payload: object }, { dispatch }) => {
        const { payload } = data;
        const response = await api.post('superadmin/create/staffManagement', { data: payload });
        return response.data;
    }
);
export const superAdminSlice = createSlice({
    name: 'inspector',
    initialState,
    reducers: {
        setSuperAdminError: (state, action) => {
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdminAccountDetail.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getAdminAccountDetail.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getAdminAccountDetail.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.companyAdminUsers = action.payload;
            })
            .addCase(deleteAdminDetail.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(deleteAdminDetail.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(deleteAdminDetail.fulfilled, (state) => {
                state.error = null;
                state.loading.pop();
            })
            .addCase(getHomeInspectorAccountsDetail.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getHomeInspectorAccountsDetail.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getHomeInspectorAccountsDetail.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.homeInspectorUsers = action.payload;
            })
            .addCase(deleteStaff.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(deleteStaff.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(deleteStaff.fulfilled, (state) => {
                state.error = null;
                state.loading.pop();
            })
            .addCase(getStaffSuperAdmin.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getStaffSuperAdmin.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getStaffSuperAdmin.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.superAdminStaff = action.payload;
            })
            .addCase(blockCompanyAdmin.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(blockCompanyAdmin.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(blockCompanyAdmin.fulfilled, (state) => {
                state.error = null;
                state.loading.pop();
            })
            .addCase(getCompanyDetailsSuperAdmin.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getCompanyDetailsSuperAdmin.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getCompanyDetailsSuperAdmin.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.companyDatailSuperAdmin = action.payload;
            })
            .addCase(getReportsBySuperAdmin.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getReportsBySuperAdmin.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getReportsBySuperAdmin.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.superAdminReports = action.payload;
            })
            .addCase(superAdminRevenueDetails.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(superAdminRevenueDetails.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(superAdminRevenueDetails.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.superAdminRevenueDetails = action.payload;
            })
            .addCase(superAdmininspectionDetailForDashboard.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(superAdmininspectionDetailForDashboard.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(superAdmininspectionDetailForDashboard.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.superAdminInspectionDetails = action.payload;
            })
            .addCase(referralDetails.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(referralDetails.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(referralDetails.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.referralDetails = action.payload;
            })
            .addCase(getCompanyPolicy.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getCompanyPolicy.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getCompanyPolicy.fulfilled, (state, action) => {
                state.error = null;
                state.loading.pop();
                state.companyPolicy = action.payload;
            })
            .addCase(createCompanyPolicy.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(createCompanyPolicy.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(createCompanyPolicy.fulfilled, (state) => {
                state.error = null;
                state.loading.pop();
            })
            .addCase(deletePolicy.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(deletePolicy.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(deletePolicy.fulfilled, (state) => {
                state.error = null;
                state.loading.pop();
            })
            .addCase(createSuperAdminHomeInspector.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(createSuperAdminHomeInspector.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(createSuperAdminHomeInspector.fulfilled, (state) => {
                state.error = null;
                state.loading.pop();
            })
            .addCase(updateCompanyPolicy.pending, (state) => {
                state.error = null;
                state.loading.push(true);
            })
            .addCase(updateCompanyPolicy.rejected, (state, action) => {
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(updateCompanyPolicy.fulfilled, (state) => {
                state.error = null;
                state.loading.pop();
            })
            .addCase(getProSubscriber.pending,(state,action)=>{
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getProSubscriber.rejected, (state,action)=>{
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getProSubscriber.fulfilled, (state,action)=>{
                state.error = null;
                state.loading.pop();
                state.growthPlusUsers = action.payload;
            })


            .addCase(getProPlusSubscriber.pending,(state,action)=>{
                state.error = null;
                state.loading.push(true);
            })
            .addCase(getProPlusSubscriber.rejected, (state,action)=>{
                state.loading.pop();
                state.error = action.error;
            })
            .addCase(getProPlusSubscriber.fulfilled, (state,action)=>{
                state.error = null;
                state.loading.pop();
                state.proPlusUsers = action.payload;
            })
    },

});

export const { setSuperAdminError } = superAdminSlice.actions;
export default superAdminSlice.reducer;