import { Box, Button, Checkbox, Flex, FormControl, Icon, Image, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, Divider, List, ListItem, SimpleGrid, ModalFooter } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { useEffect, useState, useRef } from 'react'
import { MdDelete, MdModeEdit, MdCheckCircle, MdAdd, MdArrowDropDown } from 'react-icons/md'
import { IoIosAddCircle, IoMdClose } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { toastr } from 'react-redux-toastr'
import AddChoiceModal from '../../../../components/common/AddChoiceModal'
import { checkUpdateTemplateSection, createCategory, createInspectionMethod, createNarrative, createSectionComponent, createTemplate, createTemplateSection, deleteCategory, deleteInspectionMethod, deleteNarrative, deleteSectionComponent, deleteTemplateSection, getCategory, getInspectionMethod, getNarratives, getSectionComponents, getTemplateById, getTemplateSection, resetTemplate, resetTemplateTabs, setActiveCategoryTab, setActiveComponentTab, setActiveNarrativeId, setActiveSectionTab, setRandomNarrativeKey, setTemplateError, TemplateState, updateCategory, updateInspectionMethod, updateNarrative, updateSectionComponent, updateTemplate, updateTemplateSection, setFutureTemplate, getAllTemplateDetails, setNarrativesList, setCategoriesList, setCloneTemplate, setPreviewTemplate, deleteReportInspectionMethod } from '../../../../redux/modules/template'
import EditChoiceModal from '../../../../components/common/EditChoiceModal'
import TextEditor from '../../../../components/common/TextEditor'
import { addChoiceModalState } from '../../../../components/common/AddChoiceModal/AddChoiceModal'
import { editChoiceModalState } from '../../../../components/common/EditChoiceModal/EditChoiceModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import { ReportState, setNarrativeImages, setNarrativesDetails, setSelectedNarratives, setSelectedInspectionMethod, deleteNarrativeImage, uploadIllustrativeImage, deleteIllustrativeImage, getIllustrativeImage, updateNarrativeImage, setEditedNarrativeImages, updateIllustrativeImage, resetSelectedNarratives, getReportDetail } from '../../../../redux/modules/report'
import { authState, setAuthError, userDetailById } from '../../../../redux/modules/auth'
import { RiUpload2Fill } from 'react-icons/ri';
import { Scrollbars } from 'react-custom-scrollbars';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import { useScript } from '../../../../utils/hooks';
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import store from 'store2'

export default function CreateTemplate() {
  useTemplateState();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const template: TemplateState = useSelector((state: RootState) => state.template);
  const report: ReportState = useSelector((state: RootState) => state.report);

  const [templateAction, setTemplateAction] = useState(null);
  const [openAddChoiceModel, setOpenAddChoiceModel] = useState(false);
  const [openEditChoiceModel, setOpenEditChoiceModel] = useState(false);
  const [addChoiceState, setAddChoiceState] = useState<addChoiceModalState>({ callback: null, dafaultValue: '', fieldHeading: null });
  const [editChoiceState, setEditChoiceState] = useState<editChoiceModalState>({ callback: null, dafaultValue: '', fieldHeading: null, keyName: null, type: null });

  // Initial value loder
  // ... render page resources
  useEffect(() => {
    // If URL edit/preview mode
    if (!templateAction && params.id) {
      if (/edit-template/ig.test(location.pathname)) {
        setTemplateAction('edit');
      } else if (/preview-template/ig.test(location.pathname)) {
        setTemplateAction('preview');
      }

      if (!/edit-report/ig.test(location.pathname)) {
        dispatch(getTemplateById({ templateId: Number(params.id) }));
        dispatch(getTemplateSection({ payload: { templateId: params.id } }));
      }
    }
    if (template.previewTemplate) {
      setTemplateAction('preview');
      dispatch(getTemplateById({ templateId: template.previewTemplate }));
      dispatch(getTemplateSection({ payload: { templateId: template.previewTemplate } }));
    }

    return () => { dispatch(setPreviewTemplate(null)); }
  }, [dispatch, location, params.id, template.previewTemplate, templateAction]);

  useEffect(() => {
    if (/edit-report/ig.test(location.pathname) && report?.reportDetails?.templatedId) {
      // setTemplateAction('edit');
      dispatch(getTemplateSection({ payload: { templateId: report.reportDetails.templatedId } }));
    }
  }, [dispatch, location.pathname, report.reportDetails.templatedId]);

  useEffect(() => {
    if (auth.user?.id) {
      dispatch(userDetailById({ userId: auth.user.id }));
    }
  }, [auth.user?.id, dispatch]);

  const addChoice = (type: string) => {
    // disable future template modal for admin and home inspector
    if ((report.reportDetails.selectedFrom === "super_admin" && auth.user?.user_Type === "company_admin") || (report.reportDetails.selectedFrom !== "home_inspector" && (auth.user?.user_Type === "home_inspector" || auth.user?.user_Type === "super_admin_home_inspector"))) {
      dispatch(setFutureTemplate(false));
    } else {
      dispatch(setFutureTemplate(true));
    }
    let callback = null;
    let dafaultValue = '';
    let fieldHeading = null;

    if (type === "template") {
      fieldHeading = 'or Edit Template';
      dafaultValue = template?.template?.template_name;

      callback = async (data: string) => {
        if (template?.template?.id || template?.cloneTemplate) {
          dispatch(
            updateTemplate({
              templateId: template.template.id || params.id,
              payload: {
                status: 'dumy',
                template_name: data,
              }
            })
          );
        }
        else if (auth.user?.user_Type === 'super_admin') {
          dispatch(
            createTemplate({
              payload: {
                user_type: auth.user.user_Type,
                status: 'dumy',
                template_name: data
              }
            })
          );
        }
        else {
          dispatch(
            createTemplate({
              payload: {
                status: 'dumy',
                template_name: data
              }
            })
          );
        }
      };
    } else if (type === "sections") {
      dispatch(setFutureTemplate(true));
      fieldHeading = 'Section';
      dafaultValue = '';

      if (!template?.template?.id) {
        toastr.warning('', "Please add Template name first");
        return;
      }

      // Call createTemplateSection api
      if (/edit-report/ig.test(location.pathname)) {
        callback = (data: string, futureTemplate: boolean) => {
          if (futureTemplate) {
            new Promise(() => {
              const data1: any = dispatch(
                createTemplateSection({
                  payload: {
                    section_name: data,
                    templateId: report.reportDetails.templateId
                  }
                })
              );
              data1.then(function (value) {
                if (value.type === 'template/createTemplateSection/fulfilled') {
                  new Promise(() => {
                    const data2: any = dispatch(
                      createTemplateSection({
                        payload: {
                          section_name: data,
                          templateId: auth.user.templateId
                        }
                      })
                    );
                    data2.then(function (values) {
                      if (values.type === 'template/createTemplateSection/fulfilled') {
                        dispatch(getTemplateSection({ payload: { templateId: report.reportDetails.templateId } }));
                        dispatch(getAllTemplateDetails({ payload: { templateId: auth.user.templateId } }));
                      }
                    })
                  })
                }
              })
            })
          }
          else {
            new Promise(() => {
              const data3: any = dispatch(
                createTemplateSection({
                  payload: {
                    section_name: data,
                    templateId: report.reportDetails.templateId
                  }
                })
              );
              data3.then(function (value) {
                if (value.type === 'template/createTemplateSection/fulfilled') {
                  dispatch(getTemplateSection({ payload: { templateId: report.reportDetails.templateId } }));
                }
              })
            })
          }
        };
      }
      else {
        callback = (data: string) => {
          new Promise(() => {
            const data4: any = dispatch(
              createTemplateSection({
                payload: {
                  section_name: data,
                  templateId: template.template.id
                }
              })
            );
            data4.then(function (value) {
              if (value.type === 'template/createTemplateSection/fulfilled') {
                dispatch(getTemplateSection({ payload: { templateId: value.payload.templateId } }));
              }
            })
          })
        };
      }

    } else if (type === "sectionComponents") {
      fieldHeading = 'Component';

      if (!template?.sections[template.activeSectionTab]?.id) {
        toastr.warning('', "Please add Section first");
        return;
      }

      const sectionTemplateId = template?.getAllTemplateDetails?.sectionDetails?.find((a: any) => a?.section_name === template?.sections[template?.activeSectionTab]?.section_name)?.id;
      if (!sectionTemplateId) {
        dispatch(setFutureTemplate(false));
      }

      // Call createSectionComponent api
      callback = (data: string, futureTemplate: boolean) => {
        if (futureTemplate && sectionTemplateId) {

          new Promise(() => {
            const data5: any = dispatch(
              createSectionComponent({
                payload: {
                  component_name: data,
                  sectionId: template.sections[template.activeSectionTab].id
                }
              })
            );
            data5.then(function (value) {
              if (value.type === 'template/createSectionComponent/fulfilled') {

                new Promise(() => {
                  const data6: any = dispatch(
                    createSectionComponent({
                      payload: {
                        component_name: data,
                        sectionId: sectionTemplateId
                      }
                    })
                  );
                  data6.then(function (value2) {
                    if (value2.type === 'template/createSectionComponent/fulfilled') {
                      dispatch(getAllTemplateDetails({ payload: { templateId: auth.user.templateId } }));
                      if (!template?.sectionComponents.length) {
                        new Promise(() => {
                          const data7: any = dispatch(checkUpdateTemplateSection({ sectionId: template.sections[template.activeSectionTab].id }));
                          data7.then(function (value3) {
                            if (value3.type === 'template/checkUpdateTemplateSection/fulfilled') {
                              dispatch(getTemplateSection({ payload: { templateId: report.reportDetails.templateId } }));
                            }
                          })
                        })
                      }
                    }
                    return value2;
                  })
                })
              }
            })
          })
        }
        else {
          new Promise(() => {
            const data8: any = dispatch(
              createSectionComponent({
                payload: {
                  component_name: data,
                  sectionId: template.sections[template.activeSectionTab].id
                }
              })
            );
            data8.then(function (value) {
              if (value.type === 'template/createSectionComponent/fulfilled') {
                if ((report.reportDetails.selectedFrom === "super_admin" && auth.user?.user_Type === "company_admin") || (report.reportDetails.selectedFrom !== "home_inspector" && (auth.user?.user_Type === "home_inspector" || auth.user?.user_Type === "super_admin_home_inspector"))) {
                  dispatch(setFutureTemplate(false));
                } else {
                  dispatch(setFutureTemplate(true));
                }
                if (!template?.sectionComponents.length) {
                  new Promise(() => {
                    const data9: any = dispatch(checkUpdateTemplateSection({ sectionId: template.sections[template.activeSectionTab].id }));
                    data9.then(function (value4) {
                      if (value4.type === 'template/checkUpdateTemplateSection/fulfilled') {
                        dispatch(getTemplateSection({ payload: { templateId: /edit-report/gi.test(location.pathname) ? report.reportDetails.templateId : template.template.id } }));
                      }
                    })
                  })
                }
              }
              return value;
            })
          })
        }
      };
      if (!template?.sectionComponents.length) {
        dispatch(checkUpdateTemplateSection({ sectionId: template.sections[template.activeSectionTab].id }));
      }
    } else if (type === "category") {
      fieldHeading = 'Category';

      if (!template?.sectionComponents[template.activeComponentTab]?.id) {
        toastr.warning('', "Please add Component first");
        return;
      }

      const componentTemplateId = template?.getAllTemplateDetails?.sectionDetails?.find((a: any) => a?.section_name === template?.sections[template?.activeSectionTab]?.section_name)?.Components?.find((a: any) => a?.component_name === template?.sectionComponents?.[template?.activeComponentTab]?.component_name)?.id;
      if (!componentTemplateId) {
        dispatch(setFutureTemplate(false));
      }

      // Call createCategory api
      callback = (data: string, futureTemplate: boolean) => {
        if (futureTemplate && componentTemplateId) {
          new Promise(() => {
            const data10: any = dispatch(
              createCategory({
                payload: {
                  category_name: data,
                  componentId: template.sectionComponents[template.activeComponentTab].id
                }
              })
            );
            data10.then(function (value) {
              if (value.type === 'template/createCategory/fulfilled') {
                dispatch(
                  createCategory({
                    payload: {
                      category_name: data,
                      componentId: componentTemplateId
                    }
                  })
                );
                dispatch(getAllTemplateDetails({ payload: { templateId: auth.user.templateId } }));
              }
            })
          })
        }
        else {
          dispatch(
            createCategory({
              payload: {
                category_name: data,
                componentId: template.sectionComponents[template.activeComponentTab].id
              }
            })
          );

          if ((report.reportDetails.selectedFrom === "super_admin" && auth.user?.user_Type === "company_admin") || (report.reportDetails.selectedFrom !== "home_inspector" && (auth.user?.user_Type === "home_inspector" || auth.user?.user_Type === "super_admin_home_inspector"))) {
            dispatch(setFutureTemplate(false));
          } else {
            dispatch(setFutureTemplate(true));
          }
        }
      };
    } else if (type === "narrative") {
      fieldHeading = 'Narrative';
      if (!template?.category[template.activeCategoryTab]?.id) {
        toastr.warning('', "Please add Category first");
        return;
      }

      const categoryId = template?.getAllTemplateDetails?.sectionDetails?.find((a: any) => a?.section_name === template?.sections[template?.activeSectionTab]?.section_name)?.Components?.find((a: any) => a?.component_name === template?.sectionComponents?.[template?.activeComponentTab]?.component_name)?.Categories?.find((a: any) => a?.category_name === template?.category?.[template?.activeCategoryTab]?.category_name)?.id;
      if (!categoryId) {
        dispatch(setFutureTemplate(false));
      }

      // Call createNarrative api
      callback = (data: string, futureTemplate: boolean) => {
        if (futureTemplate && categoryId) {
          new Promise(() => {
            const data11: any = dispatch(
              createNarrative({
                payload: {
                  narrative_name: data,
                  description: data,
                  categoryId: template.category[template.activeCategoryTab].id,
                  description_color: '#000'
                }
              })
            );
            data11.then(function (value) {
              if (value.type === 'template/createNarrative/fulfilled') {
                dispatch(
                  createNarrative({
                    payload: {
                      narrative_name: data,
                      description: data,
                      categoryId: categoryId,
                      description_color: '#000'
                    }
                  })
                );
                dispatch(getAllTemplateDetails({ payload: { templateId: auth.user.templateId } }));
              }
            })
          })
        }

        else {
          dispatch(
            createNarrative({
              payload: {
                narrative_name: data,
                description: data,
                categoryId: template.category[template.activeCategoryTab].id,
                description_color: '#000'
              }
            })
          );

          if ((report.reportDetails.selectedFrom === "super_admin" && auth.user?.user_Type === "company_admin") || (report.reportDetails.selectedFrom !== "home_inspector" && (auth.user?.user_Type === "home_inspector" || auth.user?.user_Type === "super_admin_home_inspector"))) {
            dispatch(setFutureTemplate(false));
          } else {
            dispatch(setFutureTemplate(true));
          }
        }
      };
    } else if (type === "inspectionMethod") {
      fieldHeading = 'Inspection Method';

      if (!template?.sections[template.activeSectionTab]?.id) {
        toastr.warning('', "Please add Section first");
        return;
      }

      const sectionId = template?.getAllTemplateDetails?.sectionDetails?.find((a: any) => a?.section_name === template?.sections[template?.activeSectionTab]?.section_name)?.id;
      if (!sectionId) {
        dispatch(setFutureTemplate(false));
      }

      // Call createInspectionMethod api
      callback = (data: string, futureTemplate: boolean) => {
        if (sectionId && futureTemplate) {
          new Promise(() => {
            const data12: any = dispatch(
              createInspectionMethod({
                payload: {
                  name: data,
                  templateSectionId: template?.sections[template.activeSectionTab]?.id
                }
              })
            );
            data12.then(function (value) {
              if (value.type === 'template/createInspectionMethod/fulfilled') {
                new Promise(() => {
                  const data13: any = dispatch(
                    createInspectionMethod({
                      payload: {
                        name: data,
                        templateSectionId: sectionId
                      }
                    })
                  );
                  data13.then(function (value2) {
                    if (value2.type === 'template/createInspectionMethod/fulfilled') {
                      dispatch(getAllTemplateDetails({ payload: { templateId: auth.user.templateId } }));
                      if (!template?.inspectionMethod.length) {
                        new Promise(() => {
                          const data14: any = dispatch(checkUpdateTemplateSection({ sectionId: template.sections[template.activeSectionTab].id }));
                          data14.then(function (value3) {
                            if (value3.type === 'template/checkUpdateTemplateSection/fulfilled') {
                              dispatch(getTemplateSection({ payload: { templateId: report.reportDetails.templateId } }));
                            }
                          })
                        })
                      }
                    }
                    return value2;
                  })
                })
              }
            })
          })

        }

        else {
          new Promise(() => {
            const data15: any = dispatch(
              createInspectionMethod({
                payload: {
                  name: data,
                  templateSectionId: template?.sections[template.activeSectionTab]?.id
                }
              })
            );
            data15.then(function (value) {
              if (value.type === 'template/createInspectionMethod/fulfilled') {
                if ((report.reportDetails.selectedFrom === "super_admin" && auth.user?.user_Type === "company_admin") || (report.reportDetails.selectedFrom !== "home_inspector" && (auth.user?.user_Type === "home_inspector" || auth.user?.user_Type === "super_admin_home_inspector"))) {
                  dispatch(setFutureTemplate(false));
                } else {
                  dispatch(setFutureTemplate(true));
                }
                if (!template?.inspectionMethod.length) {
                  new Promise(() => {
                    const data16: any = dispatch(checkUpdateTemplateSection({ sectionId: template.sections[template.activeSectionTab].id }));
                    data16.then(function (value1) {
                      if (value1.type === 'template/checkUpdateTemplateSection/fulfilled') {
                        dispatch(getTemplateSection({ payload: { templateId: /edit-report/gi.test(location.pathname) ? report.reportDetails.templateId : template.template.id } }));
                      }
                    })
                  })
                }
              }
              return value;
            })
          })
        }
      };

    }

    setOpenAddChoiceModel(!openAddChoiceModel); // Open Model
    setAddChoiceState({ ...addChoiceState, callback, dafaultValue, fieldHeading });
  };

  const editChoice = (type: string) => {
    let keyName = null;
    let dafaultValue = '';
    let fieldHeading = null;
    let callback = { delete: null, update: null };

    if (type === "sections") {
      keyName = "section_name";
      fieldHeading = "Sections";

      callback.delete = async (sectionId: number) => {
        if (sectionId === template.sections[template.activeSectionTab].id && template.activeSectionTab !== 0) {
          dispatch(setActiveSectionTab(0));
        }
        dispatch(deleteTemplateSection({ sectionId }));
      };
      callback.update = async (data: any) => {
        dispatch(updateTemplateSection(data));
      };
    } else if (type === "sectionComponents") {
      keyName = "component_name";
      fieldHeading = "Components";

      callback.delete = async (componentId: number) => {
        if (componentId === template.sectionComponents[template.activeComponentTab].id && template.activeComponentTab !== 0) {
          dispatch(setActiveComponentTab(0));
        }
        dispatch(deleteSectionComponent({ componentId }));
      };
      callback.update = async (data: any) => {
        dispatch(updateSectionComponent(data));
      };
    } else if (type === "category") {
      keyName = "category_name";
      fieldHeading = "Category";

      callback.delete = async (categoryId: number) => {
        if (categoryId === template.category[template.activeCategoryTab].id && template.activeCategoryTab !== 0) {
          dispatch(setActiveCategoryTab(0));
        }
        dispatch(deleteCategory({ categoryId }));
      };
      callback.update = async (data: any) => {
        dispatch(updateCategory(data));
      };
    } else if (type === "narrative") {
      keyName = "narrative_name";
      fieldHeading = "Narrative";

      callback.delete = async (narrativeId: number) => {
        dispatch(deleteNarrative({ narrativeId }));
      };
      callback.update = async (data: any) => {
        dispatch(updateNarrative(data));
      };
    } else if (type === "inspectionMethod") {
      keyName = "name";
      fieldHeading = "Inspection Method";

      callback.delete = async (inspectionMethodId: string) => {
        dispatch(deleteInspectionMethod({ inspectionMethodId }));
      };
      callback.update = async (data: any) => {
        dispatch(updateInspectionMethod(data));
      };
    }

    setOpenEditChoiceModel(!openEditChoiceModel); // Open Model
    setEditChoiceState({ ...editChoiceState, callback, fieldHeading, type, keyName, dafaultValue });
  };

  const setPreviousRoute = () => {
    dispatch(resetTemplate('all'));
    dispatch(resetTemplateTabs('all'));
    navigate(-1);
  }

  const handleUseTemplate = (useDefault: boolean) => {
    if (template.sections.length) {
      dispatch(setActiveNarrativeId(0));
      store.set("templateId", template.template.id);
      dispatch(resetTemplate('all'));
      dispatch(resetTemplateTabs('all'));
      navigate('/template');
      toastr.success('', 'Template saved successfully');
    }
    else {
      toastr.warning('', 'Add at least one section');
    }

  }

  useEffect(() => {
    if (/edit-report/gi.test(location.pathname) && report?.reportDetails?.templateId) {
      dispatch(
        getTemplateSection({
          payload: { templateId: report.reportDetails.templateId },
        })
      );
    }

  }, [dispatch, location.pathname, report.reportDetails.templateId])

  useEffect(() => {
    if (auth.error) {
      const { message } = auth.error;
      toastr.error('', message ? message : '');
    } else if (template.error) {
      const { message } = template.error;
      toastr.error('', message ? message : '');
    }

    return () => {
      dispatch(setAuthError(null));
      dispatch(setTemplateError(null));
    }
  }, [auth.error, dispatch, template.error]);

  useEffect(() => {
    if (template?.cloneTemplate) {
      addChoice('template')
    }
    dispatch(setCloneTemplate(false))
  }, [template?.cloneTemplate])

  return (
    <>
      <AddChoiceModal open={openAddChoiceModel} setClose={setOpenAddChoiceModel} states={addChoiceState} />
      <EditChoiceModal open={openEditChoiceModel} setClose={setOpenEditChoiceModel} states={editChoiceState} />
      <Box p={(!(/edit-report|add-report|template-center/ig.test(location.pathname))) ? '30px' : '0'} h={(!(/edit-report|add-report|template-center/ig.test(location.pathname))) ? 'calc(100vh - 70px)' : '100%'} overflow={!(/edit-report|add-report/ig.test(location.pathname)) ? 'auto' : 'hidden'}>
        {!(/edit-report|add-report/ig.test(location.pathname)) &&
          <Flex mt={template.previewTemplate ? '35px' : ''} alignItems='center'>
            {template.previewTemplate && <Icon as={FiArrowLeft} color='black.lighten' cursor='pointer' fontSize='26px' m='2px 12px 2px 0' onClick={setPreviousRoute}></Icon>}
            <Text fontSize='28px' fontWeight='600' color='black.lighten'>{template?.template?.template_name || 'Untitled Template'}</Text>
            {!['preview'].includes(templateAction) && <Icon cursor='pointer' color='gray.darken3' ml='14px' fontSize='24px' as={MdModeEdit} onClick={() => addChoice('template')}></Icon>}
            {auth?.user?.templateId === Number(params.id) ? <Box bg='orange.primary' m='6px 4px' p='0 8px' minW='36px' h='18px' borderRadius='5px' ml='10px'> <Text fontSize='12px' color='white.primary'>In Use</Text> </Box> : ''}
          </Flex>
        }

        <Section addChoice={addChoice} editChoice={editChoice} action={templateAction} />
        {(!['preview'].includes(templateAction) && !(/edit-report|add-report/ig.test(location.pathname))) && <Button isDisabled={!template?.template?.id} variant='primarySmall' my='25px' onClick={() => handleUseTemplate(true)}>Save</Button>}
      </Box>
    </>
  )
}

export function Section({ addChoice = null, editChoice = null, action = null }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const template: TemplateState = useSelector((state: RootState) => state.template);
  const report: ReportState = useSelector((state: RootState) => state.report);
  const auth: authState = useSelector((state: RootState) => state.auth);
  const { isOpen: viewAnnotator, onOpen: openAnnotator, onClose: closeAnnotator } = useDisclosure();
  const myRef = useRef(null);
  const [inputType, setInputType] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageIndex, setImageIndex] = useState(null);
  const [actionNarrativeId, setActionNarrativeId] = useState(null);

  const jqueryStatus = useScript('https://cdn.jsdelivr.net/npm/jquery@1.11.3/dist/jquery.min.js');
  const djaodjinStatus = useScript(jqueryStatus === 'ready' && 'https://cdn.jsdelivr.net/gh/djaodjin/djaodjin-annotate@0.0.4/djaodjin-annotate.js');

  const djaodjinOptions = {
    color: "red", 			// Color for shape and text
    type: "arrow",		// default shape: can be "rectangle", "arrow" or "text"
    images: null,			// Array of images path : ["images/image1.png", "images/image2.png"]
    linewidth: 4,			// Line width for rectangle and arrow shapes
    bootstrap: false,		// Bootstrap theme design
    selectEvent: "click",	// listened event to select image
    unselectTool: false,	// display an unselect tool for mobile
  }

  const deletenarrativeImage = (key: string, index: number) => {
    const images = report.editReport.narrativeImages[key].filter((_: any, pos: number) => pos !== index);
    dispatch(setNarrativeImages({ img: images, id: key, narrativeId: template.activeNarrativeId }));

    if (/http:\/\/|https:\/\//ig.test(report.editReport.narrativeImages[key][index]?.accessImageUrl)) {
      dispatch(deleteNarrativeImage({ imageId: report.editReport.narrativeImages[key][index].imageId }));
    }
  }

  function getBase64(file: any) {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  }

  const fileChange = async (event: any) => {
    event.preventDefault();
    setInputType(event.target.name);

    setImageName(event.target.files[0].name);
    const checkForAllFalse = report.editReport.selectedNarratives.find(x => x.checked === true);

    if (event.target.name === 'narrativesImage' && !checkForAllFalse) {
      toastr.warning('', 'Please select a narrative first');
      return;
    }

    let files: any;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }

    var narrArr: any[] = Object.entries(report?.editReport?.narrativeImages);
    if (narrArr.map((x) => x[1]?.length).reduce((a, b) => a + b, 0) + report.editReport.illustrativeImages.length > 78) {
      toastr.warning("", "Maximum image count is exceeded");
      return;
    }

    if (event.target.name === 'narrativesImage') {
      if (files.length === 1) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = () => {
          handleImageAnotator(reader.result);
          event.target.value = ""; // Empty input value. So same file can be select again
        };
      }
      else if (event.target.name === "narrativesImage" && files.length > 1) {
        const key = template.randomNarrativeKey.find((r: any) => r.id === template.activeNarrativeId).key;
        const images = await Promise.all(Array.from(files).map(async (file: any) => {
          return { accessImageUrl: await getBase64(file), image_name: file.name, imageId: null }
        }));
        dispatch(setNarrativeImages({ img: [...report.editReport.narrativeImages[key], ...images], id: key, narrativeId: template.activeNarrativeId }));
        event.target.value = ""; // Empty input value. So same file can be select again
      }
    }
    else if (event.target.name === 'IllustrativeImage') {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = () => {
        handleImageAnotator(reader.result);
        event.target.value = ""; // Empty input value. So same file can be select again
      };
    }
  };

  const saveAnnotatorImage = () => {
    const div: any = document.querySelector('#canvas-annotate');
    const canvas: any = div.querySelector('canvas');
    const image = canvas.toDataURL();

    if (inputType === 'narrativesImage') {
      const key = template.randomNarrativeKey.find((r: any) => r.id === template.activeNarrativeId).key;
      if (report?.editReport?.narrativeImages[key]?.length > 5) {
        toastr.warning("", "Cannot add more than six images in one narrative list");
        return;
      }
      var narrArr: any[] = Object.entries(report?.editReport?.narrativeImages);
      if (narrArr.map((x) => x[1]?.length).reduce((a, b) => a + b, 0) + report.editReport.illustrativeImages.length > 78) {
        toastr.warning("", "Maximum image count is exceeded");
        return;
      }

      if (report.editReport.narrativeImages[key]) {
        dispatch(setNarrativeImages({ img: [...report.editReport.narrativeImages[key], { accessImageUrl: image, image_name: imageName, imageId: null }], id: key, narrativeId: template.activeNarrativeId }));
      }
      else {
        dispatch(setNarrativeImages({ img: [{ accessImageUrl: image, image_name: imageName, imageId: null }], id: key, narrativeId: template.activeNarrativeId }));
      }
    }
    else if (inputType === 'IllustrativeImage') {
      dispatch(uploadIllustrativeImage({ sectionId: template.sections[template.activeSectionTab].id, fileData: image }));
    }
    // eslint-disable-next-line no-eval
    eval(`$("#canvas-annotate").annotate("destroy")`);
    closeAnnotator(); // Close Modal
  };

  const closeAnnotatorModal = () => {
    // eslint-disable-next-line no-eval
    eval(`$("#canvas-annotate").annotate("destroy")`);
    closeAnnotator(); // Close Modal
  }

  const handleImageAnotator = async (src: any) => {
    if (typeof window === 'object' && djaodjinStatus === 'ready') {
      djaodjinOptions.images = [src];
      openAnnotator(); // Open Modal

      // eslint-disable-next-line no-eval
      setTimeout(() => eval(`$(document).ready(function() {
        $("#canvas-annotate").annotate(djaodjinOptions);
        $("#undoaction").addClass("annotate-undo");
        $("#redoaction").addClass("annotate-redo");
        $("#undoaction").parent().addClass("annotator-options");
        $(".annotator-options input[type=radio]").hide(); // hide buttons
        $(".annotator-options").html($(".annotator-options").html().replace(/TEXT|RECTANGLE|CIRCLE|ARROW|PEN/gi, ""));
      })`), 100);
    }
  };

  const handleEditNarrativeName = () => {
    const originalArr = JSON.parse(JSON.stringify(report.editReport.narrativeImages));
    const key = template?.randomNarrativeKey.find((r: any) => r.id === actionNarrativeId)?.key;
    const editImageName = originalArr[key].filter((_: any, pos: number) => pos === imageIndex);
    const imgExtStr = editImageName[0]?.image_name?.split(".") || editImageName[0]?.imageName?.split(".");
    const imgExt = imgExtStr[imgExtStr.length - 1]
    editImageName[0].image_name = `${narrativeImageName}.${imgExt}`;
    originalArr[key].splice(imageIndex, editImageName[0]);
    dispatch(setEditedNarrativeImages(originalArr));

    if (/http:\/\/|https:\/\//ig.test(report.editReport.narrativeImages[key][imageIndex].accessImageUrl)) {
      dispatch(updateNarrativeImage({ id: report.editReport.narrativeImages[key][imageIndex].imageId, name: `${narrativeImageName}.${imgExt}` }));
    }

    closeEditNarrativeName();
  };

  const handleEditIllustrativeName = () => {
    const imgExtStr = illustrativeImageName.split(".");
    const imgExt = imgExtStr[imgExtStr.length - 1];
    dispatch(updateIllustrativeImage({ id: imageIndex, reportId: report.editReport.report.id, name: `${narrativeImageName}.${imgExt}` }));

    closeEditIllustrativeName();
  };

  const handleOpenEditNarrativeName = (x: number, name: string, narrativeId: any) => {
    setActionNarrativeId(narrativeId);
    setNarrativeImageName(name);
    setImageIndex(x);
    openEditNarrativeName();
  }

  const handleOpenEditIllustrativeName = (id: number, name: string) => {
    setNarrativeImageName(name);
    setIllustrativeImageName(name);
    setImageIndex(id);
    openEditIllustrativeName();
  }

  const handleInspectionMethod = (e, x: any) => {
    if (!e.target.checked && report.reportDetails.reportInspectionMethods.find(y => y.inspectionMethodId === x.id)) {
      new Promise(() => {
        const dataInspectionMethod: any = dispatch(deleteReportInspectionMethod({ inspectionMethodId: x.id }))
        dataInspectionMethod.then(function (value) {
          if (value.type === 'template/deleteReportInspectionMethod/fulfilled') {
            dispatch(setSelectedInspectionMethod([]));
            new Promise(() => {
              const data2: any = dispatch(getReportDetail({ paramId: report.editReport.report.id }));
              data2.then(function (value) {
                if (value.type === "report/getReportDetail/fulfilled") {
                  value.payload.data.reportInspectionMethods.forEach((x: any) => {
                    dispatch(
                      setSelectedInspectionMethod({
                        checked: true,
                        editMode: true,
                        reportInspectionId: x.id,
                        id: x.inspectionMethodId,
                      })
                    )
                  });
                }
              })
            });
          }
        })
      })
    }
    else {
      dispatch(setSelectedInspectionMethod({ id: x.id, checked: e.target.checked }))
    }
  }

  const { isOpen: isOpenCropper, onOpen: onOpenCropper, onClose: onCloseCropper } = useDisclosure();
  const [cropImage, setCropImage] = useState(null);
  const [narrativeImageName, setNarrativeImageName] = useState('');
  const [illustrativeImageName, setIllustrativeImageName] = useState('');
  const [croppedImage, setCroppedImage] = useState<any>();
  const [currentFileName, setCurrentFileName] = useState(null);
  const { isOpen: editNarrativeName, onOpen: openEditNarrativeName, onClose: closeEditNarrativeName } = useDisclosure();
  const { isOpen: editIllustrativeName, onOpen: openEditIllustrativeName, onClose: closeEditIllustrativeName } = useDisclosure();

  const individualFileChange = (event: any) => {
    event.preventDefault();
    let files: any;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
      onOpenCropper();
    } else if (event.target) {
      files = event.target.files;
      onOpenCropper();
    }

    const reader = new FileReader();
    reader.onload = () => {
      setCropImage(reader.result as any);
      event.target.value = ""; // Empty input value. So same file can be select again
    };

    reader.readAsDataURL(files[0]);
    setCurrentFileName(files[0].name);
  };

  const getCropData = async () => {
    var narrArr1: any[] = Object.entries(report?.editReport?.narrativeImages);
    if (narrArr1.map((x) => x[1]?.length).reduce((a, b) => a + b, 0) + report.editReport.illustrativeImages.length > 78) {
      toastr.warning("", "Maximum image count is exceeded");
      return;
    }
    if (typeof croppedImage !== 'undefined' && report.editReport.illustrativeImages?.filter(x => x.templateSectionId === template?.sections[template.activeSectionTab]?.id).length < 1) {
      const dataURLtoFile = (dataurl: string, filename: string) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n) {
          u8arr[n - 1] = bstr.charCodeAt(n - 1);
          n -= 1; // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime });
      };

      // put file into form data
      const data = new FormData();
      data.append(
        'image',
        dataURLtoFile(
          croppedImage.getCroppedCanvas().toDataURL(),
          currentFileName
        )
      );

      dispatch(uploadIllustrativeImage({ sectionId: template.sections[template.activeSectionTab].id, fileData: data }));
    }
    else {
      toastr.warning("", " Cannot add more than one illustrative image in a particular section");
    }
    onCloseCropper();
  };

  useEffect(() => {
    if (/edit-report|add-report/gi.test(location.pathname) && report.editReport?.report?.id && template.sections[template.activeSectionTab]?.id) {
      dispatch(getIllustrativeImage({ reportId: report.editReport.report.id }));
    }
  }, [dispatch, location.pathname, report.editReport.report.id, template.activeSectionTab, template.sections]);

  useEffect(() => {
    if (/edit-report/gi.test(location.pathname)) {
      myRef.current.scrollIntoView();
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setActiveSectionTab(0))
  }, [])

  return (
    <>
      <Box mt='25px' ref={myRef}>
        {(/edit-report|add-report/gi.test(location.pathname)) && <Flex justifyContent='space-between' bg='gray.darken3' borderRadius='10px' p='10px 21px' alignItems='center' mt='17px' mb='25px'>
          <Flex>
            <Text fontSize='18px' color='white.primary'>Report id - {report.editReport?.inspectorDetail?.reportId || ''}</Text>
            <Divider orientation='vertical' color='white.primary' height='17px' mx='10px' />
            <Text fontSize='18px' color='white.primary'>Client Name - {report.editReport?.clientInformation?.firstName || ''} {report.editReport?.clientInformation?.lastName || ''}</Text>
          </Flex>
          <Text fontSize='18px' w='50%' textAlign='right' className='template-name' color='white.primary'>Template Name - {template?.template?.template_name || "Untitled Template"}</Text>
        </Flex>}
        <Tabs index={template.activeSectionTab} onChange={(index) => dispatch(setActiveSectionTab(index))}>
          <Box bg='white.primary' p='15px 15px 0 15px'>
            <Flex>
              <Box d='inline-block'>
                <Text fontSize='24' color='black.lighten'>Sections</Text>
              </Box>
              <Box pl='10px' position='relative' ml='auto'>
                {!['preview'].includes(action) ? <>
                  {!!template?.sections?.length && <Button variant='transparent' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' d='inline-block' maxW='250px' color='gray.primary' h='28px' fontSize='xl' onClick={() => editChoice && editChoice('sections')}>
                    <Icon fontSize='25px' color='gray.primary' mr='12px' as={MdModeEdit}></Icon>
                  </Button>}
                  <Button variant='transparent' color='gray.primary' h='28px' ml='5px' fontSize='xl' onClick={() => {
                    addChoice && addChoice('sections');
                    if ((report.reportDetails.selectedFrom === "super_admin" && auth.user?.user_Type === "company_admin") || (report.reportDetails.selectedFrom !== "home_inspector" && (auth.user?.user_Type === "home_inspector" || auth.user?.user_Type === "super_admin_home_inspector"))) {
                      dispatch(setFutureTemplate(false));
                    } else {
                      dispatch(setFutureTemplate(true));
                    }
                  }}>
                    <Icon fontSize='25px' color='gray.primary' mr='12px' as={IoIosAddCircle}></Icon>
                  </Button>
                </> : <Button variant='transparent' color='gray.primary' h='28px' fontSize='xl' />}
              </Box>
            </Flex>
            <Box h='65px'>
              <Scrollbars overflowX='auto' overflowY='hidden'>
                <TabList borderColor='none' borderBottom='1px solid rgba(252, 180, 46, 0.31)' mt='13px' css={css({
                  '::-webkit-scrollbar-track': {
                    boxShadow: 'none',
                    border: 'none',
                    borderRadius: '10px',
                    background: '#E0E0E0'
                  },
                  '::-webkit-scrollbar-thumb': {
                    background: '#C5C7D0',
                    borderRadius: '10px'
                  },
                  '::-webkit-scrollbar': {
                    width: '7px'
                  }
                })}>
                  {template?.sections?.map((sec: any) => {
                    return (
                      <Tab key={sec?.id} whiteSpace='nowrap' className={`template-tab-border ${sec?.id === template?.sections[template.activeSectionTab]?.id ? 'border-active' : ''}`} fontSize='18px' p='0 45px 15px 45px' color='black.lighten' _selected={{ color: 'orange.light', _focus: { outline: 'none', boxShadow: 'none' }, borderBottom: 'none', borderColor: 'orange.light' }} ><Icon color={(sec?.id !== template?.sections[template.activeSectionTab]?.id && sec?.check === true && 'blue.light3') || (sec?.id !== template?.sections[template.activeSectionTab]?.id && sec?.check === false && 'gray.light3') || (sec?.id !== template?.sections[template.activeSectionTab]?.id && 'gray.light3')} mr='5px' as={MdCheckCircle}></Icon>{sec?.section_name}</Tab>
                    )
                  })}
                </TabList>
              </Scrollbars>
            </Box>
          </Box>
          <Flex mt='14px'>
            <Box borderRadius='10px' p={{ sm: '12px', md: '20px' }} bg='white.primary' w={{ sm: 'calc(100% - 283px)', xl: 'calc(100% - 355px)' }}>
              <TabPanels>
                {!template?.sections?.length &&
                  (<TabPanel p='0'>
                    <Component addChoice={addChoice} editChoice={editChoice} action={action} />
                  </TabPanel>)
                }
                {template?.sections?.map((section: any) => {
                  return <TabPanel p='0' key={section.id}>
                    <Component addChoice={addChoice} editChoice={editChoice} action={action} />
                  </TabPanel>
                })}
              </TabPanels>
              <Narrative addChoice={addChoice} editChoice={editChoice} action={action} />
            </Box>

            <Box ml='auto' p={{ sm: '12px', md: '20px' }} bg='white.primary' w={{ sm: '270px', xl: '342px' }} borderRadius='10px'>
              <Box border='1px solid #DDDDDD' borderRadius='10px' mb='22px'>
                <Flex justifyContent='space-between' alignItems='center' bg='orange.light' borderRadius='10px 10px 0px 0px' p='4px 15px' h='48px'>
                  <Text color='white.primary' fontWeight='600' lineHeight='1' fontSize={{ sm: 'xxxl', xxxl: 'xxxxl' }}>Inspection Method</Text>
                  {!['preview'].includes(action) && <Box display='inline-flex'>
                    {!!template?.inspectionMethod?.length && <Button color='white.primary' fontSize='20px' fontWeight='600' display='flex' alignItems='center' justifyContent='flex-end' variant='transparent' onClick={() => editChoice && editChoice('inspectionMethod')}>
                      <Icon fontSize='18px' color='white.primary' mb='3px' as={MdModeEdit}></Icon>
                    </Button>}
                    {!!template?.sections?.length && <Button color='white.primary' fontSize='20px' fontWeight='600' d='flex' alignItems='center' justifyContent='flex-end' variant='transparent' onClick={() => addChoice && addChoice('inspectionMethod')}>
                      <Icon color='orange.light' bg='white.primary' w='16px' h='16px' borderRadius='50%' mr='8px' as={MdAdd}></Icon>
                    </Button>}
                  </Box>}
                </Flex>
                <Box overflowY='auto' h='350px' p='10px 0'>
                  <Scrollbars renderThumbVertical={props => <div {...props} className="thumb-vertical" />} style={{ height: '100%' }} overflow='auto'>
                    <Box d='flex' flexWrap='wrap' justifyContent='flex-start' alignContent='flex-start' px='18px' w='100%' h='100%'>
                      {template.inspectionMethod.map((x: any, id: number) => {
                        return <Box d='inline-flex' key={id} justifyContent='flex-start' textAlign='left' w='100%' p='15px' mt='10px' wordBreak='break-word' whiteSpace='normal' mx='auto' bg='white.lighten7'>
                          <Checkbox className="inspection-checkbox" key={x.id} variant='transparent' colorScheme='transparent' w='100%' isChecked={report.editReport.selectedInspectionMethod.find(y => y.id === x.id)?.checked} onChange={(e) => handleInspectionMethod(e, x)}>
                            {x.name}
                          </Checkbox>
                        </Box>
                      })}
                    </Box>
                  </Scrollbars>
                </Box>
              </Box>
              {/edit-report|add-report/gi.test(location.pathname) &&
                <Box>
                  <Box borderRadius='10px'>
                    <Box justifyContent='space-between' alignItems='center' bg='orange.light' borderRadius='10px 10px 0px 0px' p='7.5px 15px'>
                      <Text color='white.primary' fontWeight='600' fontSize={{ sm: 'xxxl', xxl: 'xxxxl' }}>Narrative Photos</Text>
                    </Box>
                    <Box h='350px'>
                      <Box pos='relative' mt='17px' border='1px dashed' borderColor='gray.light3' d='flex' justifyContent='center' alignItems='center' flexDirection='column' h='auto' minH='44px'>
                        <label style={{ width: '100%', height: '100%' }}>
                          <Box cursor='pointer' d='flex' h='100%' justifyContent='center' flexDirection='column' alignItems='center'>
                            <Box bg='transparent' color='black.lighten' d='inline-flex' alignItems='center' fontSize='14px' height='32px' p='8px 12px'><Icon as={RiUpload2Fill} color='black.lighten' mr='7px' fontSize='17px'></Icon> Upload Images</Box>
                            <Input pos='absolute' top='0' left='0' right='0' bottom='0' id='upload-image' accept='.jpeg, .jpg, .png' type='file' onChange={(e) => {
                              fileChange(e);
                            }} name='narrativesImage' visibility='hidden' border='none' outline='none' p='0' _focus={{ outline: "none" }} multiple={true} />
                          </Box>
                        </label>
                      </Box>
                      {template.narrative?.filter(x => x.ComponentCategoryId === template.category[template.activeCategoryTab]?.id).find(x => report.editReport?.selectedNarratives.find(y => y?.id === x?.id && y.checked === true)) && report?.editReport?.narrativeImages &&
                        <List p='20px 10px' overflowY='auto' h='302px'>
                          {report?.editReport?.selectedNarratives?.map(x => { return template.randomNarrativeKey?.filter(y => { return x.id === y.id && x.checked === true && x.categoryId === template.category[template.activeCategoryTab].id }) })?.map(z => { return report.editReport.narrativeImages?.[z[0]?.['key']] })?.map(y => y?.map((x: any, index: number) => {
                            return <ListItem pb='20px' fontSize='18px' d='flex' alignItems='center' justifyContent='space-between' key={index + Math.random()}>
                              <Flex alignItems='center'>
                                <Box bg='white.lighten7' borderRadius='full' overflow='hidden' boxSize='40px'>
                                  <Image h='100%' alignItems='center' src={x?.accessImageUrl || x?.imageUrl} />
                                </Box>
                                <Box ml='15px' className='illustrative-photos-text' w={{ sm: '80px', xxl: "150px" }}>
                                  <Text fontSize='14px' color='black.lighten'>{x?.image_name || x?.imageName}</Text>
                                </Box>
                              </Flex>
                              <Flex alignItems='center'>
                                <Button variant='iconBtn' bg='blue.primary' zIndex='1' minW='unset' p='0' borderRadius='50%' h='21px' w='21px' onClick={() => handleOpenEditNarrativeName(index, x?.image_name, x?.narrativeId)}>
                                  <Icon fontSize='16px' color='white.primary' as={MdModeEdit}></Icon>
                                </Button>
                                <Button variant='iconBtn' bg='blue.primary' zIndex='1' minW='unset' p='0' borderRadius='50%' h='21px' w='21px' ml='15px' onClick={() => deletenarrativeImage(template?.randomNarrativeKey.find((r: any) => r.id === x?.narrativeId)?.key, index)}>
                                  <Icon fontSize='16px' color='white.primary' as={MdDelete}></Icon>
                                </Button>
                              </Flex>
                            </ListItem>
                          }))}
                        </List>
                      }
                    </Box>
                  </Box>
                  <Box borderRadius='10px' mt='20px'>
                    <Box justifyContent='space-between' alignItems='center' bg='orange.light' borderRadius='10px 10px 0px 0px' p='7.5px 15px'>
                      <Text color='white.primary' fontWeight='600' fontSize={{ sm: 'xxxl', xxl: 'xxxxl' }}>Illustrative Photos</Text>
                    </Box>
                    <Box h='350px'>
                      <FormControl mt='17px' border='1px dashed' borderColor='gray.light3' d='inline-flex' justifyContent='center' alignItems='center' flexDirection='column' h='auto' minH='44px'>
                        <label>
                          <Box cursor='pointer' d='flex' h='100%' justifyContent='center' flexDirection='column' alignItems='center'>
                            <Box bg='transparent' color='black.lighten' d='inline-flex' alignItems='center' fontSize='14px' height='32px' p='8px 12px'><Icon as={RiUpload2Fill} color='black.lighten' mr='7px' fontSize='17px'></Icon> Upload Images</Box>
                            <Input id='illustrative-upload-image' accept='.jpeg, .jpg, .png' type='file' onChange={individualFileChange} name='IllustrativeImage' w='0' h='0' border='none' outline='none' p='0' _focus={{ outline: "none" }} />
                          </Box>
                        </label>
                      </FormControl>
                      {report?.editReport?.illustrativeImages &&
                        <List p='20px 10px'>
                          {report?.editReport?.illustrativeImages?.filter(x => x.templateSectionId === template?.sections[template.activeSectionTab]?.id)?.map((x: any, index: number) => {
                            return <ListItem pb='20px' fontSize='18px' d='flex' alignItems='center' justifyContent='space-between' key={index + Math.random()}><>
                              <Flex alignItems='center'>
                                <Box bg='white.lighten7' borderRadius='full' overflow='hidden' boxSize='40px'>
                                  <Image alignItems='center' borderRadius='full' boxSize='40px' src={x?.accessImageUrl} />
                                </Box>
                                <Box ml='15px' className='illustrative-photos-text' w={{ sm: '80px', xxl: "150px" }}>
                                  <Text fontSize='14px' color='black.lighten'>{x?.image_name}</Text>
                                </Box>
                              </Flex>
                              <Flex alignItems='center'>
                                <Button variant='iconBtn' bg='blue.primary' zIndex='1' minW='unset' p='0' borderRadius='50%' h='21px' w='21px' onClick={() => handleOpenEditIllustrativeName(x?.id, x?.image_name)}>
                                  <Icon fontSize='16px' color='white.primary' as={MdModeEdit}></Icon>
                                </Button>
                                <Button variant='iconBtn' bg='blue.primary' zIndex='1' minW='unset' p='0' borderRadius='50%' h='21px' w='21px' ml='15px' onClick={() => dispatch(deleteIllustrativeImage(x?.id))}>
                                  <Icon fontSize='16px' color='white.primary' as={MdDelete}></Icon>
                                </Button>
                              </Flex>
                            </>
                            </ListItem>
                          })}
                        </List>
                      }
                    </Box>
                  </Box>
                </Box>
              }
            </Box>
          </Flex>

        </Tabs>
      </Box>

      <Modal isOpen={viewAnnotator} onClose={closeAnnotatorModal} size='6xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='25px 30px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Annotate Image</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeAnnotatorModal} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='20px 30px 30px'>
            <Box w='100%' maxH='58vh' overflow='auto'>
              <div id="canvas-annotate" style={{ border: '2px solid red' }}></div>
            </Box>
            <Button variant='primary2' mt='15px' float='right' onClick={saveAnnotatorImage}>Save</Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={editNarrativeName} onClose={closeEditNarrativeName} size='6xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='25px 30px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Edit Narrative image name</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeEditNarrativeName} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='20px 30px 30px'>
            <Box w='100%' maxH='58vh' overflow='auto'>
              <Input type='text' maxW='unset' variant='Bordered' height='50px' fontSize='xl' value={narrativeImageName} onChange={(e) => setNarrativeImageName(e.target.value)} />
            </Box>
            <Button variant='primary2' mt='15px' float='right' onClick={handleEditNarrativeName}>Save</Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={editIllustrativeName} onClose={closeEditIllustrativeName} size='6xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='25px 30px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Edit Illustrative image name</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeEditIllustrativeName} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='20px 30px 30px'>
            <Box w='100%' maxH='58vh' overflow='auto'>
              <Input type='text' maxW='unset' variant='Bordered' height='50px' fontSize='xl' value={narrativeImageName} onChange={(e) => setNarrativeImageName(e.target.value)} />
            </Box>
            <Button variant='primary2' mt='15px' float='right' onClick={handleEditIllustrativeName}>Save</Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenCropper} onClose={onCloseCropper} size={'5xl'} scrollBehavior='inside' closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='25px 28px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Crop</ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={onCloseCropper} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 28px 25px'>
            <Box>
              <Box style={{ width: "100%" }}>
                <Cropper
                  className='img-cropper'
                  zoomTo={0}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={cropImage}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCroppedImage(instance);
                  }}
                  guides={true}
                />
              </Box>
              <Box m='20px 0' overflow='hidden'>
                <Box w='auto'>
                  <Box
                    className="img-preview"
                    bg='gray.light3'
                    borderRadius='4px'
                  />
                </Box>
              </Box>
              <Button variant='primary2' onClick={getCropData}>
                Save
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export function Component({ addChoice = null, editChoice = null, action = null }) {
  const dispatch = useDispatch();
  const template: TemplateState = useSelector((state: RootState) => state.template);

  return (
    <>
      <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={5}>
        <Tabs onChange={(index) => dispatch(setActiveComponentTab(index))} index={template.activeComponentTab} border='1px solid #DDDDDD' borderRadius='10px'>
          <TabList w='100%'>
            <Box w='100%'>
              <Flex justifyContent='space-between' alignItems='center' bg='orange.light' borderRadius='10px 10px 0px 0px' p='4px 15px'>
                <Text color='white.primary' fontWeight='600' fontSize={{ sm: 'xxxl', xxl: 'xxxxl' }}>Components</Text>
                {!['preview'].includes(action) ? <Flex position='relative' ml='auto'>
                  {!!template?.sectionComponents?.length && <Button color='white.primary' fontSize='18px' fontWeight='600' d='flex' alignItems='center' variant='transparent' onClick={() => editChoice && editChoice('sectionComponents')}>
                    <Icon cursor='pointer' color='white.primary' w='18px' h='18px' borderRadius='50%' mb='3px' mr='8px' as={MdModeEdit}></Icon>
                  </Button>}
                  <Button onClick={() => addChoice && addChoice('sectionComponents')} color='white.primary' fontSize='18px' fontWeight='600' d='flex' alignItems='center' variant='transparent'>
                    <Icon cursor='pointer' color='orange.light' bg='white.primary' w='16px' h='16px' borderRadius='50%' mr='8px' as={MdAdd}></Icon>
                  </Button>
                </Flex> : <Button variant='transparent' color='gray.primary' h='28px' fontSize='xl' />}
              </Flex>
            </Box>
          </TabList>
          <Box overflowY='auto' h='350px' p='10px 0'>
            <Scrollbars overflowY='auto' overflowX='hidden' pr='100px'>
              <TabList d='flex' flexWrap='wrap' overflow='hidden' w='100%' px='18px' mb='10px' whiteSpace='nowrap' border='none' css={css({
                '::-webkit-scrollbar-track': {
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: '10px',
                  background: '#E0E0E0'
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#C5C7D0',
                  borderRadius: '10px'
                },
                '::-webkit-scrollbar': {
                  width: '7px'
                }
              })}>
                {template?.sectionComponents?.map((x: any, index: number) => {
                  return <Tab key={x.id} color='white.primary' d='flex' alignItems='center' wordBreak='break-word' whiteSpace='normal' mt='10px' minH='54px' border='none' _selected={{ boxShadow: 'none', fontWeight: '500', bg: 'white.lighten7' }} mx='auto' w='100%' bg='white.lighten7' p='15px' fontSize='18px' justifyContent='flex-start' textAlign='left'>
                    <Checkbox variant='transparent' colorScheme='transparent' mr='17px' isChecked={template.activeComponentTab === index} />
                    <Text color='black'>{x.component_name}</Text>
                    {template?.categoriesList?.find(y => x.id === Number(y)) && <Box pl='5px' ml='auto' d='inline-flex'><Icon fontSize='20px' color='blue.light3' mr='5px' as={MdCheckCircle}></Icon></Box>}
                  </Tab>
                })}
              </TabList>
            </Scrollbars>
          </Box>
        </Tabs>
        <Category addChoice={addChoice} editChoice={editChoice} action={action} />
      </SimpleGrid>
    </>
  )
}

export function Category({ addChoice = null, editChoice = null, action = null }) {
  const dispatch = useDispatch();
  const template: TemplateState = useSelector((state: RootState) => state.template);

  return (
    <>
      <Tabs onChange={(index) => { dispatch(setActiveCategoryTab(index)); dispatch(setActiveNarrativeId('')); }} index={template.activeCategoryTab} border='1px solid #DDDDDD' borderRadius='10px'>
        <Flex justifyContent='space-between' alignItems='center' bg='orange.light' borderRadius='10px 10px 0px 0px' h='48px' p='4px 15px'>
          <Text color='white.primary' fontWeight='600' fontSize={{ sm: 'xxxl', xxl: 'xxxxl' }}>Categories</Text>
          {!['preview'].includes(action) ? <Box d='inline-flex'>
            {!!template?.category?.length && <Button color='white.primary' fontSize='18px' fontWeight='600' d='flex' alignItems='center' variant='transparent' onClick={() => editChoice && editChoice('category')}><Icon color='white.primary' w='18px' h='18px' borderRadius='50%' mb='3px' mr='8px' as={MdModeEdit}></Icon></Button>}
            <Button color='white.primary' fontSize='18px' fontWeight='600' d='flex' alignItems='center' variant='transparent' onClick={() => addChoice && addChoice('category')}><Icon color='orange.light' bg='white.primary' w='16px' h='16px' borderRadius='50%' mr='8px' as={MdAdd}></Icon></Button>
          </Box>
            : ''}
        </Flex>
        <Box overflowY='auto' h='350px' p='10px 0'>
          <Scrollbars overflowY='auto' overflowX='hidden' pr='100px'>
            <TabList d='flex' flexWrap='wrap' overflow='hidden' w='100%' mb='10px' px='18px' whiteSpace='nowrap' border='none' css={css({
              '::-webkit-scrollbar-track': {
                boxShadow: 'none',
                border: 'none',
                borderRadius: '10px',
                background: '#E0E0E0'
              },
              '::-webkit-scrollbar-thumb': {
                background: '#C5C7D0',
                borderRadius: '10px'
              },
              '::-webkit-scrollbar': {
                width: '7px'
              }
            })}>
              {!!template?.sectionComponents?.length && template?.category?.map((x: any, index: number) => {
                return <Tab key={x.id} w='100%' mt='10px' wordBreak='break-word' textAlign='left' whiteSpace='normal' minH='54px' mx='auto' color='black.lighten' overflow='hidden' textOverflow='ellipsis' d='flex' justifyContent='flex-start' border='none' _selected={{ boxShadow: 'none', color: 'blue.dark', border: 'none' }} bg='white.lighten7' p='15px' fontSize='18px' alignItems='center'>
                  <Checkbox variant='transparent' colorScheme='transparent' mr='17px' isChecked={template.activeCategoryTab === index} />
                  <Text fontSize='18px' color='black'>{x.category_name}</Text>
                  {template?.narrativesList?.find(y => x.id === Number(y)) && <Box pl='5px' ml='auto' d='inline-flex'><Icon fontSize='20px' color='blue.light3' mr='5px' as={MdCheckCircle}></Icon></Box>}
                </Tab>
              })}
            </TabList>
          </Scrollbars>
        </Box>
      </Tabs>
    </>
  )
}

export function Narrative({ addChoice = null, editChoice = null, action = null }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const report: ReportState = useSelector((state: RootState) => state.report);
  const template: TemplateState = useSelector((state: RootState) => state.template);

  const [noteDesc, setNoteDesc] = useState('');
  const [narrDescName, setNarrDescName] = useState('');
  const [narrativeDesc, setNarrativeDesc] = useState('');

  const [showUpdateButton, setShowUpdateButton] = useState(false);
  // const { isOpen: disclaimer, onOpen: openDisclaimer, onClose: closeDisclaimer } = useDisclosure();
  const [optionval] = useState([{ name: 'Compliant', color: '#000' }, { name: 'Informational', color: '#00CA72' }, { name: 'Need Service', color: '#FFDE1F' }, { name: 'Safety Hazard', color: '#D64000' }]);
  const [selectval, setselectval] = useState('Compliant');
  const [selectcolor, setselectcolor] = useState('black');

  const [valueChange, setvalueChange] = useState(false);
  const [array, setArray] = useState([]);
  const [narrativeActiveTab, setNarrativeActiveTab] = useState(0);

  const { isOpen: saveTemplate, onOpen: openSaveTemplate, onClose: closeSaveTemplate } = useDisclosure();

  const showDropdown = () => {
    setvalueChange(!valueChange);
  };

  const editorNarrativeUpdated = (_content: any, _delta: any, _source: any, editor: any) => {
    const html = editor.getHTML();
    setNarrativeDesc(html);
    if (!showUpdateButton) {
      setShowUpdateButton(!showUpdateButton);
    }
  }

  const editorNoteUpdated = (_content: any, _delta: any, _source: any, editor: any) => {
    const html = editor.getHTML();
    setNoteDesc(html);

    if (!showUpdateButton) {
      setShowUpdateButton(!showUpdateButton);
    }
  }

  useEffect(() => {
    if (template.sections[template.activeSectionTab]?.id || template.activeComponentTab || template.activeCategoryTab) {
      setNarrativeDesc('');
    }
  }, [template.activeComponentTab, template.activeCategoryTab, template.sections, template.activeSectionTab])

  useEffect(() => {
    if (template.activeNarrativeId) {
      const narrative = template.narrative.find((x: any) => x.id === template.activeNarrativeId);

      if (/edit-report|add-report/gi.test(location.pathname)) {
        setNoteDesc(report?.editReport?.narrativeDetails[template.activeNarrativeId]?.note || '');
        setNarrativeDesc(report?.editReport?.narrativeDetails[template.activeNarrativeId]?.description || narrative?.description);
      } else if (!/edit-report|add-report/gi.test(location.pathname)) {
        //setNarrativeDesc(narrative?.description || '');
      }

      if (narrative?.description_color) {
        setselectcolor(narrative?.description_color);
        setselectval(optionval?.find(x => x.color === narrative?.description_color)?.name);
      }// setNarrDescName(template?.narrative?.find(x => x.id === template.activeNarrativeId)?.narrative_name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template.activeNarrativeId, report?.editReport?.narrativeDetails, optionval]);

  // For update narrative in Template
  const handleUpdateNarrative = () => {
    if (!template.activeNarrativeId) {
      toastr.warning('', "Please select a narrative first");
      return;
    }

    dispatch(setNarrativesDetails({ description: narrativeDesc, id: template.activeNarrativeId }));

    const narrativeTemplateId = template?.getAllTemplateDetails?.sectionDetails?.find((a: any) => a?.section_name === template?.sections[template?.activeSectionTab]?.section_name)?.Components?.find((a: any) => a?.component_name === template?.sectionComponents?.[template?.activeComponentTab]?.component_name)?.Categories?.find((a: any) => a?.category_name === template?.category?.[template?.activeCategoryTab]?.category_name)?.Narratives?.find((a: any) => template?.narrative?.find((b) => b?.id === template.activeNarrativeId && a?.narrative_name === b?.narrative_name));

    if (narrativeTemplateId) {
      openSaveTemplate();
    }
    else {
      dispatch(updateNarrative({
        paramId: template.activeNarrativeId,
        payload: { description: narrativeDesc }
      }));
      toastr.success('', 'Narrative updated successfully');
    }
  }

  const saveFutureNarrative = (saveFutureTemplate: boolean) => {
    const narrativeTemplateId = template?.getAllTemplateDetails?.sectionDetails?.find((a: any) => a?.section_name === template?.sections[template?.activeSectionTab]?.section_name)?.Components?.find((a: any) => a?.component_name === template?.sectionComponents?.[template?.activeComponentTab]?.component_name)?.Categories?.find((a: any) => a?.category_name === template?.category?.[template?.activeCategoryTab]?.category_name)?.Narratives?.find((a: any) => template?.narrative?.find((b) => a?.narrative_name === b?.narrative_name))?.id;

    dispatch(updateNarrative({
      paramId: template.activeNarrativeId,
      payload: { description: narrativeDesc }
    }));

    if (saveFutureTemplate) {
      dispatch(updateNarrative({
        paramId: narrativeTemplateId,
        payload: { description: narrativeDesc }
      }));
    }

    toastr.success('', 'Narrative updated successfully');
    setShowUpdateButton(false);
    closeSaveTemplate();
  }

  // For update note in Template
  const handleUpdateNote = () => {
    if (!template.activeNarrativeId) {
      toastr.warning('', "Please select a narrative first");
      return;
    }
    dispatch(setNarrativesDetails({ note: noteDesc, id: template.activeNarrativeId }));
    dispatch(updateNarrative({
      paramId: template.activeNarrativeId,
      payload: { notes: noteDesc }
    }));
    toastr.success('', 'Notes updated successfully');
    setShowUpdateButton(false);
  }

  const handleActiveNarrative = (x: any) => {
    dispatch(setActiveNarrativeId(x.id));

    setselectval(optionval?.find(item => item.color === x?.description_color)?.name || 'Compliant');
    setselectcolor(x?.description_color || 'black');

    if (!template.randomNarrativeKey.find((a: any) => a.id === x.id)) {
      const key = `${report.reportDetails.templateId}_${Math.random().toString(36).slice(2)}`;

      dispatch(setNarrativeImages({ img: [], id: key }));
      dispatch(setRandomNarrativeKey([...template.randomNarrativeKey, { id: x.id, key: key }]));
    }
  }

  const OptionSelectedVal = (value: any) => {
    if (!template.activeNarrativeId) {
      toastr.warning('', "Please select a narrative first");
      return;
    }
    setselectval(value?.name);
    setselectcolor(value?.color);
    setvalueChange(false);
    dispatch(updateNarrative({ paramId: template.activeNarrativeId, payload: { description_color: `${value?.color}` } }));
  };

  // const handleNarrativeFutureTemplate = () => {
  //   dispatch(updateNarrative({ paramId: template.activeNarrativeId, payload: { description: report.editReport.narrativeDetails[template.activeNarrativeId]?.description } }));
  //   dispatch(setSaveNarrative(true));
  //   closeDisclaimer();
  // }

  useEffect(() => {
    if (/edit-report|add-report/gi.test(location.pathname) && template.activeNarrativeId === 0) {
      return template?.narrative?.forEach((x: any) => {
        if (report.editReport.selectedNarratives.find(y => y.id === x.id)?.checked) {
          dispatch(setActiveNarrativeId(x.id));
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  const handleNarrativeChange = (e: any, x: any) => {
    let newArr = [];
    newArr = array.filter(a => x.id !== a.id);
    let narrativeId: any;

    const selNarr = report.editReport.selectedNarratives.find(a => a.id === x.id);
    if (!e.target.checked && report.editReport.selectedNarratives) {
      array.forEach((a) => {
        if (a.id === x.id) {
          let local = JSON.parse(JSON.stringify(a));
          local.checked = false;
          newArr.push(local);
          setArray([...newArr]);
        }
      });
      array.forEach((a) => { if (a.id !== x.id && a.checked === true) { narrativeId = a } });
      dispatch(setSelectedNarratives({ id: x.id, checked: false, categoryId: template.category[template.activeCategoryTab].id }))
      setNarrDescName(template?.narrative?.find(y => y.id === narrativeId?.id)?.narrative_name);
      dispatch(setActiveNarrativeId(narrativeId?.id));

      const narrativeDescription = report.editReport.narrativeDetails[String(narrativeId?.id)];
      setNarrativeDesc(narrativeDescription?.description || template?.narrative?.find(z => z.id === narrativeId?.id)?.description);


      // TO STORE THE NARRATIVES SELECTED FOR HIGHLIGHTING CATEGORIES
      const activeCategoryId = template.narrative?.find(z => z.id === x.id)?.ComponentCategoryId;
      let index;
      template.narrativesList.find((z, i) => { if (Number(z) === activeCategoryId) { index = i; } });
      const filterNarr = JSON.parse(JSON.stringify(template.narrativesList));
      filterNarr.splice(index, 1);
      dispatch(setNarrativesList(filterNarr));

      // TO STORE THE NARRATIVES SELECTED FOR HIGHLIGHTING COMPONENTS
      const activeComponentId = template.category?.find(z => z.id === activeCategoryId)?.SectionComponentId;
      let index2;
      template.categoriesList.forEach((z, i) => { if (Number(z) === activeComponentId) { index2 = i; } });
      const filterNarr2 = JSON.parse(JSON.stringify(template.categoriesList));
      filterNarr2.splice(index2, 1);
      dispatch(setCategoriesList(filterNarr2));
    }
    else {
      if (selNarr) {
        let local = JSON.parse(JSON.stringify(selNarr));
        local.checked = true;

        newArr.push(local);
        setArray([...newArr]);
      }
      else {
        const abc = { id: x.id, checked: true, categoryId: template.activeCategoryTab, sectionId: template.activeSectionTab };
        setArray([...array, abc]);
      }
      dispatch(setSelectedNarratives({ id: x.id, checked: true, categoryId: template.category[template.activeCategoryTab].id }))
      const narrativeDescription = report.editReport.narrativeDetails[String(x?.id)];
      setNarrDescName(template?.narrative?.find(z => z.id === template.activeNarrativeId)?.narrative_name);
      setNarrativeDesc(narrativeDescription?.description || template?.narrative?.find(z => z.id === template.activeNarrativeId)?.description);
      setNoteDesc(narrativeDescription?.note || template?.narrative?.find(z => z.id === template.activeNarrativeId)?.notes);

      // TO STORE THE NARRATIVES SELECTED FOR HIGHLIGHTING CATEGORIES
      const activeCategory = template.narrative?.find(z => z.id === template.activeNarrativeId)?.ComponentCategoryId;
      if (template.narrativesList?.length) {
        dispatch(setNarrativesList([...template.narrativesList, `${activeCategory}`]));
      }
      else {
        dispatch(setNarrativesList([`${activeCategory}`]));
      }

      // TO STORE THE NARRATIVES SELECTED FOR HIGHLIGHTING COMPONENTS
      const activeComponent = template.category?.find(z => z.id === activeCategory)?.SectionComponentId;
      if (template.categoriesList?.length) {
        dispatch(setCategoriesList([...template.categoriesList, `${activeComponent}`]));
      }
      else {
        dispatch(setCategoriesList([`${activeComponent}`]));
      }
    }
  }

  return (
    <>
      <Box mt='20px' border='1px solid #DDDDDD' borderRadius='10px'>
        <Flex justifyContent='space-between' alignItems='center' bg='orange.light' borderRadius='10px 10px 0px 0px' p='4px 15px' h='48px'>
          <Text color='white.primary' fontWeight='600' fontSize={{ sm: 'xxxl', xxxl: 'xxxxl' }}>Narrative List</Text>
          {!['preview'].includes(action) && <Box d='inline-flex'>
            {!!template?.narrative?.length && <Button color='white.primary' fontSize='20px' fontWeight='600' d='flex' alignItems='center' variant='transparent' onClick={() => editChoice && editChoice('narrative')}>
              <Icon fontSize='18px' color='white.primary' mb='3px' as={MdModeEdit}></Icon>
            </Button>}
            {!!template?.category?.length && <Button color='white.primary' fontSize='20px' fontWeight='600' d='flex' alignItems='center' variant='transparent' onClick={() => addChoice && addChoice('narrative')}>
              <Icon color='orange.light' bg='white.primary' w='16px' h='16px' borderRadius='50%' mr='8px' as={MdAdd}></Icon>
            </Button>}
          </Box>}
        </Flex>
        <Box overflowY='auto' h='350px' p='10px 0'>
          <Scrollbars renderThumbVertical={props => <div {...props} className="thumb-vertical" />} style={{ height: '100%' }} overflow='auto'>
            <Box display='flex' flexWrap='wrap' justifyContent='flex-start' alignContent='flex-start' px='18px' w='100%' h='100%'>
              {(!!template.sectionComponents.length && !!template?.category?.length) && template?.narrative?.map((x: any) => {
                return <Box key={x.id} d='inline-flex' justifyContent='flex-start' textAlign='left' w='100%' p='15px' mt='10px' wordBreak='break-word' whiteSpace='normal' mx='auto' bg='white.lighten7' onClick={() => handleActiveNarrative(x)}>
                  {/* isChecked={/edit-report|edit-template|template/gi.test(location.pathname) && report.editReport.selectedNarratives.find(y => y.id === x.id)?.checked} */}
                  <Checkbox variant='transparent' colorScheme='transparent' w='100%' onChange={(e) => handleNarrativeChange(e, x)}><Text textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' w='calc(100% - 40px)'>{x.narrative_name}</Text></Checkbox>
                </Box>
              })}
            </Box>
          </Scrollbars>
        </Box>
      </Box>

      <Box mt='29px' border='1px solid #DDDDDD' borderRadius='10px' overflow='hidden'>
        <Tabs onChange={(index) => { setNarrativeActiveTab(index); }}>
          <TabList border='none'>
            <Tab _focus={{ boxShadow: 'none' }} _selected={{ boxShadow: 'none', color: 'white', bg: 'orange.light' }} color='orange.light' bg='orange.light2' h='48px' fontSize={{ sm: 'xxxl', xxl: 'xxxxl' }} fontWeight='600' flex='1' justifyContent={!/edit-report|add-report/gi.test(location.pathname) ? 'flex-start' : 'center'} p={!/edit-report|add-report/gi.test(location.pathname) ? '8px 20px' : '0'} _active={{ bg: 'borange.light' }}>Narrative Description</Tab>
            {/edit-report|add-report/gi.test(location.pathname) && <Tab _focus={{ boxShadow: 'none' }} _selected={{ boxShadow: 'none', color: 'white', bg: 'orange.light' }} color='orange.light' bg='orange.light2' h='48px' fontSize={{ sm: 'xxxl', xxl: 'xxxxl' }} fontWeight='600' flex='1' p='8px 20px' _active={{ bg: 'orange.light' }}>Note</Tab>}
          </TabList>
          <TabPanels borderRadius='10px'>
            <TabPanel p='0 28px 24px 28px'>
              <Flex pt='24px'>
                {(template?.narrative?.find(x => x.id === template.activeNarrativeId)?.narrative_name && narrDescName) && <Text w='100%' p='12px 10px 10px 15px' mb='24px' border='1px solid' borderColor='gray.light4' borderRadius='10px'>{narrDescName}</Text>}
                {/* {/edit-report|add-report/gi.test(location.pathname) && <Flex mb='24px' alignItems='center'>
                  <Checkbox mr='15px' variant='transparent' colorScheme='transparent' isChecked={template.saveNarrative} onChange={() => { dispatch(setSaveNarrative(!template.saveNarrative)); openDisclaimer(); }}></Checkbox>
                  <Text fontSize='18px' color='black.lighten'>Save narrative changes to library</Text>
                </Flex>} */}
              </Flex>
              <TextEditor
                value={narrativeDesc || ''}
                onChange={editorNarrativeUpdated}
                style={{ color: selectcolor }}
                readOnly={!(/edit-report|add-report/gi.test(location.pathname) || !['preview'].includes(action)) && true}
              />
              <Text fontSize='16px' color='black.lighten' m='20px 0 4px'>Condition</Text>
              <Box cursor='pointer' onClick={showDropdown} border='1px solid' borderRadius='4px' borderColor='gray.light4' p='10px' maxW='538px' w="100%" display="inline-flex" flexWrap="wrap" position="relative"><Flex justifyContent='space-between' w='100%' alignItems='center'><Flex alignItems='center'><Box h='21px' w='21px' borderRadius='3px' mr='13px' bg={selectcolor}></Box><span style={{ lineHeight: '1' }}><span className='template-dropdown-text'>{selectval}</span></span></Flex><Icon fontSize='22px' color='gray.darken3' as={MdArrowDropDown}></Icon></Flex> {valueChange && (
                <>{(/edit-report|add-report/gi.test(location.pathname) || !['preview'].includes(action)) && <>
                  <Box position="fixed" left="0" top="0" width="100%" height="100%" zIndex="8" onClick={showDropdown} ></Box>
                  <Box boxShadow='0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' width="100%" display="inline-flex" flexWrap="wrap" bg="rgba(255,255,255,0.9)" color="#fff" borderRadius="10px" maxH="200px" marginTop="5px" position="absolute" left="0" bottom={{ base: "35px", '2xl': "40px" }} zIndex="99" >
                    {optionval.map((item, index) => (
                      <Box key={index} padding={{ base: '6px 12px', '2xl': '10px 15px' }} display="inline-flex" flexWrap="wrap" width="100%" fontSize={{ base: '13px', '2xl': '15px' }} _hover={{ bg: "primaryOpacity.sh7", }} onClick={() => OptionSelectedVal(item)} >
                        <Flex alignItems='center'><Box h='21px' w='21px' borderRadius='3px' mr='13px' bg={`${item.color}`} className={`${item.color}`}></Box> <span className='template-dropdown-text'>{item.name}</span></Flex>
                      </Box>
                    ))}
                  </Box></>}
                </>
              )}
              </Box>
            </TabPanel>
            <TabPanel p='24px 28px'>
              <TextEditor
                value={noteDesc || ''}
                onChange={editorNoteUpdated}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {showUpdateButton && !['preview'].includes(action) && <Button float='right' variant='primary2' mt='20px' bg='orange.light' w='auto' onClick={narrativeActiveTab === 0 ? handleUpdateNarrative : handleUpdateNote} m='0 24px 24px 0'>{narrativeActiveTab === 0 ? 'Update Description' : 'Update Notes'}</Button>}
        {/* <Modal isOpen={disclaimer} onClose={closeDisclaimer} size='sm' scrollBehavior='inside'>
          <ModalOverlay />
          <ModalContent textAlign='center' p='25px'>
            <ModalHeader p='0' fontSize='18px' lineHeight='22px' color='black.lighten' fontWeight='600'>Disclaimer</ModalHeader>
            <ModalBody p='0'>
              <Text mt='12px' fontSize='15px' color='black.lighten'>On checking this box , the changes made to the narratives will be applicable to all your future reports</Text>
            </ModalBody>
            <ModalFooter justifyContent='space-between' p='0'>
              <Button variant='grey' mt='25px' w='95px' fontSize='15px' onClick={() => {
                dispatch(setSaveNarrative(false));
                closeDisclaimer();
              }}>Cancel</Button>
              <Button mt='25px' variant='primary2' w='95px' fontSize='15px' onClick={handleNarrativeFutureTemplate}>Agreed</Button>
            </ModalFooter>
          </ModalContent>
        </Modal> */}

        <Modal isOpen={saveTemplate} onClose={closeSaveTemplate} size='xl' scrollBehavior='inside'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader p='30px 30px 35px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>Save Narrative</ModalHeader>
            <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeSaveTemplate} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
            <ModalBody p='0 30px 25px'>
              <Text fontSize='18px' color='black.lighten'>Do you want to save it for the future templates?</Text>
            </ModalBody>
            <ModalFooter>
              <Button variant='primary2' minW='120px' onClick={() => saveFutureNarrative(true)}>Yes</Button>
              <Button variant='grey' ml='15px' minW='120px' onClick={() => saveFutureNarrative(false)}>No</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  )
}

// Handle template states
export function useTemplateState() {
  const dispatch = useDispatch();
  const template: TemplateState = useSelector((state: RootState) => state.template);

  useEffect(() => {
    if (template.sections.length && template.sections.length >= template.activeSectionTab) {
      dispatch(resetTemplate(['sectionComponents', 'category', 'narrative', 'inspectionMethod']));
      dispatch(getSectionComponents({ payload: { sectionId: template.sections[template.activeSectionTab]?.id } }));
      dispatch(getInspectionMethod({ payload: { templateSectionId: template.sections[template.activeSectionTab]?.id } }));
      dispatch(setActiveComponentTab(0)); // Set setActiveComponentTab to zero
    }
  }, [dispatch, template.activeSectionTab, template.sections]);

  useEffect(() => {
    if (template.sectionComponents.length && template.sectionComponents.length >= template.activeComponentTab) {
      dispatch(resetTemplate(['category', 'narrative', 'inspectionMethod']));
      dispatch(getCategory({ payload: { componentId: template.sectionComponents[template.activeComponentTab].id } }));
      dispatch(getInspectionMethod({ payload: { templateSectionId: template.sections[template.activeSectionTab]?.id } }));
      dispatch(setActiveCategoryTab(0)); // Set setActiveCategoryTab to zero
    }
  }, [dispatch, template.activeComponentTab, template.activeSectionTab, template.sectionComponents, template.sections]);

  useEffect(() => {
    if (template.category.length && template.category.length >= template.activeCategoryTab) {
      dispatch(resetTemplate(['narrative']));
      dispatch(getNarratives({ payload: { categoryId: template.category[template.activeCategoryTab].id } }));
      dispatch(resetSelectedNarratives());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, template.activeSectionTab, template.activeCategoryTab, template.category.length, template.category]);
}