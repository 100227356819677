import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import React, { useState, useEffect } from "react";
import {
  IoIosAddCircle,
  IoIosRemoveCircle,
  IoMdClose,
  IoMdEye,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { receipt, property, camera } from "../../../../assets/images/icons-svg";
import DateTimePicker from "react-datetime-picker";
import {
  MdClose,
  MdDelete,
  MdModeEdit,
  MdOutlinePayments,
  MdPhotoCamera,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useForm } from "../../../../utils/hooks";
import { toastr } from "react-redux-toastr";
import { deleteConstructionType, deletePropertyStyle, deletePropertyType, getAllReports, setReportAgreement, updatePaymentMode } from "../../../../redux/modules/report";
import {
  createAgents,
  createClientInformation,
  createConstructionType,
  createInspectorDetail,
  createOccupancyType,
  createOrienationType,
  createPaymentMode,
  createPropertyStyle,
  createPropertyType,
  createPropertyDetail,
  createWeatheReport,
  getConstructionType,
  getOccupancyType,
  getOrienationType,
  getPropertyStyle,
  getPropertyType,
  updateAgent,
  updateClientInformation,
  updateInspectorDetail,
  updatePropertyDetail,
  updateWeatheReport,
  uploadAgentImage,
  uploadBacksideImage,
  uploadFrontsideImage,
  uploadPropertyImage,
  getAgents,
  ReportState,
  resetAddReport,
  getReportDetail,
  createReportInspectionMethod,
  createInspectionDetail,
  setEditReportDetails,
  getPropertyImages,
  updateReportAgent,
  setSelectedNarratives,
  setNarrativesDetails,
  setSelectedInspectionMethod,
  setReportError,
  getInspectorDetail,
  uploadinspectorimage,
  uploadNarrativeImage,
  setReportTemplateState
} from "../../../../redux/modules/report";
import { RiTempHotFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import CropImage, {
  dataURLtoFile,
} from "../../../../components/common/CropImage";
import "react-quill/dist/quill.snow.css";
import AddImagePlaceholder from "../../../../components/common/AddImagePlaceholder";
import AddChoiceModal from "../../../../components/common/AddChoiceModal";
import { fillFormValues, googleMapAutocomplete, loadGoogleMapScript } from "../../../../utils/common";
import Loader from "../../../../components/common/loader";
import {
  getAllTemplateDetails,
  getTemplateById,
  getTemplateSection,
  setFutureTemplate,
  setRandomNarrativeKey,
  setTemplateError,
  TemplateState,
  updateNarrative,
} from "../../../../redux/modules/template";
import {
  authState,
  getAggrements,
  setAuthError,
  uploadUserImage,
  userDetailById,
} from "../../../../redux/modules/auth";
import CreateTemplate, {
  useTemplateState
} from "../../../common/Template/CreateTemplate/CreateTemplate";
import AgreementModal from "../../../../components/common/AgreementModal";
import { addChoiceModalState } from "../../../../components/common/AddChoiceModal/AddChoiceModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsFillHouseFill } from "react-icons/bs";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { FiArrowLeft } from "react-icons/fi";
import store from "store2";
import { DashboardState, getScheduleJobs, setAddJobValues } from "../../../../redux/modules/dashboard";
import { getAdminScheduleJobs } from "../../../../redux/modules/admin/adminDashboard";
import { Link } from "react-router-dom";
import refreshIcon from "../../../../assets/images/refresh.svg"
import { css } from "@emotion/react";
import StarRatings from 'react-star-ratings';
export default function AddReport() {
  const [maxActiveStep, saveMaxActiveStep] = useState(0);
  const STEPPER = [
    "Save Inspection Details",
    "Save Client Information",
    "Save Property Details",
    "Save Weather Condition",
    "Save Agent Details",
    "Save Payment Details",
    "Save Inspection Details",
  ];

  useTemplateState();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth: authState = useSelector((state: RootState) => state.auth);
  const report: ReportState = useSelector((state: RootState) => state.report);
  const dashboard: DashboardState = useSelector((state: RootState) => state.dashboard);
  const template: TemplateState = useSelector(
    (state: RootState) => state.template
  );
  const { nextStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });
  const {
    isOpen: showDisclaimer,
    onOpen: openShowDisclaimer,
    onClose: closeShowDisclaimer,
  } = useDisclosure();

  const {
    isOpen: showType,
    onOpen: openShowType,
    onClose: closeShowType,
  } = useDisclosure();

  const INSPECTOR_DETAILS_FORM = {
    default: {
      city: "",
      state: "",
      country: "",
      zipcode: "",
      travelDistance: "",
      inspectorName: "",
      inspectionLocation: "",
      reportId: Math.floor(Math.random() * 100000) + 1,
      Duration: ""
    },
    field: {
      required: true,
      inputs: ["reportId", "inspectionLocation", "city", "state", "country", "zipcode"],
    },
  };

  const CLIENT_INFO_FORM = {
    default: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    },
    field: {
      required: true,
      inputs: ["firstName", "email", "phone"],
    },
  };

  const PROPERTY_DETAILS_FORM = {
    default: {
      occupancy: "",
      propertyType: "",
      constructiontype: "",
      area: "",
      address: "",
      propertyStyle: "",
      orientation: "",
      furnished: "",
      utility: "",
    },
    field: {
      required: true,
      inputs: [],
    },
  };

  const WEATHER_REPORT_FORM = {
    default: {
      climate: "",
      humidity: "",
      temperature: "",
    },
    field: {
      required: true,
      inputs: [],
    },
  };

  const AGENT_DETAILS_FORM = {
    default: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notes: "",
      address: "",
      agencyName: "",
      agencyNumber: "",
    },
    field: {
      required: true,
      inputs: [],
    },
  };

  const PAYMENT_DETAILS_FORM = {
    default: {
      paymentMode: "",
      duration: "",
      price: "",
      selectService: "",
      propertyStyle: "",
      tax: "",
      area: "",
      totalAmount: "",
    },
    field: {
      required: true,
      inputs: [],
    },
  };

  // Select agent states
  const [selectedAgent, setSelectedAgent] = useState({ buyer: "", seller: "" });

  // Images states
  const [propertyImage, setpropertyImage] = useState<any>([]);
  const [agentImage, setAgentImage] = useState<any>({ img: null, name: null });
  const [backsideImage, setBacksideImage] = useState<any>({
    img: null,
    name: null,
  });
  const [frontsideImage, setfrontsideImage] = useState<any>({
    img: null,
    name: null,
  });
  const [inspectorImage, setinspectorImage] = useState<any>({
    img: null,
    name: null,
  });

  // Crop modal states
  const [openCropModel, setOpenCropModel] = useState(false);
  const [image, setImage] = useState<any>({ src: null, name: null, for: null });
  const [croppedImage, setCroppedImage] = useState<any>({
    img: null,
    name: null,
  });

  // Add choice modal states
  const [openAddChoiceModel, setOpenAddChoiceModel] = useState(false);
  const [addChoiceState, setAddChoiceState] = useState<addChoiceModalState>({
    callback: null,
    dafaultValue: "",
    fieldHeading: null,
  });

  const [rating, changeRating] = useState(0);
  const [tempunit, setTempunit] = useState("c");
  const [yearBuilt, changeYearBuilt] = useState('');
  const [inspectionDate, setInspectionDate] = useState(new Date());
  const [inspectionFromTime, setInspectionFromTime] = useState(new Date());
  const [inspectionEndTime, setInspectionEndTime] = useState(new Date());
  const [inspectorAttendees, setInspectorAttendees] = useState({});
  const [showInspactionDetails, setShowInspactionDetails] = useState(false);
  const presentAttendes = ['All', 'Buyer', 'Seller', 'Buyer Agent', 'Seller Agent'];
  const [checkedClientAddress, setCheckedClientAddress] = useState(false);
  const [checkDisbled, setCheckDisbled] = useState(true);
  const [clientAddressDisbled, setClientAddressDisbled] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [scheduledJobId, setScheduleJobId] = useState(null);
  // Set agreement modal states
  const [checkedAgreement, setCheckedAgreement] = useState({});
  const [disableAgreementClick, setDisableAgreementClick] = useState<any>(false);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [openAgreementModel, setOpenAgreementModel] = useState(false);

  // Set Travel Distance
  const [travelDistance, setTravelDistance] = useState("");

  // Property Details choice Delete
  const [propertyChoiceType, setPropertyTypeChoice] = useState([]);
  const [propertyTypeHeader, setPropertyTypeHeader] = useState('');

  // Forms states
  const {
    values: inspectorValues,
    change: inspectorChange,
    touched: inspectorTouched,
    errors: inspectorErrors,
    setValues: setInspectorValues,
  } = useForm(INSPECTOR_DETAILS_FORM.default, INSPECTOR_DETAILS_FORM.field);
  const {
    values: clientValues,
    change: clientChange,
    touched: clientTouched,
    errors: clientErrors,
    setValues: setClientValues,
  } = useForm(CLIENT_INFO_FORM.default, CLIENT_INFO_FORM.field);
  const {
    values: paymentValues,
    change: paymentChange,
    touched: paymentTouched,
    errors: paymentErrors,
    setValues: setPaymentValues,
  } = useForm(PAYMENT_DETAILS_FORM.default, CLIENT_INFO_FORM.field);
  const {
    values: weatherValues,
    change: weatherChange,
    touched: weatherTouched,
    errors: weatherErrors,
    setValues: setWeatherValues,
  } = useForm(WEATHER_REPORT_FORM.default, WEATHER_REPORT_FORM.field);
  const {
    values: agentValues,
    change: agentChange,
    touched: agentTouched,
    errors: agentErrors,
    reset: agentReset,
    setValues: agentSetValues,
  } = useForm(AGENT_DETAILS_FORM.default, AGENT_DETAILS_FORM.field);
  const {
    values: propertyValues,
    change: propertyChange,
    touched: propertyTouched,
    errors: propertyErrors,
    setValues: setPropertyValues,
  } = useForm(PROPERTY_DETAILS_FORM.default, PROPERTY_DETAILS_FORM.field);

  // Initial value loder
  // ... render page resources (add-report)
  useEffect(() => {
    if (/add-report/gi.test(location.pathname)) {
      dispatch(resetAddReport()); // Reset report
      openShowDisclaimer();
      if (auth?.user?.id) {
        dispatch(getAgents());
        dispatch(getPropertyType());
        dispatch(getOccupancyType());
        dispatch(getPropertyStyle());
        dispatch(getOrienationType());
        dispatch(getConstructionType());
        dispatch(getAggrements({ payload: { userId: auth.user.id } }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.id, dispatch, location.pathname]);

  useEffect(() => {
    if (/edit-report/gi.test(location.pathname) && report?.reportDetails?.templateId) {
      dispatch(
        getTemplateSection({
          payload: { templateId: report.reportDetails.templateId },
        })
      );
    }

    if (/edit-report/gi.test(location.pathname) && auth.user?.id && auth.user.templateId) {
      dispatch(getAllTemplateDetails({ payload: { templateId: auth.user.templateId } }));
      if ((report.reportDetails.selectedFrom === "super_admin" && auth.user?.user_Type === "company_admin") || (report.reportDetails.selectedFrom !== "home_inspector" && (auth.user?.user_Type === "home_inspector" || auth.user?.user_Type === "super_admin_home_inspector")))
        dispatch(setFutureTemplate(false));
    } else {
      dispatch(setFutureTemplate(true));
    }
  }, [auth.user?.id, auth.user?.templateId, auth.user?.user_Type, dispatch, location.pathname, report.reportDetails.selectedFrom, report.reportDetails.templateId])

  useEffect(() => {
    dispatch(getScheduleJobs());
    dispatch(getAdminScheduleJobs());

    return () => { dispatch(resetAddReport()); }
  }, [dispatch])

  useEffect(() => {
    const activeJob = dashboard.scheduledJobs.find((x: any) => x.id === dashboard.activeJobId);
    if (activeJob) {
      setInspectorValues({ ...inspectorValues, travelDistance: dashboard.travelDistance, inspectionLocation: activeJob.inspectionAddress, city: activeJob.city, state: activeJob.state, country: activeJob.country, zipcode: activeJob.zipcode });
      setInspectionDate(new Date(activeJob.inspectionDate));
      setInspectionFromTime(new Date(activeJob.StartTime));
      setInspectionEndTime(new Date(activeJob.EndTime));
      setClientValues({ ...clientValues, firstName: activeJob.clientName, lastName: activeJob.clientLastName, email: activeJob.clientEmail, phone: activeJob.clientPhoneNumber });
      setTravelDistance(dashboard.travelDistance);
      setScheduleJobId(activeJob.id);
    }

    return () => { dispatch(setAddJobValues(null)); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard.activeJobId, dashboard.scheduledJobs, dispatch, dashboard.travelDistance]);

  // Initial value loder
  // ... render page resources (edit-report)

  useEffect(() => {
    if (auth.user?.firstName) {
      setInspectorValues({
        ...inspectorValues,
        inspectorName: `${auth.user?.firstName} ${auth.user?.lastName}`.trim(),
        Duration: `${(Math.abs(Math.round(((inspectionEndTime.getTime() - inspectionFromTime.getTime()) / 1000) / (60 * 60))))} hours`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.firstName, inspectionEndTime, inspectionFromTime, setInspectorValues])

  useEffect(() => {
    if (report.editReport.report.inspectorDetailId) {
      dispatch(
        getInspectorDetail({ inspectorId: report.editReport.report.inspectorDetailId })
      )
    }
  }, [dispatch, report.editReport.report.inspectorDetailId])

  useEffect(() => {
    if (/edit-report/ig.test(location.pathname) && report?.reportDetails?.templateId) {
      // setTemplateAction('edit');
      dispatch(getTemplateById({ templateId: report.reportDetails.templateId }));
      getReportDetail({ paramId: params.id });
      dispatch(getAllReports());
    }
  }, [dispatch, location.pathname, params.id, report.reportDetails.templateId]);

  useEffect(() => {
    async function loadEditReportDetails() {
      new Promise(() => {
        const data2: any = dispatch(
          getReportDetail({ paramId: params.id })
        );
        data2.then(function (value) {
          if (value.type === "report/getReportDetail/fulfilled") {
            const { data, inspectionDetails } = value.payload;
            const reports = {
              id: data.id,
              userId: data.userId,
              buyerId: data.buyerId ?? "",
              sellerId: data.sellerId ?? "",
              inspectorDetailId: data.inspectorDetailId,
              templateId: inspectionDetails.sections.length
                ? inspectionDetails.sections[0].template.id
                : auth.user.templateId,
            };
            // Fill form with pre-defined values
            if (data.inspectorDetail) {
              setInspectorValues(
                fillFormValues(INSPECTOR_DETAILS_FORM.default, data.inspectorDetail, [
                  "id",
                ])
              );
            }
            setClientValues(
              fillFormValues(CLIENT_INFO_FORM.default, data.clientinformation, [
                "id",
              ])
            );
            if (data.propertyDetail) {
              setPropertyValues(
                fillFormValues(PROPERTY_DETAILS_FORM.default, data.propertyDetail, [
                  "id",
                ])
              );
            }
            if (data["weather-detail"]) {
              setWeatherValues(
                fillFormValues(WEATHER_REPORT_FORM.default, data["weather-detail"], [
                  "id",
                ])
              );
            }
            setPaymentValues(
              fillFormValues(PAYMENT_DETAILS_FORM.default, data["payment-detail"], [
                "id",
              ])
            );

            // Set individual state with pre-defined values
            changeRating(Number(data.clientinformation?.clientRating || 0));
            setSelectedAgent({ buyer: reports.buyerId, seller: reports.sellerId });
            setTempunit(data["weather-detail"]?.temperature?.split(" ")?.at(-1));
            changeYearBuilt(
              data.propertyDetail
                ? data.propertyDetail.yearBuilt
                : ' '
            );
            setInspectionDate(
              data.inspectorDetail
                ? new Date(data.inspectorDetail.inspectionDate)
                : new Date()
            );
            setInspectionFromTime(
              data.inspectorDetail.inspectionEndDate
                ? new Date(data.inspectorDetail.inspectionEndDate)
                : new Date()
            );
            setInspectionEndTime(
              data.inspectorDetail.inspectionEndTime
                ? new Date(data.inspectorDetail.inspectionEndTime)
                : new Date()
            );
            if (data.sentAggrementLink) {
              const str = [];
              const id = data.sentAggrementLink.split("agrId=");
              id.splice(0, 1);
              id.forEach((x) => {
                var out = x.replace(/&/g, '');
                str.push(out);
              })
              const obj = {}
              str.forEach((elem) => {
                obj[elem] = true;
              })
              setCheckedAgreement(obj);
              setDisableAgreementClick(Object.values(obj)[0]);
            }
            setInspectorAttendees(data.inspectorDetail.inspectionAttendees);
            if (Boolean(Number(data.inspectorDetail.isClientAddressSame)) && data.clientinformation) {
              setClientAddressDisbled(data.inspectorDetail.isClientAddressSame);
              setCheckedClientAddress(Boolean(Number(data.inspectorDetail.isClientAddressSame)));
            }
            dispatch(getAggrements({ payload: { userId: data.userId } }));
            dispatch(getPropertyImages({ payload: { reportId: data.id } }));

            if (reports.templateId) {
              if (!/edit-report/gi.test(location.pathname)) {
                dispatch(
                  getTemplateSection({ payload: { templateId: reports.templateId } })
                );
              }
            }

            // Fill state with pre-defined values
            dispatch(setEditReportDetails({ key: "report", data: reports }));
            dispatch(
              setEditReportDetails({
                key: "inspectorDetail",
                data: data?.inspectorDetail ?? {},
              })
            );
            dispatch(
              setEditReportDetails({
                key: "clientInformation",
                data: data?.clientinformation ?? {},
              })
            );
            dispatch(
              setEditReportDetails({
                key: "propertyDetails",
                data: data?.propertyDetail ?? {},
              })
            );
            dispatch(
              setEditReportDetails({
                key: "weatherCondition",
                data: data["weather-detail"] ?? {},
              })
            );
            dispatch(
              setEditReportDetails({
                key: "paymentDetails",
                data: data["payment-detail"] ?? {},
              })
            );

            // Set Narratives & Inspections methods
            data.reportInspectionMethods.forEach((x: any) => {
              dispatch(
                setSelectedInspectionMethod({
                  checked: true,
                  editMode: true,
                  reportInspectionId: x.id,
                  id: x.inspectionMethodId,
                })
              );
            });

            inspectionDetails.narratives.forEach((x: any) => {
              dispatch(
                setSelectedNarratives({
                  id: x["category-Narrative"].id,
                  reportNarrativeId: x.id,
                  checked: false,
                  editMode: true,
                  description: x.description,
                  categoryId: x['category-Narrative'].ComponentCategoryId
                })
              );

              dispatch(
                setNarrativesDetails({
                  note: x["category-Narrative"].notes,
                  editMode: true,
                  reportNarrativeId: x.id,
                  description: x["category-Narrative"].description,
                  id: x["category-Narrative"].id,
                  categoryId: x["category-Narrative"].ComponentCategoryId
                })
              );
            });

            dispatch(
              setRandomNarrativeKey(
                inspectionDetails.narratives.map((x: any) => {
                  return { id: x["category-Narrative"].id, key: x.randomKey };
                })
              )
            );
          }
        })
      })
    }

    if (
      /edit-report/gi.test(location.pathname) &&
      params.id &&
      auth?.user?.id
    ) {
      dispatch(resetAddReport()); // Reset report

      dispatch(getAgents());
      dispatch(getPropertyType());
      dispatch(getOccupancyType());
      dispatch(getPropertyStyle());
      dispatch(getOrienationType());
      dispatch(getConstructionType());
      dispatch(getAggrements({ payload: { userId: auth.user.id } }));
      loadEditReportDetails(); // Load edit-report details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.id, dispatch, location, params.id]);

  const fileChange = (event: any) => {
    event.preventDefault();

    let files: any;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }

    if (files.length <= 1) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      setOpenCropModel(true); // Open Modal

      reader.onload = () => {
        setImage({
          name: files[0].name,
          for: event.target.name,
          src: reader.result as any,
        });
        event.target.value = ""; // Empty input value. So same file can be select again
      };
    } else if (event.target.name === "propertyImage" && files.length > 1) {
      setpropertyImage([...propertyImage, ...Array.from(files)]);
      event.target.value = ""; // Empty input value. So same file can be select again
    }
  };

  useEffect(() => {
    if (croppedImage.img !== null) {
      if (image.for === "backsideImage") {
        setBacksideImage({ img: croppedImage.img, name: image.name });
      } else if (image.for === "frontsideImage") {
        setfrontsideImage({ img: croppedImage.img, name: image.name });
      } else if (image.for === "inspectorImage") {
        setinspectorImage({ img: croppedImage.img, name: image.name });
        // put file into form data
        const data = new FormData();
        data.append(
          'image',
          dataURLtoFile(
            croppedImage.img,
            image.name
          )
        );
        const uploadInspectorImage = () => {
          new Promise(() => {
            const data3: any = dispatch(
              uploadUserImage({
                userId: auth.user.id,
                payload: data
              })
            );
            data3.then(function (value) {
              if (value.type === 'auth/uploadUserImage/fulfilled') {
                toastr.success('', 'Image uploaded successfully');
                dispatch(userDetailById({ userId: auth.user.id }));
              }
            })
          })
        }
        uploadInspectorImage();
      } else if (image.for === "agentImage") {
        setAgentImage({ img: croppedImage.img, name: image.name });
      } else if (image.for === "propertyImage") {
        setpropertyImage([
          ...propertyImage,
          { img: croppedImage.img, name: image.name },
        ]);
      }

      // Set selected image to null
      // ... So new image can be selected
      setCroppedImage({ img: null, name: null });
      setImage({ name: null, src: null, for: null });
    }
  }, [auth?.user?.id, croppedImage.img, dispatch, image, propertyImage]);

  const deleteInspectorDetailsImage = (imge: string) => {
    if (imge === "backendAccessUrl") {
      setBacksideImage({ img: null, name: null });
    } else if (imge === "frontendAccessUrl") {
      setfrontsideImage({ img: null, name: null });
    }
    // else if (imge === "inspectorImage") {
    //   setinspectorImage({ img: null, name: null });
    // }

    // Also delete from server
    if (
      report.editReport.inspectorDetail[imge] &&
      ["backendAccessUrl", "frontendAccessUrl", "inspectorImage"].includes(imge)
    ) {
      dispatch(
        updateInspectorDetail({
          payload: { [imge]: "" },
          inspectorId: report.editReport.report.inspectorDetailId,
        })
      );
    }
  };

  const submitInspectorDetailImage = (id: string) => {
    if (inspectorImage.img) {
      let inspectorImageData = new FormData();
      inspectorImageData.append(
        "image",
        dataURLtoFile(inspectorImage.img, inspectorImage.name)
      );

      // Call uploadInspectorImage api
      new Promise(() => {
        const data4: any = dispatch(
          uploadinspectorimage({
            inspectorId: id,
            payload: inspectorImageData,
          })
        );
        data4.then(function (value) {
          if (value.type === "report/uploadInspectorImage/fulfilled") {
            setinspectorImage({ img: null, name: null });
            new Promise(() => {
              const data5: any = dispatch(
                userDetailById({ userId: auth.user.id })
              );
              data5.then(function (value1) {
                if (value1.type === "auth/userDetailById/fulfilled") {
                  // request.payload.user.id
                  dispatch(
                    updateInspectorDetail({
                      payload: {
                        inspectorImage: value1.payload.user.image,
                        inspectorAccessImageUrl: auth.user?.AccessProfileUrl,
                      },
                      inspectorId: report.editReport.inspectorDetail.id || id,
                    })
                  );
                }
              })
            })
          }
        })
      })
    }

    if (frontsideImage.img) {
      let frontsideImageData = new FormData();
      frontsideImageData.append(
        "image",
        dataURLtoFile(frontsideImage.img, frontsideImage.name)
      );

      // Call uploadInspectorImage api
      new Promise(() => {
        const data6: any = dispatch(
          uploadFrontsideImage({
            inspectorId: id,
            payload: frontsideImageData,
          })
        );
        data6.then(function (value) {
          if (value.type === "report/uploadFrontsideImage/fulfilled") {
            setfrontsideImage({ img: null, name: null });
          }
        })
      })
    }

    if (backsideImage.img) {
      let backsideImageData = new FormData();
      backsideImageData.append(
        "image",
        dataURLtoFile(backsideImage.img, backsideImage.name)
      );

      // Call uploadInspectorImage api
      new Promise(() => {
        const data7: any = dispatch(
          uploadBacksideImage({
            inspectorId: id,
            payload: backsideImageData,
          })
        );
        data7.then(function (value) {
          if (value.type === "report/uploadBacksideImage/fulfilled") {
            setBacksideImage({ img: null, name: null });
          }
        })
      })
    }
  };
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  useEffect(() => {
    if (loadMap) {
      let autocomplete: google.maps.places.Autocomplete;
      const autocompleteInput = document.getElementById('autocomplete') as HTMLInputElement;
      autocomplete = new google.maps.places.Autocomplete(autocompleteInput);

      // When a place is selected from the autocomplete dropdown
      autocomplete.addListener('place_changed', () => {
        const inspectionAddress = googleMapAutocomplete(autocomplete);

        if (inspectionAddress) {
          setInspectorValues({
            city: inspectionAddress.addressCity,
            state: inspectionAddress.addressState,
            country: inspectionAddress.addressCountry,
            zipcode: inspectionAddress.addressZipcode || "",
            travelDistance: "",
            inspectorName: inspectorValues.inspectorName,
            inspectionLocation: inspectionAddress.streetNumber,
            reportId: inspectorValues.reportId,
            Duration: inspectorValues.Duration
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectorValues.inspectionLocation]);

  const calculateDistance = () => {
    if (Object.keys(inspectorTouched).length <= 0 && (!inspectorValues.inspectionLocation)) {
      toastr.warning("", "Please fill inspection Address first");
      return;
    }
    else if ((!inspectorValues.city)) {
      toastr.warning("", "Please fill city first");
      return;
    }
    else if ((!inspectorValues.state)) {
      toastr.warning("", "Please fill state first");
      return;
    }
    else if ((!inspectorValues.country)) {
      toastr.warning("", "Please fill country first");
      return;
    }
    else if ((!inspectorValues.zipcode)) {
      toastr.warning("", "Please fill zipcode first");
      return;
    }

    else if (Object.keys(clientErrors).length > 0) {
      toastr.warning("", clientErrors[Object.keys(clientErrors)[0]]);
      return;
    }
    else {
      const service = new google.maps.DistanceMatrixService();
      const origin1 = inspectorValues.inspectionLocation + ", " + inspectorValues.city + ", " + inspectorValues.state + ", " + inspectorValues.country
      const destinationA = auth.company.companyAddress + ", " + auth.company.city + ", " + auth.company.state;
      const request = {
        origins: [origin1],
        destinations: [destinationA],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      };
      service.getDistanceMatrix(request).then((response) => {
        if (!response?.rows?.[0].elements?.[0]?.distance?.text) {
          toastr.error('', 'Please enter a valid address');
          return;
        }
        // put response
        setTravelDistance(response?.rows?.[0].elements?.[0]?.distance?.text)
      });
    }
  }

  useEffect(() => {
    const uploadPropertyImages = () => {
      if (propertyImage.length) {
        const propertyImageData = [];

        if (propertyImage.length > 1) {
          propertyImage.forEach((x: any, index: number) => {
            propertyImageData[index] = new FormData();
            propertyImageData[index].append("image", x);
          });
        } else {
          propertyImageData[0] = new FormData();
          propertyImageData[0].append(
            "image",
            dataURLtoFile(
              propertyImage?.at(-1)?.img,
              propertyImage?.at(-1)?.name
            )
          );
        }

        // Call uploadPropertyImage api
        new Promise(() => {
          const data8: any = dispatch(
            uploadPropertyImage({
              payload: propertyImageData,
              reportId: report.editReport.report.id,
            })
          );
          data8.then(function (value) {
            if (value.type === "report/uploadPropertyImage/fulfilled") {
              setpropertyImage([]); // Reset state for images
            }
          })
        })
      }
    };

    uploadPropertyImages();
  }, [dispatch, propertyImage, report.editReport.report?.id]);

  const formSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (activeStep !== 0 && !report.editReport.report.id) {
      toastr.warning("", "First fill the inspection details");
      return;
    }

    if (activeStep === 0) {
      if (!inspectorValues.inspectionLocation || inspectorValues.inspectionLocation === "") {
        toastr.warning("", "Inspection Location is required");
        return;
      }
      if (!inspectorValues.city || inspectorValues.city === "") {
        toastr.warning("", "City is required");
        return;
      }
      if (!inspectorValues.state || inspectorValues.state === "") {
        toastr.warning("", "State is required");
        return;
      }
      if (!inspectorValues.country || inspectorValues.country === "") {
        toastr.warning("", "Country is required");
        return;
      }
      if (!inspectorValues.zipcode || inspectorValues.zipcode === "") {
        toastr.warning("", "Zipcode is required");
        return;
      }
      // if (Object.keys(inspectorErrors).length > 0) {
      //   toastr.warning("", inspectorErrors[Object.keys(inspectorErrors)[0]]);
      //   return;
      // }

      if (inspectionFromTime.getTime() >= inspectionEndTime.getTime()) {
        toastr.warning("", "Inspection Start Time should be less than Inspection End Time");
        return;
      }

      if (inspectionEndTime.getHours() - inspectionFromTime.getHours() < 1) {
        toastr.warning("", "There should be minimum 1 hour difference between Inspection Start and Inspection End Time");
        return;
      }

      if (
        !report.editReport.inspectorDetail?.id &&
        Object.keys(inspectorTouched).length <= 0 && (!inspectorValues.inspectionLocation)
      ) {
        toastr.warning("", "Please fill the Inspection Address");
        return;
      }
      const address = inspectorValues.inspectionLocation.toLowerCase() + ' ' + inspectorValues.city.toLowerCase() + ' ' + inspectorValues.state.toLowerCase() + ' ' + inspectorValues.zipcode;
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': address }, (results, status) => {
        if (status === 'OK') {
          if (report.editReport.inspectorDetail?.id) {
            // Call updateInspectorDetail api
            new Promise(() => {
              const data9: any = dispatch(
                updateInspectorDetail({
                  payload: {
                    ...inspectorValues,
                    inspectionDate,
                    inspectionEndDate: inspectionFromTime,
                    inspectorImage: auth.user?.profileImage,
                    inspectionAttendees: inspectorAttendees,
                    inspectionEndTime: inspectionEndTime,
                    isClientAddressSame: checkedClientAddress,
                  },
                  inspectorId: report.editReport.inspectorDetail.id,
                })
              );
              data9.then(function (value) {
                if (value.type === "report/updateInspectorDetail/fulfilled") {
                  submitInspectorDetailImage(
                    report.editReport.report.inspectorDetailId
                  );
                  nextStep(); // Move to next step
                  toastr.success("", "Inspector detail updated successfully");
                }
              })
            })
          }
          else {
            // Call createInspectordetail api
            new Promise(() => {
              const data11: any = dispatch(
                createInspectorDetail({
                  payload: {
                    ...inspectorValues,
                    inspectionDate: inspectionDate,
                    inspectionEndDate: inspectionFromTime,
                    inspectorAccessImageUrl: auth.user?.AccessProfileUrl,
                    inspectorImage: auth.user?.image,
                    inspectionAttendees: inspectorAttendees,
                    inspectionEndTime: inspectionEndTime,
                    isClientAddressSame: checkedClientAddress,
                    scheduleJobId: scheduledJobId
                  },
                })
              );
              data11.then(function (value) {
                if (value.type === "report/createInspectorDetail/fulfilled") {
                  submitInspectorDetailImage(value.payload.inspectordetail.id);
                  saveMaxActiveStep(activeStep + 1);
                  nextStep(); // Move to next step
                  toastr.success("", "Inspection details saved successfully");
                }
              })
            })
          }
        } else {
          toastr.error('', 'Please enter a valid inspection address');
          return;
        }
      });
    } else if (activeStep === 1) {
      if (
        !report.editReport.clientInformation?.id &&
        Object.keys(clientTouched).length <= 0 && (!clientValues.firstName && !clientValues.email)
      ) {
        toastr.warning("", "Please first fill the form");
        return;
      }
      if (!clientValues.firstName || clientValues.firstName === "") {
        toastr.warning("", "First Name is required");
        return;
      }
      if (!clientValues.email || clientValues.email === "") {
        toastr.warning("", "Email is required");
        return;
      }
      if (!clientValues.phone || clientValues.phone === "") {
        toastr.warning("", "Phone is required");
        return;
      }

      if (Object.keys(clientErrors).length > 0) {
        toastr.warning("", clientErrors[Object.keys(clientErrors)[0]]);
        return;
      }

      if (report.editReport.clientInformation?.id) {
        // Call updateClientInformation api
        new Promise(() => {
          const data12: any = dispatch(
            updateClientInformation({
              payload: { ...clientValues, clientRating: rating },
              clientId: report.editReport.clientInformation.id
            })
          );
          data12.then(function (value) {
            if (value.type === "report/updateClientInformation/fulfilled") {
              nextStep(); // Move to next step
              toastr.success("", "Client information updated successfully");
            }
          })
        })
      } else {
        // Call createClientInformation api
        new Promise(() => {
          const data13: any = dispatch(
            createClientInformation({
              payload: { ...clientValues, reportId: report.editReport.report.id, clientRating: rating },
            })
          );
          data13.then(function (value) {
            if (value.type === "report/createClientInformation/fulfilled") {
              saveMaxActiveStep(activeStep + 1);
              nextStep(); // Move to next step
              toastr.success("", "Client information saved successfully");
            }
          })
        })
      }
    } else if (activeStep === 2) {
      if (
        !report.editReport.propertyDetails?.id &&
        Object.keys(propertyTouched).length <= 0
      ) {
        toastr.warning("", "Please first fill the form");
        return;
      }

      if (Object.keys(propertyErrors).length > 0) {
        toastr.warning("", propertyErrors[Object.keys(propertyErrors)[0]]);
        return;
      }

      if (report.editReport.propertyDetails?.id) {
        // Call updatePropertyDetail api
        new Promise(() => {
          const data14: any = dispatch(
            updatePropertyDetail({
              propertyId: report.editReport.propertyDetails.id,
              payload: {
                ...propertyValues,
                yearBuilt: yearBuilt
              },
            })
          );
          data14.then(function (value) {
            if (value.type === "report/updatePropertyDetail/fulfilled") {
              nextStep(); // Move to next step
              toastr.success("", "Property details updated successfully");
            }
          })
        })
      } else {
        // Call createPropertyDetail api
        new Promise(() => {
          const data15: any = dispatch(
            createPropertyDetail({
              payload: {
                ...propertyValues,
                yearBuilt: yearBuilt,
                reportId: report.editReport.report.id,
              },
            })
          );
          data15.then(function (value) {
            if (value.type === "report/createPropertyDetail/fulfilled") {
              saveMaxActiveStep(activeStep + 1);
              nextStep(); // Move to next step
              toastr.success("", "Property details saved successfully");
            }
          })
        })
      }
    } else if (activeStep === 3) {
      if (
        !report.editReport.weatherCondition?.id &&
        Object.keys(weatherTouched).length <= 0
      ) {
        toastr.warning("", "Please first fill the form");
        return;
      }

      if (Object.keys(weatherErrors).length > 0) {
        toastr.warning("", weatherErrors[Object.keys(weatherErrors)[0]]);
        return;
      }

      if (report.editReport.weatherCondition?.id) {
        const temperature = weatherValues.temperature
          ? `${weatherValues.temperature} ${tempunit}`
          : "";

        // Call updateWeatheReport api
        new Promise(() => {
          const data16: any = dispatch(
            updateWeatheReport({
              payload: { ...weatherValues, temperature },
              weatherId: report.editReport.weatherCondition.id,
            })
          );
          data16.then(function (value) {
            if (value.type === "report/updateWeatheReport/fulfilled") {
              nextStep(); // Move to next step
              toastr.success("", "Weather details updated successfully");
            }
          })
        })
      } else {
        const temperature = weatherValues.temperature
          ? `${weatherValues.temperature} ${tempunit}`
          : "";

        // Call createWeatheReport api
        new Promise(() => {
          const data17: any = dispatch(
            createWeatheReport({
              payload: {
                ...weatherValues,
                temperature,
                reportId: report.editReport.report.id,
              },
            })
          );
          data17.then(function (value) {
            if (value.type === "report/createWeatheReport/fulfilled") {
              saveMaxActiveStep(activeStep + 1);
              nextStep(); // Move to next step
              toastr.success("", "Weather details saved successfully");
            }
          })
        })
      }
    } else if (activeStep === 4) {
      new Promise(() => {
        const data18: any = dispatch(
          updateReportAgent({
            reportId: report.editReport.report.id,
            payload: {
              buyerId: selectedAgent.buyer,
              sellerId: selectedAgent.seller,
            },
          })
        );
        data18.then(function (value) {
          if (value.type === "report/updateReportAgent/fulfilled") {
            saveMaxActiveStep(activeStep + 1);
            nextStep(); // Move to next step
            toastr.success("", "Agent saved successfully");
          }
        })
      })
    } else if (activeStep === 5) {
      if (
        report.editReport.paymentDetails.paymentMode === 'Offline Payment' &&
        Object.keys(paymentTouched).length <= 0
      ) {
        nextStep(); // Move to next step
        saveMaxActiveStep(activeStep + 1);
        // setShowInspactionDetails(true); // Hide form & show template
        return;
      }
      if (
        report.editReport.paymentDetails.paymentMode === 'Offline Payment' &&
        Object.keys(paymentTouched).length <= 0
      ) {
        toastr.warning("", "Please first fill the form");
        return;
      }
      if (report.editReport.paymentDetails?.id) { // Call updatePayment api
        new Promise(() => {
          const data18: any = dispatch(
            updatePaymentMode({
              paymentId: report.editReport.paymentDetails.id,
              payload: {
                ...paymentValues,
                accountType: auth.user.SelectAccountType,
                accountId: auth.user.AccountId,
                paymentMode: paymentValues.paymentMode,
                reportId: report.editReport.report.id,
                clientEmail: clientValues.email,
                clientFirstName: clientValues.firstName,
              },
            })
          );
          data18.then(function (value) {
            if (value.type === "report/updatePaymentMode/fulfilled") {
              // nextStep(); // Move to next step
              toastr.success("", "PaymentMode  updated successfully");
            }
          })
        })
      }

      if (report.editReport.paymentDetails?.id) {
        nextStep(); // Move to next step
        saveMaxActiveStep(activeStep + 1);
        setShowInspactionDetails(true); // Hide form & show template
      }
      else {
        if (!clientValues.email || clientValues.email === "") {
          toastr.warning("", "Client email is required for payment");
          return;
        }

        if (!clientValues.firstName || clientValues.firstName === "") {
          toastr.warning("", "Client name is required for payment");
          return;
        }
        if (!paymentValues.paymentMode || paymentValues.paymentMode === "" || paymentValues.paymentMode === "Select") {
          toastr.warning("", "PaymentMode is required for payment");
          return;
        }
        if (!paymentValues.price || paymentValues.price === "") {
          toastr.warning("", "Price is required for payment");
          return;
        }
        if (!paymentValues.selectService || paymentValues.selectService === "") {
          toastr.warning("", "Select Service is required for payment");
          return;
        }
        if (!paymentValues.tax || paymentValues.tax === "") {
          toastr.warning("", "Tax is required for payment");
          return;
        }

        if (Object.keys(paymentErrors).length > 0) {
          toastr.warning("", paymentErrors[Object.keys(paymentErrors)[0]]);
          return;
        }
        if (!Object.values(checkedAgreement).find((x) => x === true)) {
          toastr.warning("", "At least one agreement should be selected.");
          return;
        }
        const agreementUrl = Object.keys(checkedAgreement)
          .filter((x: any) => checkedAgreement[x])
          .reduce((_acc, current, index, array) => {
            return (_acc += `agrId=${current}${index < array.length - 1 ? "&" : ""
              }`);
          }, `${window.location.origin}/agreement-signature/${report.editReport.report.id}?`);

        // Call createPaymentMode api
        new Promise(() => {
          const data19: any = dispatch(
            createPaymentMode({
              payload: {
                ...paymentValues,
                agreementUrl: agreementUrl,
                clientEmail: clientValues.email,
                reportId: report.editReport.report.id,
                clientFirstName: clientValues.firstName,
                totalAmount: (
                  Number(paymentValues.price) +
                  (Number(paymentValues.tax) / 100) * Number(paymentValues.price)
                ).toFixed(2),
                accountId: auth.user.AccountId,
                accountType: auth.user.SelectAccountType
              },
            })
          );
          data19.then(function (value) {
            if (value.type === "report/createPaymentMode/fulfilled") {
              nextStep(); // Move to next step
              saveMaxActiveStep(activeStep + 1);
              // setShowInspactionDetails(true); // Hide form & show template
              // setShowButton(!showButton); // hide save inspection details
              toastr.success(
                "",
                "Payment details saved successfully. We have sent an email to client."
              );
            }
          })
        })
      }
    }
    else if (activeStep === 6) {
      handleWhereToUpdate();
    }
  };

  const handleWhereToUpdate = async () => {
    const { selectedInspectionMethod, selectedNarratives } = report.editReport;
    if (template.saveNarrative === true) {
      // [editMode=false] means they are just created & should be saved in database
      const data = selectedNarratives
        .filter((x: any) => x.checked === true)
        .map((x: any) => {
          return {
            paramId: x,
            editMode: x.editMode ?? false,
            payload: {
              description: report.editReport.narrativeDetails[x.id]?.description,
            },
          };
        });

      // Here we dispatch in map because
      // ... each dispatch also upload multiple images
      // ... and only checked narrative's images upload
      data.forEach(

        (x: any) => x.editMode === false && dispatch(updateNarrative({ paramId: x.paramId.id, payload: { description: x.payload.description } }))
      );
    }

    const narrativeData = selectedNarratives
      // .filter((x: any) => x.checked === true)
      .map((x: any) => {
        return {
          categoryNarrativeId: x.id,
          editMode: x.editMode ?? false,
          reportId: report.editReport.report.id,
          note: report.editReport.narrativeDetails[x.id]?.note,
          description: report.editReport.narrativeDetails[x.id]?.description,
          randomKey: template.randomNarrativeKey.find((r: any) => r.id === x.id)
            .key,
        };
      });
    const inspectionMethodData = selectedInspectionMethod
      .filter((x: any) => x.checked === true)
      .map((x) => {
        return {
          inspectionMethodId: x.id,
          editMode: x.editMode ?? false,
          reportId: report.editReport.report.id,
        };
      });
    new Promise(() => {
      dispatch(createInspectionDetail(narrativeData));
      dispatch(createReportInspectionMethod({ payload: inspectionMethodData }));
    });
    const payload = narrativeData.map((x: any) => x.randomKey).map((key: any) => {
      if (report?.editReport?.narrativeImages[key]?.length) {
        return { key: key, images: report.editReport.narrativeImages[key] }
      }
      return null;
    });
    let payload2: any;
    if (payload) {
      payload2 = payload;
    }
    payload2.map(async (x) => {
      if (x?.images) {
        let values = x?.images?.filter((y) => y.imageId === null);
        x.images = values;
      }
    })
    new Promise(() => {
      const data20: any = dispatch(uploadNarrativeImage({ payload: payload }));
      data20.then(function (value) {
        if (value.type === 'report/uploadNarrativeImage/fulfilled') {
          toastr.success("", "Inspection Details Saved Succesfully");
          navigate(`/report-detail/${params.id}`)
        }
      })
    })
  };

  const addNewAgent = async () => {
    if (Object.keys(agentTouched).length <= 0) {
      toastr.warning("", "Please first fill the form");
      return;
    }

    if (Object.keys(agentErrors).length > 0) {
      toastr.warning("", agentErrors[Object.keys(agentErrors)[0]]);
      return;
    }

    const newAgent = {
      firstName: agentValues.firstName,
      lastName: agentValues.lastName,
      email: agentValues.email,
      phone: agentValues.phone,
      notes: agentValues.notes,
      address: agentValues.address,
      agencyName: agentValues.agencyName,
      agencyNumber: agentValues.agencyNumber,
      userId: auth.user.id,
      // agent_type: agentType
    };

    // Call createAgents api
    new Promise(() => {
      const agentData: any = dispatch(
        createAgents({ payload: { ...newAgent } })
      );
      agentData.then(function (value) {
        if (value.type === "report/createAgents/fulfilled") {
          if (agentImage.img) {
            const agentImageData = new FormData();
            agentImageData.append(
              "image",
              dataURLtoFile(agentImage.img, agentImage.name)
            );
            dispatch(
              uploadAgentImage({
                payload: agentImageData,
                agentId: value.payload.id,
              })
            );
            setAgentImage({ img: null, name: null }); // Reset Agent Image
          }

          closeAddAgent(); // Close addAgent Modal
          toastr.success("", "Agent added successfully");
        }
      })
    });
  };

  // Show agent details in model when open updated modal
  const openUpdateAgentDetails = (agentId: any) => {
    openEditAgent(); // Open edit Modal
    const agent = report.agents.find((x) => x.id === agentId);
    agentSetValues(
      fillFormValues(AGENT_DETAILS_FORM.default, agent, ["id", "image"])
    );
  };

  const updateAgentDetails = () => {
    const newdetails = { ...agentValues };
    const upateAgentDetails = { ...agentValues };
    delete upateAgentDetails.id;
    delete upateAgentDetails.image;

    if (Object.keys(agentErrors).length > 0) {
      toastr.warning("", agentErrors[Object.keys(agentErrors)[0]]);
      return;
    }

    // Call updateAgent api
    new Promise(() => {
      const agentData1: any = dispatch(
        updateAgent({ payload: upateAgentDetails, agentId: newdetails.id })
      );
      agentData1.then(function (value) {
        if (value.type === "report/updateAgent/fulfilled") {
          if (agentImage.img) {
            const agentImageData = new FormData();
            agentImageData.append(
              "image",
              dataURLtoFile(agentImage.img, agentImage.name)
            );
            dispatch(
              uploadAgentImage({ payload: agentImageData, agentId: newdetails.id })
            );
            setAgentImage({ img: null, name: null }); // Reset Agent Image
          }
          closeEditAgent(); // Close editAgent Modal
          toastr.success("", "Agent Updated successfully");
        }
      })
    });
  };

  // Remove selected user agent
  const removeSelectedAgent = (agentId: string) => {
    const agentType = selectedAgent.buyer === agentId ? "buyer" : "seller";
    setSelectedAgent({ ...selectedAgent, [agentType]: "" });
  };

  const {
    isOpen: addAgentDetails,
    onOpen: openAddAgent,
    onClose: closeAddAgent,
  } = useDisclosure();
  const {
    isOpen: editAgentDetails,
    onOpen: openEditAgent,
    onClose: closeEditAgent,
  } = useDisclosure();

  const addChoice = (type: string) => {
    let callback = null;
    let fieldHeading = null;

    if (type === "property_type") {
      dispatch(setFutureTemplate(false));
      fieldHeading = "Property Type";
      // Call createPropertyType api
      callback = async (data: string) => {
        dispatch(
          createPropertyType({
            payload: { [type]: data },
          })
        );
      };
    } else if (type === "property_style") {
      dispatch(setFutureTemplate(false));
      fieldHeading = "Property Style";
      // Call createPropertyStyle api
      callback = async (data: string) => {
        dispatch(
          createPropertyStyle({
            payload: { [type]: data },
          })
        );
      };
    } else if (type === "contruction_type") {
      dispatch(setFutureTemplate(false));
      fieldHeading = "Construction Type";
      // Call createConstructionType api
      callback = async (data: string) => {
        dispatch(
          createConstructionType({
            payload: { [type]: data },
          })
        );
      };
    } else if (type === "orientation_type") {
      fieldHeading = "Orientation Type";
      // Call createOrienationType api
      callback = async (data: string) => {
        dispatch(
          createOrienationType({
            payload: { [type]: data },
          })
        );
      };
    } else if (type === "occupancy_type") {
      fieldHeading = "Occupancy Type";
      // Call createOrienationType api
      callback = async (data: string) => {
        dispatch(
          createOccupancyType({
            payload: { [type]: data },
          })
        );
      };
    }

    setOpenAddChoiceModel(!openAddChoiceModel); // Open Choice Model
    setAddChoiceState({ ...addChoiceState, callback, fieldHeading });
  };


  const editChoice = (type: string) => {
    openShowType();

    if (type === "property_type") {
      setPropertyTypeChoice(report.choice.property_type);
      setPropertyTypeHeader("Property Type")
    }
    else if (type === "property_style") {
      setPropertyTypeChoice(report.choice.property_style);
      setPropertyTypeHeader("Property Style")
    }
    else if (type === "contruction_type") {
      setPropertyTypeChoice(report.choice.contruction_type);
      setPropertyTypeHeader("Construction Type")
    }
  }
  const submitDeleteField = (field: any) => {
    if (field.property_type) {
      dispatch(deletePropertyType({ paramId: field.id }));
    }
    else if (field.property_style) {
      dispatch(deletePropertyStyle({ paramId: field.id }));
    }
    else if (field.contruction_type) {
      dispatch(deleteConstructionType({ paramId: field.id }));
    }

  };

  useEffect(() => {
    if (propertyTypeHeader === "Property Type") {
      setPropertyTypeChoice(report.choice.property_type);
      if (report.choice.property_type?.length === 0) {
        closeShowType();
      }
    }
    else if (propertyTypeHeader === "Property Style") {
      setPropertyTypeChoice(report.choice.property_style);
      if (report.choice.property_style?.length === 0) {
        closeShowType();
      }
    }
    else if (propertyTypeHeader === "Construction Type") {
      setPropertyTypeChoice(report.choice.contruction_type);
      if (report.choice.contruction_type?.length === 0) {
        closeShowType();
      }
    }
  }, [closeShowType, propertyTypeHeader, report.choice.contruction_type, report.choice.property_style, report.choice.property_type])



  // Handle agreement changes
  const handleAgreementModal = (agreement: any) => {
    setOpenAgreementModel(true);
    setSelectedAgreement(agreement);
  };

  const handleSelectAgreement = () => {
    dispatch(setReportAgreement(1))
  }
  const handleChnageReportView = () => {
    if (!showInspactionDetails) {
      if (Object.keys(inspectorErrors).length > 0) {
        toastr.warning("", inspectorErrors[Object.keys(inspectorErrors)[0]]);
        return;
      }

      if (!report.editReport.inspectorDetail?.id) {
        // Call createInspectordetail api
        new Promise(() => {
          const data21: any = dispatch(
            createInspectorDetail({
              payload: { ...inspectorValues, inspectionDate: inspectionDate, inspectionFromTime: inspectionFromTime, inspectionAttendees: inspectorAttendees, inspectionEndTime: inspectionEndTime, isClientAddressSame: checkedClientAddress, },
            })
          );
          data21.then(function (value) {
            if (value.type === "report/createInspectorDetail/fulfilled") {
              submitInspectorDetailImage(value.payload.inspectordetail.id);
            }
          })
        });
      }

      setStep(6);
      saveMaxActiveStep(6);
      setShowInspactionDetails(!showInspactionDetails);
    } else {
      setStep(0);
      setShowInspactionDetails(!showInspactionDetails);
    }
  };

  const handleTemplateCenter = () => {
    if (!report.editReport.inspectorDetail.id) {
      toastr.warning("", "Please Save Inspection Details first!");
      return;
    }

    if (!report.editReport.clientInformation.id) {
      toastr.warning("", "Please Save Client Information first!");
      return;
    }


    if (!store.get('reportId')) {
      store.set('reportId', report.editReport.report.id);
    }

    navigate('/template/template-center');
  }

  useEffect(() => {
    if (auth?.error) {
      const { message } = auth.error;
      toastr.error("", message ? message : "");
    } else if (report?.error) {
      const { message } = report.error;
      toastr.error("", message ? message : "");
    } else if (template?.error) {
      const { message } = template.error;
      toastr.error("", message ? message : "");
    }

    return () => {
      dispatch(setAuthError(null));
      dispatch(setReportError(null));
      dispatch(setTemplateError(null));
    };
  }, [auth.error, dispatch, report.error, template.error]);

  const handleClientAddress = (sameAddress) => {
    setCheckedClientAddress(!checkedClientAddress);
    if (sameAddress) {
      setClientValues({
        firstName: clientValues.firstName,
        lastName: clientValues.lastName,
        email: clientValues.email,
        phone: clientValues.phone,
        address: inspectorValues.inspectionLocation,
        city: inspectorValues.city,
        state: inspectorValues.state,
        country: inspectorValues.country,
        pincode: inspectorValues.zipcode
      })
      setClientAddressDisbled(true);
    }
    else {
      setClientValues({
        firstName: clientValues.firstName,
        lastName: clientValues.lastName,
        email: clientValues.email,
        phone: clientValues.phone,
        address: "",
        city: "",
        state: "",
        country: "",
        pincode: ""
      })
      setClientAddressDisbled(false);
    }
  }
  useEffect(() => {
    if (inspectorValues.inspectionLocation && inspectorValues.city && inspectorValues.state && inspectorValues.country && inspectorValues.zipcode) {
      setCheckDisbled(false);
    } else {
      setCheckDisbled(true);
    }
  }, [inspectorValues.city, inspectorValues.country, inspectorValues.inspectionLocation, inspectorValues.state, inspectorValues.zipcode])

  useEffect(() => {
    if (inspectorAttendees) {
      const clonedInspectorAttendees = [...Object.entries(inspectorAttendees)];
      const selectedValues = clonedInspectorAttendees.filter((x) => x[1] === true);
      if (Object.keys(selectedValues).length === presentAttendes.length - 1) {
        setInspectorAttendees({
          ...inspectorAttendees,
          'All': true
        });
      }
    }
  }, [inspectorAttendees, presentAttendes.length]);

  useEffect(() => {
    if ((/edit-report/gi.test(location.pathname) && report.reportTemplate) || showInspactionDetails) {
      setShowInspactionDetails(true);
      setStep(6);
      saveMaxActiveStep(6);
    }

    return () => { dispatch(setReportTemplateState({ value: false })); setShowInspactionDetails(false); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);


  const handleInspectorAttendees = (x: any, e: any) => {
    if (x === 'All' && inspectorAttendees[x] === true) {
      setInspectorAttendees({
        'All': false,
        'Buyer': false,
        'Buyer Agent': false,
        'Seller': false,
        'Seller Agent': false
      });
    }
    else if (x === 'All') {
      setInspectorAttendees({
        'All': true,
        'Buyer': true,
        'Buyer Agent': true,
        'Seller': true,
        'Seller Agent': true
      });
    }
    else {
      setInspectorAttendees({
        ...inspectorAttendees,
        [x]: e.target.checked,
        'All': false
      });
    }
  }

  useEffect(() => {
    if ((activeStep === 6 && report.editReport.paymentDetails && !showInspactionDetails)) {
      setShowButton(false);
    } else if (activeStep === 5 && report.editReport.paymentDetails.paymentMode === 'Online Payment' && !showInspactionDetails) {
      setShowButton(false);
    }
    else {
      setShowButton(true);
    }
  }, [activeStep, report.editReport.paymentDetails, showInspactionDetails])

  return (
    <>
      {(auth.loginLoading.length !== 0 || report.loading.length !== 0 || template.loading.length !== 0) && <Loader />}
      <AddChoiceModal
        open={openAddChoiceModel}
        setClose={setOpenAddChoiceModel}
        states={addChoiceState}
      />
      <CropImage
        src={image.src}
        name={image.name}
        setCroppedImage={setCroppedImage}
        open={openCropModel}
        setOpenCropModel={setOpenCropModel}
      />
      <Box p="29px 0 0" w="100%">
        <Box
          p="0 20px"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="16px"
        >
          <Box d="inline-flex" alignItems="center">
            {showInspactionDetails && (
              <Button
                mr="2px"
                p="0"
                variant="iconBtn"
                justifyContent="flex-start"
                onClick={handleChnageReportView}
              >
                <Icon
                  as={FiArrowLeft}
                  color="black.lighten"
                  cursor="pointer"
                  fontSize="26px"
                  m="2px 8px 2px 0"
                ></Icon>
              </Button>
            )}
            {activeStep !== 6 && (
              <Text
                as="h1"
                color="black.lighten"
                fontSize="24px"
                lineHeight="30px"
                fontWeight="600"
              >
                Add Details
              </Text>
            )}
            {activeStep === 6 && (
              <Text fontSize="28px" fontWeight="600" color="black.lighten">
                Workspace
              </Text>
            )}
          </Box>
          {showButton && <Box>
            <Button variant="primary2" onClick={formSubmit}>
              {showInspactionDetails ? STEPPER.at(-1) : STEPPER[activeStep]}
            </Button>
          </Box>}
        </Box>
        <Box
          p={showInspactionDetails ? "0 20px 60px" : "29px 20px 60px"}
          h="calc(100vh - 157px)"
          overflow="auto"
        >
          {!showInspactionDetails && (
            <Steps
              className="addReport-stepper"
              orientation="vertical"
              variant="custom"
              onClickStep={(step) => {
                if (/add-report/gi.test(location.pathname)) {
                  if (step <= maxActiveStep || maxActiveStep > 1 || /localhost/gi.test(window.location.hostname)) {
                    setStep(step);
                  }
                } else {
                  setStep(step);
                }
              }}
              activeStep={activeStep}
              p="0"
              display="flex"
              justifyContent="center"
              flex="unset"
            >
              <Step label="Inspection Details" key="login" icon={receipt}>
                <Box p={{ sm: "15px 10px", xxl: "30px 20px" }}>
                  <SimpleGrid
                    columns={{ sm: 2, md: 3 }}
                    spacing={{ sm: "20px 18px", xxl: "39px 30px" }}
                  >
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Report ID
                      </FormLabel>
                      <Input
                        isInvalid={inspectorErrors["reportId"]}
                        w={{ sm: "100%", xxl: "65%" }}
                        maxW="unset"
                        type="text"
                        variant="Bordered"
                        name="reportId"
                        value={inspectorValues.reportId}
                        onChange={inspectorChange}
                      />
                    </FormControl>
                    <Box
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <Text
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Travel Distance
                      </Text>
                      <Flex w={{ sm: "100%", xxl: "65%" }}>
                        <Input
                          w="100%"
                          mr='10px'
                          disabled
                          maxW="unset"
                          type="text"
                          variant="Bordered"
                          name="travelDistance"
                          value={travelDistance}
                          onChange={inspectorChange}
                        />
                        <Tooltip label='Calculate Distance' placement="top">
                          <Image src={refreshIcon} color="gray.primary" onClick={calculateDistance} />
                        </Tooltip>
                      </Flex>
                    </Box>
                    <Box
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <Text
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Inspection Date
                      </Text>
                      <DateTimePicker
                        minDate={new Date(1900, 11, 32)}
                        disableClock={true}
                        className="dateTime-picker inspection-datePicker datepicker-ui"
                        name="inspectionDate"
                        value={inspectionDate}
                        onChange={setInspectionDate}
                        format="MM-dd-yy"
                      />
                    </Box>
                    <SimpleGrid
                      columns={2}
                      spacing={{ sm: "20px 18px", xxl: "39px 30px" }}>
                      <Box
                        d="inline-flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                        overflow='hidden'
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          pr={{ sm: "6px", xxl: "8px" }}
                          textAlign="left"
                        >
                          From
                        </Text>
                        <DateTimePicker
                          disableCalendar={true}
                          disableClock={true}
                          className="dateTime-picker inspection-datePicker date-picker"
                          name="inspectionEndDate"
                          value={inspectionFromTime}
                          onChange={setInspectionFromTime}
                          format="MM-dd-yy hh:mm a"
                        />
                      </Box>
                      <Box
                        d="inline-flex"
                        alignItems="center"
                        justifyContent={{ sm: "space-between", xxl: "flex-end" }}
                        flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                        overflow='hidden'
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          pr={{ sm: "6px", xxl: "32px" }}
                          textAlign="left"
                        >
                          To
                        </Text>
                        <DateTimePicker
                          disableCalendar={true}
                          disableClock={true}
                          className="dateTime-picker inspection-datePicker date-picker"
                          name="inspectionTo"
                          value={inspectionEndTime}
                          onChange={setInspectionEndTime}
                          format="MM-dd-yy hh:mm a"
                        />
                      </Box>
                    </SimpleGrid>
                    <FormControl
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Duration
                      </FormLabel>
                      <Input
                        w={{ sm: "100%", xxl: "65%" }}
                        maxW="unset"
                        type="text"
                        variant="Bordered"
                        value={inspectorValues.Duration}
                        isDisabled={true}
                      />
                    </FormControl>
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Inspector Name
                      </FormLabel>
                      <Input
                        isInvalid={inspectorErrors["inspectorName"]}
                        isDisabled={true}
                        w={{ sm: "100%", xxl: "65%" }}
                        maxW="unset"
                        type="text"
                        variant="Bordered"
                        name="inspectorName"
                        value={inspectorValues.inspectorName}
                        onChange={inspectorChange}
                      />
                    </FormControl>
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "0px" }}
                        textAlign="left"
                      >
                        Inspection Address
                      </FormLabel>
                      <Box
                        position="relative"
                        w="100%"
                        maxW={{ sm: "100%", xxl: "65%" }}
                      >
                        <Input
                          id="autocomplete"
                          isInvalid={
                            inspectorTouched["inspectionLocation"] && inspectorErrors["inspectionLocation"]
                          }
                          maxW="unset"
                          type="text"
                          variant="Bordered"
                          p="0 40px 0 18px"
                          name="inspectionLocation"
                          value={inspectorValues.inspectionLocation}
                          onChange={inspectorChange}
                        />
                      </Box>
                    </FormControl>
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        City
                      </FormLabel>
                      <Box
                        position="relative"
                        w="100%"
                        maxW={{ sm: "100%", xxl: "65%" }}
                      >
                        <Input
                          maxW="unset"
                          type="text"
                          variant="Bordered"
                          name="city"
                          value={inspectorValues.city}
                          onChange={inspectorChange}
                        />
                      </Box>
                    </FormControl>
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        State
                      </FormLabel>
                      <Box
                        position="relative"
                        w="100%"
                        maxW={{ sm: "100%", xxl: "65%" }}
                      >
                        <Input
                          maxW="unset"
                          type="text"
                          variant="Bordered"
                          name="state"
                          value={inspectorValues.state}
                          onChange={inspectorChange}
                        />
                      </Box>
                    </FormControl>
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Country
                      </FormLabel>
                      <Box
                        position="relative"
                        w="100%"
                        maxW={{ sm: "100%", xxl: "65%" }}
                      >
                        <Input
                          maxW="unset"
                          type="text"
                          variant="Bordered"
                          name="country"
                          value={inspectorValues.country}
                          onChange={inspectorChange}
                        />
                      </Box>
                    </FormControl>
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Zip Code
                      </FormLabel>
                      <Box
                        position="relative"
                        w="100%"
                        maxW={{ sm: "100%", xxl: "65%" }}
                      >
                        <Input
                          maxW="unset"
                          type="text"
                          variant="Bordered"
                          name="zipcode"
                          value={inspectorValues.zipcode}
                          onChange={inspectorChange}
                        />
                      </Box>
                    </FormControl>
                  </SimpleGrid>
                  <Flex textAlign='left' mt='20px' flexDirection={{ sm: 'column', xxl: 'row' }}>
                    <Flex flexWrap='wrap'>
                      <Flex alignItems='center' mr='45px' mt='10px'>
                        <Checkbox disabled={checkDisbled} variant='transparentSmall' colorScheme='transparent' isChecked={checkedClientAddress && checkedClientAddress === true} onChange={(e) => handleClientAddress(e.target.checked)} />
                        <Text w='100%' fontSize={{ sm: "16px", xxl: "xxl" }} ml='10px' color="black.lighten">Client address is same as inspection address</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Box
                    d="flex"
                    justifyContent="space-between"
                    mt={{ sm: "20px", xxl: "30px" }}
                  >
                    <Box
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      w={{ sm: "60%", md: "54%" }}
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <Text
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Add Property Image
                      </Text>
                      <SimpleGrid
                        columns={2}
                        spacing={{ sm: "0 20px", xxl: "0 30px" }}
                        w={{ sm: "100%", xxl: "79%" }}
                        d="inline-flex"
                      >
                        <Box
                          position="relative"
                          borderRadius="4px"
                          overflow="hidden"
                          h={{ sm: "170px", lg: "200px" }}
                          w={{ sm: "210px", lg: "260px", xxl: "300px" }}
                          bg={(frontsideImage.img ||
                            report.editReport.inspectorDetail?.frontendAccessUrl) && '#383d4e1c'}
                          d='inline-flex'
                          justifyContent='center'
                        >
                          {!(
                            frontsideImage.img ||
                            report.editReport.inspectorDetail?.frontendAccessUrl
                          ) && (
                              <label style={{ width: '100%' }}>
                                <Box
                                  h="100%"
                                  border="2px dashed"
                                  borderRadius="4px"
                                  borderColor="gray.primary"
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  cursor="pointer"
                                >
                                  <Icon
                                    fontSize="26px"
                                    color="gray.primary"
                                    as={BsFillHouseFill}
                                  ></Icon>
                                  <Text
                                    w="100%"
                                    textAlign="center"
                                    fontSize="xxl"
                                    color="gray.primary"
                                    mt="8px"
                                  >
                                    Add Front image
                                  </Text>
                                  <Input
                                    type="file"
                                    accept=".jepg, .jpg, .png"
                                    name="frontsideImage"
                                    w="0"
                                    h="0"
                                    border="none"
                                    outline="none"
                                    p="0"
                                    _focus={{ outline: "none" }}
                                    onChange={fileChange}
                                  />
                                </Box>
                              </label>
                            )}
                          {(frontsideImage.img ||
                            report.editReport.inspectorDetail?.frontendAccessUrl) && (
                              <>
                                <Image
                                  src={
                                    frontsideImage.img ||
                                    report.editReport.inspectorDetail?.frontendAccessUrl
                                  }
                                  alt=""
                                  h="100%"
                                />
                                <Box
                                  top="15px"
                                  right="15px"
                                  position="absolute"
                                  zIndex={1}
                                  d="inline-flex"
                                >
                                  <Box
                                    w={{ sm: "22px", xxl: "30px" }}
                                    h={{ sm: "25px", xxl: "30px" }}
                                    bg="gray.light7"
                                    borderRadius="4px"
                                    d="inline-flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    cursor="pointer"
                                  >
                                    <label
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Icon
                                        cursor="pointer"
                                        fontSize={{ sm: "14px", xxl: "xxl" }}
                                        color="gray.primary"
                                        as={MdModeEdit}
                                      ></Icon>
                                      <Input
                                        type="file"
                                        accept=".jepg, .jpg, .png"
                                        name="frontsideImage"
                                        w="0"
                                        h="0"
                                        border="none"
                                        outline="none"
                                        p="0"
                                        _focus={{ outline: "none" }}
                                        onChange={fileChange}
                                      />
                                    </label>
                                  </Box>
                                  <Box
                                    w={{ sm: "22px", xxl: "30px" }}
                                    h={{ sm: "25px", xxl: "30px" }}
                                    bg="gray.light7"
                                    borderRadius="4px"
                                    d="inline-flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    cursor="pointer"
                                    ml="10px"
                                    onClick={() =>
                                      deleteInspectorDetailsImage(
                                        "frontendAccessUrl"
                                      )
                                    }
                                  >
                                    <Icon
                                      cursor="pointer"
                                      fontSize={{ sm: "14px", xxl: "xxl" }}
                                      color="gray.primary"
                                      as={MdDelete}
                                    ></Icon>
                                  </Box>
                                </Box>
                              </>
                            )}
                        </Box>
                        <Box
                          position="relative"
                          borderRadius="4px"
                          overflow="hidden"
                          h={{ sm: "170px", lg: "200px" }}
                          w={{ sm: "210px", lg: "260px", xxl: "300px" }}
                          bg={(backsideImage.img ||
                            report.editReport.inspectorDetail?.backendAccessUrl) && '#383d4e1c'}
                          d='inline-flex'
                          justifyContent='center'
                        >
                          {!(
                            backsideImage.img ||
                            report.editReport.inspectorDetail?.backendAccessUrl
                          ) && (
                              <label style={{ width: '100%' }}>
                                <Box
                                  h="100%"
                                  border="2px dashed"
                                  borderRadius="4px"
                                  borderColor="gray.primary"
                                  d="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  cursor="pointer"
                                >
                                  <Icon
                                    fontSize="26px"
                                    color="gray.primary"
                                    as={BsFillHouseFill}
                                  ></Icon>
                                  <Text
                                    w="100%"
                                    textAlign="center"
                                    fontSize="xxl"
                                    color="gray.primary"
                                    mt="8px"
                                  >
                                    Add Back image
                                  </Text>
                                  <Input
                                    type="file"
                                    accept=".jepg, .jpg, .png"
                                    name="backsideImage"
                                    w="0"
                                    h="0"
                                    border="none"
                                    outline="none"
                                    p="0"
                                    _focus={{ outline: "none" }}
                                    onChange={fileChange}
                                  />
                                </Box>
                              </label>
                            )}
                          {(backsideImage.img ||
                            report.editReport.inspectorDetail
                              ?.backendAccessUrl) && (
                              <>
                                <Image
                                  src={
                                    backsideImage.img ||
                                    report.editReport.inspectorDetail
                                      ?.backendAccessUrl
                                  }
                                  alt=""
                                  h="100%"
                                />
                                <Box
                                  top="15px"
                                  right="15px"
                                  position="absolute"
                                  zIndex={1}
                                  d="inline-flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  cursor="pointer"
                                >
                                  <Box
                                    w={{ sm: "22px", xxl: "30px" }}
                                    h={{ sm: "25px", xxl: "30px" }}
                                    bg="gray.light7"
                                    borderRadius="4px"
                                    d="inline-flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    cursor="pointer"
                                  >
                                    <label
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Icon
                                        cursor="pointer"
                                        fontSize={{ sm: "14px", xxl: "xxl" }}
                                        color="gray.primary"
                                        as={MdModeEdit}
                                      ></Icon>
                                      <Input
                                        type="file"
                                        accept="image/*"
                                        name="backsideImage"
                                        w="0"
                                        h="0"
                                        border="none"
                                        outline="none"
                                        p="0"
                                        _focus={{ outline: "none" }}
                                        onChange={fileChange}
                                      />
                                    </label>
                                  </Box>
                                  <Box
                                    w={{ sm: "22px", xxl: "30px" }}
                                    h={{ sm: "25px", xxl: "30px" }}
                                    bg="gray.light7"
                                    borderRadius="4px"
                                    d="inline-flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    cursor="pointer"
                                    ml="10px"
                                    onClick={() =>
                                      deleteInspectorDetailsImage("backendAccessUrl")
                                    }
                                  >
                                    <Icon
                                      cursor="pointer"
                                      fontSize={{ sm: "14px", xxl: "xxl" }}
                                      color="gray.primary"
                                      as={MdDelete}
                                    ></Icon>
                                  </Box>
                                </Box>
                              </>
                            )}
                        </Box>
                      </SimpleGrid>
                    </Box>
                    <Box
                      w={{ sm: "30%", md: "32%" }}
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <Text
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Inspector Image
                      </Text>
                      <Box
                        border={
                          !(inspectorImage.img || auth.user?.profileImage)
                            ? "2px dashed"
                            : ""
                        }
                        bg={
                          inspectorImage.img || auth.user?.profileImage
                            ? "gray.light2"
                            : ""
                        }
                        borderColor="gray.primary"
                        pos="relative"
                        overflow="hidden"
                        w={{ sm: "100%", xxl: "300px" }}
                        borderRadius="4px"
                        h={{ sm: "170px", lg: "200px" }}
                        d="inline-flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box w="100%" d='inline-flex' alignItems='center' justifyContent='center'>
                          {inspectorImage.img || auth.user?.AccessProfileUrl ? (
                            <>
                              <Image
                                src={
                                  inspectorImage.img || auth.user?.AccessProfileUrl
                                }
                                alt=""
                                h="200px"
                                maxH='200px'
                              />
                              <Box
                                top="15px"
                                right="15px"
                                position="absolute"
                                zIndex={1}
                                d="inline-flex"
                              >
                                <label>
                                  <Box
                                    w={{ sm: "22px", xxl: "30px" }}
                                    h={{ sm: "25px", xxl: "30px" }}
                                    bg="white.primary"
                                    borderRadius="4px"
                                    d="inline-flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    cursor="pointer"
                                  >
                                    <Icon
                                      cursor="pointer"
                                      fontSize={{ sm: "14px", xxl: "xxl" }}
                                      color="gray.primary"
                                      as={MdModeEdit}
                                    ></Icon>
                                    <Input
                                      type="file"
                                      accept="image/*"
                                      name="inspectorImage"
                                      id="inspectorImage"
                                      h="0"
                                      w="0"
                                      border="none"
                                      outline="none"
                                      p="0"
                                      _focus={{ outline: "none" }}
                                      onChange={fileChange}
                                    />
                                  </Box>
                                </label>
                              </Box>
                            </>
                          ) : (
                            <label>
                              <Box
                                d="inline-flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                w="100%"
                                cursor="pointer"
                              >
                                <Icon
                                  fontSize="22px"
                                  color="gray.primary"
                                  as={MdPhotoCamera}
                                ></Icon>
                                <Text
                                  w="100%"
                                  textAlign="center"
                                  fontSize="xxl"
                                  color="gray.primary"
                                  mt="8px"
                                >
                                  Add Image
                                </Text>
                                <Input
                                  type="file"
                                  accept="image/*"
                                  name="inspectorImage"
                                  id="inspectorImage"
                                  h="0"
                                  w="0"
                                  border="none"
                                  outline="none"
                                  p="0"
                                  _focus={{ outline: "none" }}
                                  onChange={fileChange}
                                />
                              </Box>
                            </label>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Flex textAlign='left' mt='20px' flexDirection={{ sm: 'column', xxl: 'row' }}>
                    <Text maxW={{ sm: '100%', xxl: '171px' }} w='100%' fontSize={{ sm: "16px", xxl: "xxl" }} mb={{ sm: "4px", xxl: "0" }} color="black.lighten">Present at time of Inspection</Text>
                    <Flex flexWrap='wrap'>
                      {presentAttendes.map((x: string, id: number) => (
                        <Flex key={id} alignItems='center' mr='45px' mt='10px'>
                          <Checkbox variant='transparentSmall' colorScheme='transparent' isChecked={inspectorAttendees && inspectorAttendees[x] === true} onChange={(e) => handleInspectorAttendees(x, e)} />
                          <Text ml='5px' fontSize={{ sm: "16px", xxl: "xxl" }}>{x}</Text>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </Box>
              </Step>
              <Step
                className="client-info-step"
                label="Client Information"
                key="client-info"
                icon={IoMdInformationCircleOutline}
              >
                <SimpleGrid
                  columns={{ sm: 2, md: 3 }}
                  spacing={{ sm: "20px 18px", xxl: "39px 30px" }}
                  p={{ sm: "15px 10px", xxl: "30px 20px" }}
                >
                  <FormControl
                    isRequired
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <FormLabel
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      First Name
                    </FormLabel>
                    <Input
                      isInvalid={
                        clientTouched["firstName"] && clientErrors["firstName"]
                      }
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="firstName"
                      value={clientValues.firstName}
                      onChange={clientChange}
                    />
                  </FormControl>
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Last Name
                    </Text>
                    <Input
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="lastName"
                      value={clientValues.lastName}
                      onChange={clientChange}
                    />
                  </Box>
                  <FormControl
                    isRequired
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <FormLabel
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Email ID
                    </FormLabel>
                    <Input
                      isInvalid={
                        clientTouched["email"] && clientErrors["email"]
                      }
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="email"
                      value={clientValues.email}
                      onChange={clientChange}
                    />
                  </FormControl>
                  <FormControl
                    isRequired
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <FormLabel
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Phone No
                    </FormLabel>
                    <Input
                      isInvalid={
                        clientTouched["phone"] && clientErrors["phone"]
                      }
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="phone"
                      value={clientValues.phone}
                      onChange={clientChange}
                    />
                  </FormControl>
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Address
                    </Text>
                    <Input
                      disabled={clientAddressDisbled}
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="address"
                      value={clientValues.address}
                      onChange={clientChange}
                    />
                  </Box>
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      City
                    </Text>
                    <Input
                      disabled={clientAddressDisbled}
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="city"
                      value={clientValues.city}
                      onChange={clientChange}
                    />
                  </Box>
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      State
                    </Text>
                    <Input
                      disabled={clientAddressDisbled}
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="state"
                      value={clientValues.state}
                      onChange={clientChange}
                    />
                  </Box>
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Country
                    </Text>
                    <Input
                      disabled={clientAddressDisbled}
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="country"
                      value={clientValues.country}
                      onChange={clientChange}
                    />
                  </Box>
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Zip Code
                    </Text>
                    <Input
                      disabled={clientAddressDisbled}
                      w={{ sm: "100%", xxl: "72%" }}
                      maxW="unset"
                      type="text"
                      variant="Bordered"
                      name="pincode"
                      value={clientValues.pincode}
                      onChange={clientChange}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mb={{ sm: "10px", xxl: "0" }}
                    justifyContent="flex-start"
                    flexWrap="wrap"
                  >
                    <Box
                      w={{ sm: "100%", xxl: "24%" }}
                      d="inline-flex"
                      alignItems="center"
                    >
                      <Text
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Rate the client
                      </Text>
                    </Box>
                    <Box
                      w={{ sm: "100%", xxl: "72%" }}
                      display="inline-flex"
                      justifyContent="flex-start"
                      minH={{ sm: "40px", xxl: "unset" }}
                      alignItems={{ sm: "flex-end", xxl: "center" }}
                      ml="auto"
                    >
                      <StarRatings
                        rating={rating}
                        starEmptyColor="#888888"
                        starRatedColor="#f89d2c"
                        starHoverColor="#f89d2c"
                        changeRating={changeRating}
                        numberOfStars={5}
                        starDimension="24px"
                        starSpacing="4px"
                      />
                    </Box>
                  </Box>
                </SimpleGrid>
              </Step>
              <Step
                className="property-details"
                label="Property Details"
                key="property-details"
                icon={property}
              >
                <Box p={{ sm: "15px 10px", xxl: "30px 20px" }}>
                  <Box d="flex" justifyContent="flex-start" flexWrap="wrap">
                    <Box
                      w="100%"
                      d="inline-flex"
                      justifyContent="flex-start"
                      flexDirection="column"
                    >
                      <SimpleGrid
                        columns={{ sm: 2, lg: 4 }}
                        spacing={{ sm: "10px", xxl: "0 40px" }}
                        mt={{ sm: "20px", xxl: "30px" }}
                        w={{ sm: "100%", xxl: "90%" }}
                        ml="auto"
                      >
                        <AddImagePlaceholder
                          h="200px"
                          fileChange={fileChange}
                          name="propertyImage"
                          multiple={true}
                        />
                        {report.editReport.propertyImages?.length > 0 && (
                          <Box
                            bg="#383d4e1c"
                            borderRadius="4px"
                            h="200px"
                            overflow="hidden"
                            position="relative"
                            display="inline-flex"
                            justifyContent="center"
                          >
                            <Image
                              h="100%"
                              src={
                                report.editReport.propertyImages?.at(-1)
                                  .accessImageUrl
                              }
                              alt=""
                            />
                          </Box>
                        )}
                        {report.editReport.propertyImages?.length > 1 && (
                          <Box
                            bg="#383d4e1c"
                            borderRadius="4px"
                            h="200px"
                            overflow="hidden"
                            position="relative"
                            display="inline-flex"
                            justifyContent="center"
                          >
                            <Image
                              h="100%"
                              src={
                                report.editReport.propertyImages?.at(-2)
                                  .accessImageUrl
                              }
                              alt=""
                            />
                          </Box>
                        )}
                        {report.editReport.propertyImages?.length > 2 && (
                          <Box
                            bg="#383d4e1c"
                            borderRadius="4px"
                            h="200px"
                            overflow="hidden"
                            position="relative"
                            display="inline-flex"
                            justifyContent="center"
                          >
                            <Image
                              h="100%"
                              src={
                                report.editReport.propertyImages?.at(-3)
                                  .accessImageUrl
                              }
                              alt=""
                            />
                            <Box
                              position="absolute"
                              left="0"
                              top="0"
                              right="0"
                              bottom="0"
                              bg={
                                report.editReport.propertyImages?.length > 3
                                  ? `black.lighten3`
                                  : ""
                              }
                              d="inline-flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {report.editReport.propertyImages?.length > 3 && (
                                <Text fontSize="60px" color="white.primary">
                                  +
                                  {report.editReport.propertyImages?.length - 2}
                                </Text>
                              )}
                            </Box>
                          </Box>
                        )}
                      </SimpleGrid>
                    </Box>
                  </Box>
                  <Box
                    d="flex"
                    alignItems="center"
                    mt={{ sm: "20px", xxl: "30px" }}
                    justifyContent="flex-start"
                    flexWrap="wrap"
                  >
                    <Box
                      w={{ sm: "48%", lg: "calc(50% - 20px)" }}
                      textAlign="left"
                      d="flex"
                      mr="20px"
                      flexWrap="wrap"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "22%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          d="inline-block"
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          pr={{ sm: "6px", xxl: "8px" }}
                          textAlign="left"
                        >
                          Property Type
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 95px)", xxl: "calc(78% - 320px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Select
                          w="100%"
                          variant="Simple"
                          bg="transparent"
                          name="propertyType"
                          placeholder="Select"
                          value={propertyValues.propertyType}
                          onChange={propertyChange}
                        >
                          {report?.choice?.property_type?.map((x: any) => {
                            return (
                              <option key={x.id} value={x?.property_type}>
                                {x?.property_type}
                              </option>
                            );
                          })}
                          <option value="Multi-Story">Multi-Story</option>
                          <option value="2 Story">2 Story</option>
                          <option value="Ranch">Ranch</option>
                        </Select>
                      </Box>
                      <Button
                        variant="transparent"
                        ml={{ sm: "6px", xxl: "22px" }}
                        height="48px"
                        onClick={() => addChoice("property_type")}
                      >
                        <Icon
                          fontSize="25px"
                          color="gray.primary"
                          mr="12px"
                          as={IoIosAddCircle}
                        ></Icon>
                        <Text d={{ sm: "none", xxl: "inline-block" }}>
                          Add Choice
                        </Text>
                      </Button>
                      {(report.choice.property_type?.length !== 0) &&
                        <Button
                          variant="transparent"
                          ml={{ sm: "6px", xxl: "22px" }}
                          height="48px"
                          onClick={() => editChoice("property_type")}
                        >
                          <Icon
                            fontSize="25px"
                            color="gray.primary"
                            mr="12px"
                            as={IoIosRemoveCircle}
                          ></Icon>
                          <Text d={{ sm: "none", xxl: "inline-block" }}>
                            Delete Choice
                          </Text>
                        </Button>
                      }
                    </Box>
                    <Box
                      w={{ sm: "48%", lg: "50%" }}
                      textAlign="left"
                      d="flex"
                      flexWrap="wrap"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "22%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          mr="30px"
                        >
                          Property Style
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 95px)", xxl: "calc(78% - 320px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Select
                          w="100%"
                          variant="Simple"
                          bg="transparent"
                          name="propertyStyle"
                          placeholder="Select"
                          value={propertyValues.propertyStyle}
                          onChange={propertyChange}
                        >
                          {report?.choice?.property_style?.map((x: any) => {
                            return (
                              <option key={x.id} value={x?.property_style}>
                                {x?.property_style}
                              </option>
                            );
                          })}
                          <option value="Modern">Modern</option>
                          <option value="Contemporary">Contemporary</option>
                        </Select>
                      </Box>
                      <Button
                        variant="transparent"
                        ml={{ sm: "6px", xxl: "22px" }}
                        height="48px"
                        onClick={() => addChoice("property_style")}
                      >
                        <Icon
                          fontSize="25px"
                          color="gray.primary"
                          mr="12px"
                          as={IoIosAddCircle}
                        ></Icon>
                        <Text d={{ sm: "none", xxl: "inline-block" }}>
                          Add Choice
                        </Text>
                      </Button>
                      {(report.choice.property_style?.length !== 0) &&
                        <Button
                          variant="transparent"
                          ml={{ sm: "6px", xxl: "22px" }}
                          height="48px"
                          onClick={() => editChoice("property_style")}
                        >
                          <Icon
                            fontSize="25px"
                            color="gray.primary"
                            mr="12px"
                            as={IoIosRemoveCircle}
                          ></Icon>
                          <Text d={{ sm: "none", xxl: "inline-block" }}>
                            Delete Choice
                          </Text>
                        </Button>
                      }
                    </Box>
                  </Box>
                  <Box
                    d="flex"
                    alignItems="center"
                    mt={{ sm: "20px", xxl: "30px" }}
                    justifyContent="flex-start"
                  >
                    <Box
                      w={{ sm: "48%", lg: "calc(50% - 20px)" }}
                      textAlign="left"
                      d="flex"
                      flexWrap="wrap"
                      mr="20px"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "22%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          pr={{ sm: "6px", xxl: "8px" }}
                          textAlign="left"
                        >
                          Construction Type
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 95px)", xxl: "calc(78% - 320px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Select
                          w="100%"
                          variant="Simple"
                          bg="transparent"
                          name="constructiontype"
                          placeholder="Select"
                          value={propertyValues.constructiontype}
                          onChange={propertyChange}
                        >
                          {report?.choice?.contruction_type?.map((x: any) => {
                            return (
                              <option key={x.id} value={x?.contruction_type}>
                                {x?.contruction_type}
                              </option>
                            );
                          })}
                          <option value="CBS">CBS</option>
                          <option value="Wood Frame">Wood Frame</option>
                        </Select>
                      </Box>
                      <Button
                        variant="transparent"
                        ml={{ sm: "6px", xxl: "22px" }}
                        height="48px"
                        onClick={() => addChoice("contruction_type")}
                      >
                        <Icon
                          fontSize="25px"
                          color="gray.primary"
                          mr="12px"
                          as={IoIosAddCircle}
                        ></Icon>
                        <Text d={{ sm: "none", xxl: "inline-block" }}>
                          Add Choice
                        </Text>
                      </Button>
                      {(report.choice.contruction_type?.length !== 0) &&
                        <Button
                          variant="transparent"
                          ml={{ sm: "6px", xxl: "22px" }}
                          height="48px"
                          onClick={() => editChoice("contruction_type")}
                        >
                          <Icon
                            fontSize="25px"
                            color="gray.primary"
                            mr="12px"
                            as={IoIosRemoveCircle}
                          ></Icon>
                          <Text d={{ sm: "none", xxl: "inline-block" }}>
                            Delete Choice
                          </Text>
                        </Button>
                      }
                    </Box>
                    <Box
                      w={{ sm: "48%", lg: "50%" }}
                      textAlign="left"
                      d="flex"
                      flexWrap="wrap"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "22%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          mr="30px"
                        >
                          Year Built
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 95px)", xxl: "calc(78% - 320px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Box w="100%" position="relative">
                          <Input
                            maxW="unset"
                            w="100%"
                            variant="Bordered"
                            name="yearBuilt"
                            value={yearBuilt}
                            onChange={(e) => { changeYearBuilt(e.target.value) }}
                            pr='45px'
                            type='number'
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    d="flex"
                    alignItems="center"
                    mt={{ sm: "20px", xxl: "30px" }}
                    justifyContent="flex-start"
                  >
                    <Box
                      w={{ sm: "48%", lg: "calc(50% - 20px)" }}
                      textAlign="left"
                      d="flex"
                      mr="20px"
                      flexWrap="wrap"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "24%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          pr={{ sm: "6px", xxl: "8px" }}
                          textAlign="left"
                        >
                          Property Size
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 46px)", xxl: "calc(78% - 113px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Box
                          w="100%"
                          d="inline-flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          pos='relative'
                        >
                          <Input
                            maxW="unset"
                            w="100%"
                            variant="Bordered"
                            name="area"
                            value={propertyValues.area}
                            onChange={propertyChange}
                            pr='45px'
                          />
                          <Text as='span' pos='absolute' right='0' p='0 13px'>sqft</Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={{ sm: "48%", lg: "50%" }}
                      textAlign="left"
                      d="flex"
                      flexWrap="wrap"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "24%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          mr="30px"
                        >
                          Orientation
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 46px)", xxl: "calc(78% - 113px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Select
                          variant="Simple"
                          bg="transparent"
                          w="100%"
                          name="orientation"
                          placeholder="Select"
                          value={propertyValues.orientation}
                          onChange={propertyChange}
                        >
                          <option value="North">North</option>
                          <option value="South">South</option>
                          <option value="West">West</option>
                          <option value="East">East</option>
                          <option value="North East">North East</option>
                          <option value="North West">North West</option>
                          <option value="South East">South East</option>
                          <option value="South West">South West</option>
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    d="flex"
                    alignItems="center"
                    mt={{ sm: "20px", xxl: "30px" }}
                    justifyContent="flex-start"
                  >
                    <Box
                      w={{ sm: "48%", lg: "calc(50% - 20px)" }}
                      textAlign="left"
                      d="flex"
                      mr="20px"
                      flexWrap="wrap"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "24%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          pr={{ sm: "6px", xxl: "8px" }}
                          textAlign="left"
                        >
                          Occupancy
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 46px)", xxl: "calc(78% - 113px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Select
                          variant="Simple"
                          bg="transparent"
                          w="100%"
                          name="occupancy"
                          placeholder="Select"
                          value={propertyValues.occupancy}
                          onChange={propertyChange}
                        >
                          <option value="Occupied">Occupied</option>
                          <option value="Unoccupied">Unoccupied</option>
                        </Select>
                      </Box>
                    </Box>
                    <Box
                      w={{ sm: "48%", lg: "50%" }}
                      textAlign="left"
                      d="flex"
                      flexWrap="wrap"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "24%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          mr="30px"
                        >
                          Furnished
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 46px)", xxl: "calc(78% - 113px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Select
                          variant="Simple"
                          bg="transparent"
                          w="100%"
                          name="furnished"
                          placeholder="Select"
                          value={propertyValues.furnished}
                          onChange={propertyChange}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    d="flex"
                    alignItems="center"
                    mt={{ sm: "20px", xxl: "30px" }}
                    justifyContent="flex-start"
                  >
                    <Box
                      w={{ sm: "48%", lg: "calc(50% - 20px)" }}
                      textAlign="left"
                      d="flex"
                      mr="20px"
                      flexWrap="wrap"
                    >
                      <Box
                        w={{ sm: "100%", xxl: "24%" }}
                        d="inline-flex"
                        alignItems="center"
                      >
                        <Text
                          w={{ sm: "100%", xxl: "auto" }}
                          as="label"
                          fontSize={{ sm: "16px", xxl: "xxl" }}
                          mb={{ sm: "4px", xxl: "0" }}
                          color="black.lighten"
                          pr={{ sm: "6px", xxl: "8px" }}
                          textAlign="left"
                        >
                          Utilities
                        </Text>
                      </Box>
                      <Box
                        w={{ sm: "calc(100% - 46px)", xxl: "calc(78% - 113px)" }}
                        d="inline-flex"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Select
                          variant="Simple"
                          bg="transparent"
                          w="100%"
                          name="utility"
                          value={propertyValues.utility}
                          placeholder="Select"
                          onChange={propertyChange}
                        >
                          <option value="On">On</option>
                          <option value="Off">Off</option>
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Step>
              <Step
                className="weather-conditions"
                label="Weather Condition"
                key="weather-conditions"
                icon={RiTempHotFill}
              >
                <SimpleGrid
                  columns={{ sm: 2, md: 3 }}
                  spacing={{ sm: "20px 18px", xxl: "39px 30px" }}
                  p="30px 20px"
                >
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Climate
                    </Text>
                    <Box
                      position="relative"
                      w="100%"
                      maxW={{ sm: "100%", xxl: "70%" }}
                    >
                      <Select
                        variant="Simple"
                        bg="transparent"
                        w="100%"
                        name="climate"
                        placeholder="Select"
                        value={weatherValues.climate}
                        onChange={weatherChange}
                      >
                        <option value="Clear & Dry">Clear & Dry</option>
                        <option value="Cloudy">Cloudy</option>
                        <option value="Partially Cloudy">
                          Partially Cloudy
                        </option>
                        <option value="Currently Raining">
                          Currently Raining
                        </option>
                        <option value="Recent Rain">
                          Recent Rain
                        </option>
                      </Select>
                    </Box>
                  </Box>
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Temperature
                    </Text>
                    <Box
                      position="relative"
                      w="100%"
                      maxW={{ sm: "100%", xxl: "70%" }}
                    >
                      <Input
                        maxW="unset"
                        type="number"
                        variant="Bordered"
                        name="temperature"
                        value={
                          weatherValues.temperature
                            ? weatherValues.temperature.split(" ")[0]
                            : ""
                        }
                        onChange={weatherChange}
                      />
                    </Box>
                  </Box>
                  <Box
                    d="inline-flex"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Box
                      w={{ sm: "100%", xxl: "80%" }}
                      d="inline-flex"
                      justifyContent="flex-start"
                      pl={{ sm: "0", xxl: "30px" }}
                    >
                      <label className="toggle-switch">
                        <input
                          type="checkbox"
                          name="tempunit"
                          onChange={(e) =>
                            setTempunit(e.target.checked ? "f" : "c")
                          }
                          checked={tempunit === "f"}
                        />
                        <span className="slider"></span>
                        <span className="celcius">
                          {weatherValues.temperature
                            ? weatherValues.temperature.split(" ")[0]
                            : "0"}{" "}
                          <sup>°C</sup>
                        </span>
                        <span className="fahrenheit">
                          {weatherValues.temperature
                            ? weatherValues.temperature.split(" ")[0]
                            : "0"}{" "}
                          <sup>°F</sup>
                        </span>
                      </label>
                    </Box>
                  </Box>
                  <Box
                    d="inline-flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                  >
                    <Text
                      w={{ sm: "100%", xxl: "auto" }}
                      as="label"
                      fontSize={{ sm: "16px", xxl: "xxl" }}
                      mb={{ sm: "4px", xxl: "0" }}
                      color="black.lighten"
                      pr={{ sm: "6px", xxl: "8px" }}
                      textAlign="left"
                    >
                      Humidity
                    </Text>
                    <Box
                      position="relative"
                      w="100%"
                      maxW={{ sm: "100%", xxl: "70%" }}
                    >
                      <Select
                        variant="Simple"
                        bg="transparent"
                        w="100%"
                        name="humidity"
                        placeholder="Select"
                        value={weatherValues.humidity}
                        onChange={weatherChange}
                      >
                        <option value="0% to 25%">0% to 25%</option>
                        <option value="25% to 50%">25% to 50%</option>
                        <option value="50% to 75%">50% to 75%</option>
                      </Select>
                    </Box>
                  </Box>
                </SimpleGrid>
              </Step>
              <Step label="Agent Details" key="agent-details" icon={FaUser}>
                <Box p={{ sm: "15px 10px", xxl: "30px 20px" }}>
                  <SimpleGrid
                    columns={{ sm: 2, md: 3 }}
                    spacing={{ sm: "20px 18px", xxl: "39px 30px" }}
                  >
                    <Box
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <Text
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Buyer Agent
                      </Text>
                      <Select
                        variant="Simple"
                        bg="transparent"
                        w={{ sm: "100%", xxl: "75%" }}
                        placeholder="Select"
                        name="buyer"
                        value={selectedAgent.buyer}
                        onChange={(e) =>
                          setSelectedAgent({
                            ...selectedAgent,
                            buyer: e.target.value,
                          })
                        }
                      >
                        {report.agents?.map((agent: any) => {
                          return (
                            <option
                              key={agent.id}
                              value={agent.id}
                            >{`${agent.firstName} ${agent.lastName}`}</option>
                          );
                        })}
                      </Select>
                    </Box>
                    <Box
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <Text
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Seller Agent
                      </Text>
                      <Box
                        w={{ sm: "100%", xxl: "75%" }}
                        pos="relative"
                        zIndex={2}
                      >
                        <Select
                          variant="Simple"
                          bg="transparent"
                          w={{ sm: "100%", xxl: "75%" }}
                          placeholder="Select"
                          name="seller"
                          value={selectedAgent.seller}
                          onChange={(e) =>
                            setSelectedAgent({
                              ...selectedAgent,
                              seller: e.target.value,
                            })
                          }
                        >
                          {report.agents?.map((agent: any) => {
                            return (
                              <option
                                key={agent.id}
                                value={agent.id}
                              >{`${agent.firstName} ${agent.lastName}`}</option>
                            );
                          })}
                        </Select>
                      </Box>
                    </Box>
                    <Box d="inline-flex" alignItems="flex-end">
                      <Button
                        justifyContent="flex-start"
                        variant="transparent"
                        ml="10px"
                        mb="6px"
                        onClick={() => {
                          agentReset();
                          openAddAgent();
                        }}
                      >
                        <Icon
                          fontSize="25px"
                          color="gray.primary"
                          mr="12px"
                          as={IoIosAddCircle}
                        ></Icon>
                        Add Agent
                      </Button>
                    </Box>
                  </SimpleGrid>
                  <Box
                    mt={{ sm: "10px", lg: "24px" }}
                    d="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    {[selectedAgent.buyer, selectedAgent.seller].map(
                      (agentId: any, index) => {
                        const agent = report.agents?.find(
                          (a: any) => a.id === Number(agentId)
                        );

                        return agentId ? (
                          <Box
                            key={index + Math.random()}
                            p="20px 25px 20px 20px"
                            boxShadow="0px 0px 3px #D9F4FF"
                            borderRadius="4px"
                            d="inline-flex"
                            w="100%"
                            maxW={{ sm: "366px", xxl: "462px" }}
                            mr="10px"
                            bg="white.lighten5"
                          >
                            <Box
                              borderRadius="50%"
                              minW={{ sm: "110px", xxl: "141px" }}
                              w={{ sm: "110px", xxl: "141px" }}
                              h={{ sm: "110px", xxl: "141px" }}
                              mr="20px"
                            >
                              <Avatar src={agent?.accessImageUrl} h="100%" w="100%" overflow="hidden" css={css({
                                '.chakra-avatar__img': {
                                  borderRadius: '0',
                                  width: 'auto',
                                  height: 'auto',
                                  maxHeight: '100%'
                                }
                              })} />
                            </Box>
                            <Box
                              textAlign="left"
                              w={{
                                sm: "calc(100% - 115px)",
                                xxl: "calc(100% - 161px)",
                              }}
                            >
                              <Box
                                d="flex"
                                justifyContent="space-between"
                                mb="6px"
                                alignItems="center"
                              >
                                <Text
                                  fontSize="21px"
                                  color="black.lighten"
                                  fontWeight="600"
                                  pr="10px"
                                >{`${agent?.firstName} ${agent?.lastName}`}</Text>
                                <Icon
                                  fontSize="24px"
                                  as={MdClose}
                                  onClick={() =>
                                    removeSelectedAgent(String(agent?.id))
                                  }
                                ></Icon>
                              </Box>
                              <Text
                                fontSize={{ sm: "15px", xxl: "xxl" }}
                                color="black.lighten"
                                mb="6px"
                              >
                                {agent?.phone}
                              </Text>
                              <Text
                                fontSize={{ sm: "15px", xxl: "xxl" }}
                                color="black.lighten"
                                mb={{ sm: "10px", xxl: "14px" }}
                              >
                                {agent?.gmail}
                              </Text>
                              <Button
                                variant="primaryExtraSmall"
                                onClick={() => openUpdateAgentDetails(agent?.id)}
                              >
                                Edit Details
                              </Button>
                            </Box>
                          </Box>
                        ) : null;
                      }
                    )}
                  </Box>
                </Box>
              </Step>
              <Step
                label="Payment"
                className="weather-conditions"
                icon={MdOutlinePayments}
              >
                <Box p={{ sm: "15px 10px", xxl: "30px 20px" }}>
                  <SimpleGrid
                    columns={{ sm: 2, md: 3 }}
                    spacing={{ sm: "20px 18px", xxl: "39px 30px" }}
                  >
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Payment Mode
                      </FormLabel>
                      <Select
                        variant="Simple"
                        w={{ sm: "100%", xxl: "70%" }}
                        maxW="unset"
                        placeholder="Select"
                        name="paymentMode"
                        value={paymentValues.paymentMode}
                        onChange={(e) => {
                          paymentChange(e);
                          if (e.target.value === 'Online Payment' && !auth.user.SelectAccountType) {
                            toastr.warning("", "Please select a payment method first to set the payment mode Online");
                            setPaymentValues({ ...paymentValues, paymentMode: 'Select' });
                          }
                        }}
                        disabled={Object.keys(report.editReport.paymentDetails).length && report.editReport.paymentDetails.paymentMode === 'Online Payment'}
                      >
                        <option value="Online Payment">Online Payment</option>
                        <option value="Offline Payment">Offline Payment</option>
                      </Select>
                    </FormControl>
                    <FormControl
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Duration
                      </FormLabel>


                      <Input
                        type="text"
                        w={{ sm: "100%", xxl: "70%" }}
                        maxW="unset"
                        variant="Bordered"
                        name="duration"
                        value={inspectorValues.Duration}
                        isDisabled={true}
                        onChange={paymentChange}
                        disabled={Object.keys(report.editReport.paymentDetails).length && /edit-report/gi.test(location.pathname)}
                      />
                    </ FormControl>
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Price
                      </FormLabel>
                      <Box pos="relative" w={{ sm: "100%", xxl: "70%" }}>
                        <Text
                          as="span"
                          pos="absolute"
                          left="16px"
                          fontSize="18px"
                          top="11px"
                          bottom="0"
                        >
                          $
                        </Text>
                        <Input
                          type="number"
                          pl="30px"
                          w="100%"
                          maxW="unset"
                          variant="Bordered"
                          name="price"
                          value={paymentValues.price}
                          onChange={paymentChange}
                          disabled={Object.keys(report.editReport.paymentDetails).length && /edit-report/gi.test(location.pathname)}
                        />
                      </Box>
                    </FormControl>
                    <FormControl
                      isRequired
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Select Service
                      </FormLabel>
                      <Select
                        variant="Simple"
                        w={{ sm: "100%", xxl: "70%" }}
                        maxW="unset"
                        name="selectService"
                        placeholder="Select"
                        value={paymentValues.selectService}
                        onChange={paymentChange}
                        disabled={Object.keys(report.editReport.paymentDetails).length && /edit-report/gi.test(location.pathname)}
                      >
                        <option value="Residential Inspection">
                          Residential Inspection
                        </option>
                        <option value="Pre-Listing Inspection">
                          Pre-Listing Inspection
                        </option>
                        <option value="4 Point Inspection">
                          4 Point Inspection
                        </option>
                        <option value="Trip Fee">
                          Trip Fee
                        </option>
                        <option value="Duplex Inspection">
                          Duplex Inspection
                        </option>
                        <option value="Triplex Inspection">
                          Triplex Inspection
                        </option>
                        <option value="Quadraplex Inspection">
                          Quadraplex Inspection
                        </option>
                      </Select>
                    </FormControl>
                    <FormControl
                      isRequired
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <FormLabel
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Tax
                      </FormLabel>
                      <Input
                        type="number"
                        w={{ sm: "100%", xxl: "70%" }}
                        maxW="unset"
                        variant="Bordered"
                        name="tax"
                        value={paymentValues.tax}
                        onChange={paymentChange}
                        disabled={Object.keys(report.editReport.paymentDetails).length && /edit-report/gi.test(location.pathname)}
                      />
                    </FormControl>
                    <Box
                      d="inline-flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexWrap={{ sm: "wrap", xxl: "nowrap" }}
                    >
                      <Text
                        w={{ sm: "100%", xxl: "auto" }}
                        as="label"
                        fontSize={{ sm: "16px", xxl: "xxl" }}
                        mb={{ sm: "4px", xxl: "0" }}
                        color="black.lighten"
                        pr={{ sm: "6px", xxl: "8px" }}
                        textAlign="left"
                      >
                        Total Amount
                      </Text>
                      <Box pos="relative" w={{ sm: "100%", xxl: "70%" }}>
                        <Text
                          as="span"
                          pos="absolute"
                          left="16px"
                          fontSize="18px"
                          top="11px"
                          bottom="0"
                        >
                          $
                        </Text>
                        <Input
                          readOnly
                          type="number"
                          pl="30px"
                          w="100%"
                          maxW="unset"
                          variant="Bordered"
                          name="totalAmount"
                          value={(
                            Number(paymentValues.price) +
                            (Number(paymentValues.tax) / 100) *
                            Number(paymentValues.price)
                          ).toFixed(2)}
                          disabled={Object.keys(report.editReport.paymentDetails).length && /edit-report/gi.test(location.pathname)}
                        />
                      </Box>
                    </Box>
                  </SimpleGrid>
                  <Flex
                    mt={{ sm: "20px", xxl: "30px" }}
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <>
                      <Text fontSize="xxl" color="black.lighten">
                        Agreement/Contract
                      </Text>
                      {auth?.agreements.length === 0 && (
                        <>
                          <Text fontSize="xl" color="black.lighten" mt="10px">
                            There are no active agreements. New agreements added
                            will be visible here.
                          </Text>
                          <Link to='/settings'>
                            <Button
                              variant="primary2"
                              mt="5px"
                              onClick={handleSelectAgreement}
                            >Select Agreement
                            </Button>
                          </Link>
                        </>
                      )}
                      <Box
                        d="flex"
                        mt={{ sm: "20px", xxl: "30px" }}
                        flexWrap="wrap"
                        justifyContent="flex-start"
                      >
                        {auth?.agreements?.map((x: any) => {
                          return (
                            <Box
                              key={x.id}
                              mr={{ sm: "20px", xxl: "35px" }}
                              mb="6px"
                              d="inline-flex"
                              alignItems="center"
                            >
                              <Checkbox
                                css={{
                                  "@media screen and (max-width: 1549px)": {
                                    ".chakra-checkbox__label": {
                                      fontSize: "16px",
                                      lineHeight: "18px",
                                    },
                                  },
                                }}
                                variant="bold"
                                colorScheme="white.primary"
                                mr={{ sm: "4px", xxl: "16px" }}
                                textAlign="left"
                                disabled={disableAgreementClick}
                                isChecked={checkedAgreement[x.id]}
                                onChange={(e) =>
                                  setCheckedAgreement({
                                    ...checkedAgreement,
                                    [x.id]: e.target.checked,
                                  })
                                }
                              >
                                {x.title}
                              </Checkbox>
                              <Button
                                variant="iconBtn"
                                onClick={() => handleAgreementModal(x)}
                              >
                                <Icon
                                  fontSize={{ sm: "24px", xxl: "26px" }}
                                  color="gray.primary"
                                  as={IoMdEye}
                                ></Icon>
                              </Button>
                            </Box>
                          );
                        })}
                      </Box>
                    </>
                  </Flex>
                  <Flex mt="22px" justifyContent="flex-start">
                  </Flex>
                </Box>
              </Step>
            </Steps>
          )}

          {((report.reportDetails.templateId && !showInspactionDetails && report.reportTemplate) || (/edit-report/gi.test(location.pathname) && report.reportDetails.templateId && !showInspactionDetails)) && (
            <Button
              variant="primary2"
              mt="5px"
              onClick={handleChnageReportView}
              mr='10px  '
            >
              <Icon
                fontSize="18px"
                as={HiOutlineSwitchHorizontal}
                mr="6px"
              ></Icon>
              Switch to Workspace
            </Button>
          )}

          {(!report.reportDetails.templateId || !/edit-report/gi.test(location.pathname)) && (
            <Button
              variant="primary2"
              mt="5px"
              onClick={handleTemplateCenter}
            >
              <Icon
                fontSize="18px"
                as={HiOutlineSwitchHorizontal}
                mr="6px"
              ></Icon>
              Select a Template
            </Button>
          )}
          {showInspactionDetails && (
            <Box className="inspection-stepper" p="0" mb="20px">
              <Box m="0">
                <Flex alignItems="center" p="0 40px" flexWrap="wrap">
                  <Box ml="auto">
                  </Box>
                </Flex>
                <CreateTemplate />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Modal
        isOpen={editAgentDetails}
        onClose={closeEditAgent}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="30px 50px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Edit Agent Details
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={closeEditAgent}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="8px 50px">
            <Box
              borderRadius="4px"
              d="inline-flex"
              alignItems="center"
              mb="20px"
            >
              <Box w="110px" h="110px" borderRadius="4px">
                <Avatar
                  src={
                    agentImage.img ||
                    report?.agents.find((x) => x.id === agentValues.id)?.accessImageUrl
                  }
                  h="100%"
                  w="100%"
                  overflow="hidden"
                  css={css({
                    '.chakra-avatar__img': {
                      borderRadius: '0',
                      width: 'auto',
                      height: 'auto',
                      maxHeight: '100%'
                    }
                  })}
                />
              </Box>
              <Box ml="39px">
                <label>
                  <Box d="inline-flex" alignItems="center" cursor="pointer">
                    <Icon
                      color="blue.dark4"
                      fontSize="xxl"
                      mr="15px"
                      as={MdModeEdit}
                    ></Icon>
                    <Text color="gray.light8" fontSize="xxl">
                      Change Image
                    </Text>
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={fileChange}
                      name="agentImage"
                      w="0"
                      h="0"
                      border="none"
                      outline="none"
                      p="0"
                      _focus={{ outline: "none" }}
                    />
                  </Box>
                </label>
              </Box>
            </Box>
            <Box
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="30px"
            >
              <Text as="label" fontSize="xxl" color="black.lighten" pr="10px">
                First Name
              </Text>
              <Input
                w="70%"
                type="text"
                variant="Bordered"
                name="firstName"
                value={agentValues.firstName}
                onChange={agentChange}
              />
            </Box>
            <Box
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="30px"
            >
              <Text as="label" fontSize="xxl" color="black.lighten" pr="10px">
                Last Name
              </Text>
              <Input
                w="70%"
                type="text"
                variant="Bordered"
                name="lastName"
                value={agentValues.lastName}
                onChange={agentChange}
              />
            </Box>
            <Box
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="30px"
            >
              <Text as="label" fontSize="xxl" color="black.lighten" pr="10px">
                Phone No
              </Text>
              <Input
                w="70%"
                type="number"
                variant="Bordered"
                name="phone"
                value={agentValues.phone}
                onChange={agentChange}
              />
            </Box>
            <Box
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="30px"
            >
              <Text as="label" fontSize="xxl" color="black.lighten" pr="10px">
                Email
              </Text>
              <Input
                w="70%"
                type="text"
                variant="Bordered"
                name="email"
                value={agentValues.email}
                onChange={agentChange}
              />
            </Box>
            <Box
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="30px"
            >
              <Text as="label" fontSize="xxl" color="black.lighten" pr="10px">
                Agency Name
              </Text>
              <Input
                w="70%"
                type="text"
                variant="Bordered"
                name="agencyName"
                value={agentValues.agencyName}
                onChange={agentChange}
              />
            </Box>
            <Box
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="30px"
            >
              <Text as="label" fontSize="xxl" color="black.lighten" pr="10px">
                Agency No
              </Text>
              <Input
                w="70%"
                type="number"
                variant="Bordered"
                name="agencyNumber"
                value={agentValues.agencyNumber}
                onChange={agentChange}
              />
            </Box>
            <Box d="flex" justifyContent="space-between" alignItems="center">
              <Text as="label" fontSize="xxl" color="black.lighten" pr="10px">
                Address
              </Text>
              <Textarea
                w="70%"
                variant="Bordered"
                name="address"
                value={agentValues.address}
                onChange={agentChange}
              ></Textarea>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="center" p="30px 50px">
            <Button
              variant="transparent"
              color="black.lighten"
              mr="80px"
              onClick={updateAgentDetails}
            >
              Update
            </Button>
            <Button
              variant="primary"
              fontWeight="normal"
              w="168px"
              onClick={closeEditAgent}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={addAgentDetails}
        onClose={closeAddAgent}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="30px 50px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Add New Agent Details
          </ModalHeader>
          <Icon
            color="black.primary"
            fontSize="24px"
            as={IoMdClose}
            onClick={closeAddAgent}
            cursor="pointer"
            position="absolute"
            top="29px"
            right="29px"
          ></Icon>
          <ModalBody p="8px 50px">
            <SimpleGrid columns={2} spacing="0 35px" mb="30px">
              <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Text
                  as="label"
                  fontSize="xxl"
                  color="black.lighten"
                  pr={{ xxl: "10px" }}
                  mb={{ sm: "6px", xxl: "0" }}
                >
                  First Name
                </Text>
                <Input
                  maxW="unset"
                  w={{ sm: "100%", xxl: "70%" }}
                  type="text"
                  variant="Bordered"
                  name="firstName"
                  value={agentValues.firstName}
                  onChange={agentChange}
                />
              </Box>
              <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Text
                  as="label"
                  fontSize="xxl"
                  color="black.lighten"
                  pr={{ xxl: "10px" }}
                  mb={{ sm: "6px", xxl: "0" }}
                >
                  Last Name
                </Text>
                <Input
                  maxW="unset"
                  w={{ sm: "100%", xxl: "70%" }}
                  type="text"
                  variant="Bordered"
                  name="lastName"
                  value={agentValues.lastName}
                  onChange={agentChange}
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing="0 35px" mb="30px">
              <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Text
                  as="label"
                  fontSize="xxl"
                  color="black.lighten"
                  pr={{ xxl: "10px" }}
                  mb={{ sm: "6px", xxl: "0" }}
                >
                  Mobile No
                </Text>
                <Input
                  maxW="unset"
                  w={{ sm: "100%", xxl: "70%" }}
                  type="number"
                  variant="Bordered"
                  name="phone"
                  value={agentValues.phone}
                  onChange={agentChange}
                />
              </Box>
              <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Text
                  as="label"
                  fontSize="xxl"
                  color="black.lighten"
                  pr={{ xxl: "10px" }}
                  mb={{ sm: "6px", xxl: "0" }}
                >
                  Email ID
                </Text>
                <Input
                  maxW="unset"
                  w={{ sm: "100%", xxl: "70%" }}
                  type="text"
                  variant="Bordered"
                  name="email"
                  value={agentValues.email}
                  onChange={agentChange}
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing="0 35px" mb="30px">
              <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Text
                  as="label"
                  fontSize="xxl"
                  color="black.lighten"
                  pr={{ xxl: "10px" }}
                  mb={{ sm: "6px", xxl: "0" }}
                >
                  Agency Name
                </Text>
                <Input
                  maxW="unset"
                  w={{ sm: "100%", xxl: "70%" }}
                  type="text"
                  variant="Bordered"
                  name="agencyName"
                  value={agentValues.agencyName}
                  onChange={agentChange}
                />
              </Box>
              <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Text
                  as="label"
                  fontSize="xxl"
                  color="black.lighten"
                  pr={{ xxl: "10px" }}
                  mb={{ sm: "6px", xxl: "0" }}
                >
                  Agency No
                </Text>
                <Input
                  maxW="unset"
                  w={{ sm: "100%", xxl: "70%" }}
                  type="number"
                  variant="Bordered"
                  name="agencyNumber"
                  value={agentValues.agencyNumber}
                  onChange={agentChange}
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing="0 35px" mb="30px">
              <Box
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <Text
                  as="label"
                  fontSize="xxl"
                  color="black.lighten"
                  pr={{ xxl: "10px" }}
                  mb={{ sm: "6px", xxl: "0" }}
                >
                  Address
                </Text>
                <Input
                  maxW="unset"
                  w={{ sm: "100%", xxl: "70%" }}
                  type="text"
                  variant="Bordered"
                  name="address"
                  value={agentValues.address}
                  onChange={agentChange}
                />
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={2} spacing="0 35px" mb="30px">
              <Box
                borderRadius="4px"
                d="inline-flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Text
                  as="label"
                  fontSize="xxl"
                  color="black.lighten"
                  pr={{ xxl: "10px" }}
                  mb={{ sm: "6px", xxl: "0" }}
                >
                  Agent Image
                </Text>
                <Box
                  w={{ sm: "100%", xxl: "70%" }}
                  border={agentImage.img ? "" : "1px solid"}
                  bg="white.lighten4"
                  borderRadius="4px"
                  overflow="hidden"
                  borderColor="gray.light4"
                  d="inline-flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  h="200px"
                >
                  {agentImage.img ? (
                    <Image src={agentImage.img} alt="" h="100%" />
                  ) : (
                    <label>
                      <Box
                        d="inline-flex"
                        alignItems="center"
                        cursor="pointer"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Icon
                          fontSize="22px"
                          color="gray.primary"
                          as={camera}
                        ></Icon>
                        <Text fontSize="14px" color="black.lighten" mt="8px">
                          Add Image
                        </Text>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={fileChange}
                          name="agentImage"
                          w="0"
                          h="0"
                          border="none"
                          outline="none"
                          p="0"
                          _focus={{ outline: "none" }}
                        />
                      </Box>
                    </label>
                  )}
                </Box>
              </Box>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter justifyContent="center" p="30px 50px">
            <Button
              variant="primary2"
              p="8px 30px"
              mr="15px"
              bg="gray.light10"
              w="168px"
              height="52px"
              fontSize="18px"
              color="black.lighten"
              onClick={() => setAgentImage({ img: null, name: null })}
            >
              Clear
            </Button>
            <Button
              disabled={report.loading.length !== 0}
              variant="primary"
              fontWeight="normal"
              w="168px"
              onClick={addNewAgent}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        variant="centerModal"
        isOpen={showDisclaimer}
        onClose={closeShowDisclaimer}
        size="3xl"
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p="30px 30px 35px"
            fontSize="21px"
            lineHeight="26px"
            color="black.lighten"
            fontWeight="600"
          >
            Disclaimer
          </ModalHeader>
          <ModalBody p="0 30px 30px">
            <Text fontSize="18px" color="black.lighten">
              The verbiage included in this software application is being provided, to you, as an example of how the narratives should be written. While the narratives are based on Industry Standard, you should check each narrative and rewrite them in the manner in which you want them represented in your end report. As you know, as a Home Inspector, your scope of work is as a generalist… Some of the narratives can be construed to mean you are acting as a specialist. Therefore Aurora Software, LLC makes no guarantees or warranties about the accuracy of the information contained in this software. Each Home Inspector is responsible for checking and editing their narratives, as well as all information added to his or her report.
              Additionally, Our templates are provided in whole or in part through a collaboration of the community of Home Inspectors that currently use or have used this platform. Therefore, we can not control where they get the information from and thus, by using this platform, you agree to hold Aurora Software, LLC and its community harmless for any apparent misuse of material.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="grey" minW="120px" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              variant="primary2"
              ml="auto"
              minW="120px"
              onClick={closeShowDisclaimer}
            >
              Accept
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AgreementModal
        setOpen={openAgreementModel}
        setClose={setOpenAgreementModel}
        title={selectedAgreement?.title}
        description={selectedAgreement?.description}
      />
      <Modal isOpen={showType} onClose={closeShowType} size='lg' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p='25px 28px' fontSize='21px' lineHeight='26px' color='black.lighten' fontWeight='600'>
            {`Delete ${propertyTypeHeader}`}
          </ModalHeader>
          <Icon color='black.primary' fontSize='24px' as={IoMdClose} onClick={closeShowType} cursor='pointer' position='absolute' top='29px' right='29px'></Icon>
          <ModalBody p='0 28px'>
            <UnorderedList listStyleType='none' m='0'>
              {propertyChoiceType?.map((field: any) => {
                return <ListItem d='flex' alignItems='center' mb='15px' key={field.id}>
                  <Input w='calc(100% - 68px)' type='text' readOnly variant='Bordered' height='42px' name={`s-${field.id}`} defaultValue={field.property_type || field.property_style || field.contruction_type} />
                  <Box pl='10px' ml='auto'>
                    <Button variant='iconBtn' p='0' mr='30px' minW='unset' onClick={() => submitDeleteField(field)}>
                      <Icon fontSize='20px' color='gray.primary' as={MdDelete}></Icon>
                    </Button>
                  </Box>
                </ListItem>
              })}
            </UnorderedList>
          </ModalBody>
          <ModalFooter p='25px 28px' flexWrap='wrap'>
            <Box d='flex' alignItems='center' w='100%'>
              <Button variant='primary2' ml='auto' w='117px' onClick={closeShowType}>
                Close
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
