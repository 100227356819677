// const config: any = {
//   local: {
//     apiUrl: 'https://myauroraportal.dedicateddevelopers.us/',
//   },

//   development: {
//     apiUrl: 'https://api-home-inspection.symple.co.in/',
//   },

//   testing: {
//     apiUrl: 'https://api-home-inspection-test.symple.co.in/',
//   },

//   staging: {
//     apiUrl: '',
//   },

//   prod: {
//     apiUrl: 'https://myauroraportal.dedicateddevelopers.us/',
//   },
// };

// export default config[process.env.REACT_APP_ENV || 'local'];


const config: any = {
  local: {
    apiUrl: 'https://stagingapp.myauroraportal.com/',
  },

  development: {
    apiUrl: 'https://api-home-inspection.symple.co.in/',
  },

  testing: {
    apiUrl: 'https://api-home-inspection-test.symple.co.in/',
  },

  staging: {
    apiUrl: '',
  },

  prod: {
    apiUrl: 'https://stagingapp.myauroraportal.com/',
  },
};

export default config[process.env.REACT_APP_ENV || 'local'];

